import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
    },
    formControle: {
        display: "block",
        margin: "5px",
    },
    input: {
        width: "100%",
    },
    helper: {
        fontStyle: 'italic',
        width: "100%",
    }
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
SelectMono.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.any,
    ]),
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    libelle: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    helperText: PropTypes.any,
    mesChoix: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            label: PropTypes.string.isRequired,
            key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
        })),
}

SelectMono.defaultProps = {
    // value: '',
    disabled: false,
    helperText: '',
    errorText: '',
    required: false,
};


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SelectMono({ value, name, libelle, disabled, onChange, helperText, errorText, placeHolder, required, mesChoix }) {
    const classes = useStyles();
    const [erreur, setErreur] = useState(errorText)
    const [selectValue, setSelectValue] = useState(value);
    const [t] = useTranslation(['commun'])


    useEffect(() => {
        if (selectValue !== value) {
            setSelectValue(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    // function appelée à chaque saisie
    const handleChange = (event) => {
        let valeurSaisie = event.target.value
        let uneErreur = ''

        if (required && valeurSaisie === 0) {
            uneErreur = t('commun:helper')
        }
        // exécute le controle demandé par la props
        if (onChange) {
            uneErreur = onChange(event, uneErreur)
        }

        if (erreur !== uneErreur) {
            // initialise l'erreur selon le retour de la props
            setErreur(uneErreur)
        }

        setSelectValue(valeurSaisie)
    };

    // retourne vrai si une erreur existe sinon faux
    function estEnErreur() {
        return (erreur && erreur !== '') ? true : false
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    // render des valeurs
    function renderMenuItem(mesChoix) {

        // on ajoute la valeur 'null'
        let indice = mesChoix.findIndex((element) => element.value === 0)
        if (indice < 0) {
            mesChoix.unshift({ value: 0, label: placeHolder === undefined ? 'non renseigné' : placeHolder, key: 'choice_key_' + name + '_0' })
        }

        return mesChoix.map(unChoix => {
            return (
                < MenuItem key={"choice_key_" + unChoix.key} value={unChoix.value} > {unChoix.label} </MenuItem >
            )
        })
    }

    return (

        < FormGroup key={'ctrl_id_' + name} className={classes.root} >
            < FormControl error={estEnErreur()} required={required} disabled={disabled} className={classes.formControle} >
                <InputLabel htmlFor={'input_name_' + name + '_label'}>{libelle}</InputLabel>

                <Select
                    className={classes.input}
                    name={name}
                    value={(selectValue && selectValue !== null) ? selectValue : 0}
                    onChange={handleChange}
                    id={'input_name_' + name + '_label'}
                    aria-describedby={'input_name_' + name + '_helper'}
                >
                    {renderMenuItem(mesChoix)}
                </Select>

                {
                    (estEnErreur() || helperText !== '') &&
                    < FormHelperText
                        id={'input_name_' + name + '_helper'}
                        className={classes.helper}
                    >

                        {
                            estEnErreur() ?
                                erreur
                                :
                                helperText
                        }

                    </FormHelperText>
                }
            </FormControl>
        </FormGroup >
    )
}
