import * as constants from '../constants'
import { Globale } from './globale'
import db from '../services/db'
import mimeTypeRef from '../component/audit/mime-type.json'


/**
 * Sert à la gestion des document de l'audit dans l'application
 * @class
 * @extends Globale
 */
export class DocumentADT extends Globale {
    constructor(unDocument = {}) {
        super()
        this.ID_CONTROLE = unDocument.ID_CONTROLE
        this.ID_DOCUMENT = unDocument.ID_DOCUMENT
        this.ID_ENTETE = unDocument.ID_ENTETE
        this.ID_LIGNE = unDocument.ID_LIGNE
        this.ID_SITE = unDocument.ID_SITE
        this.ID_TYPE = unDocument.ID_TYPE
        this.META_COMMENTAIRE = unDocument.META_COMMENTAIRE
        this.META_EXTENSION = unDocument.META_EXTENSION
        this.META_LIBELLE = unDocument.META_LIBELLE
        this.META_NOM = unDocument.META_NOM
        // this.DOC_CONTENU = unDocument.DOC_CONTENU
        this.MODIF = unDocument.MODIF
        this.SUPPR = unDocument.SUPPR
        this.SUPPR_OK = unDocument.SUPPR_OK

    }


    /**
     * Permet de récupérer le mime type via une extension
     * @param {String} extension - extension pour lequel chercher le mime type
     * @returns {String} - mime type correspondant à l'extension ex : pour un .jpg => data:image/jpeg;base64,
     */
    static getMimeTypeDocument(extension) {
        let mimeType = (mimeTypeRef[extension.toLowerCase()]) ? mimeTypeRef[extension.toLowerCase()] : "application/octet-stream"
        return `data:` + mimeType + `;base64,`

    }

    /**
     * Fonction de comparaison entre un doucment passé en parametre et le document courant
     * @param {Budget} unAtt - Représente le document du localStorage à comparer avec le document courant
     * @param {Boolean} identique - Variable de controle de similitude de la réponse du WS et du localStorage pour une réponse / un controle 
     */
    compareTo(unDoc, identique) {
        if (this.MODIF === true) {
            identique = false
        } else if (unDoc.ID_DOCUMENT < 0) {
            identique = false
        } else {
            unDoc.MODIF = false
            identique = (unDoc.META_LIBELLE !== this.META_LIBELLE) ? false : identique
            identique = (unDoc.META_COMMENTAIRE !== this.META_COMMENTAIRE) ? false : identique
        }
    }

    /**
     * Sauvegarde du document courant dans l'indexedDB
     * Attention le document n'est sauvegardé que s'il n'est pas encore enregistré en BDD
     */
    toIndexedDB() {
        if (this.ID_DOCUMENT < 0) {
            const prepareLocal = Object.assign({}, this);
            prepareLocal.referenceDocument = this.getReferenceDocument()

            if (this.SUPPR === true) {
                this.dropIndexedDB()
            } else {
                db.table('documents')
                    .get({ 'referenceDocument': this.getReferenceDocument() }, (monDoc) => {
                        if (monDoc && monDoc.id) {
                            prepareLocal.id = monDoc.id
                        }
                        db.table('documents')
                            .put(prepareLocal)
                    })
            }

        }
    }

    /**
     * Suppression du document local en indexDB
     */
    dropIndexedDB() {
        db.table('documents')
            .get({ 'referenceDocument': this.getReferenceDocument() }, (monDoc) => {
                if (monDoc && monDoc.id) {
                    db.table('documents')
                        .delete(monDoc.id)
                }
            })

    }


    /**
     * Récupère un document au format blob depuis l'indexedDB
     * @returns {Promise} - chaine base64 représentant le document 
     */
    getContenuByIndexedDB() {
        return db.table('documents')
            .where({ 'referenceDocument': this.getReferenceDocument() })
            .toArray(monDoc => {
                const retour = {}
                if (monDoc[0]) {
                    if (monDoc[0].blob) {
                        retour.blob = monDoc[0].blob
                    }
                }
                return retour
            })
    }

    /**
     * si doc existant en bdd (id > 0) : Appel au WS pour récupérer le contenu du document depuis le WS 
     * si doc non existant en bdd : cherche dans la base de données locale le blob du document
     * @returns {Promise} - retourne une chaine de promesse
     */
    getDocContenu() {
        if (this.ID_DOCUMENT > 0) {
            const url = `/api/document/${this.ID_DOCUMENT}`
            return DocumentADT.getFetch(url)
                .then((res) => {
                    const retour = {}
                    if (res && res.data) {
                        const thisDoc = res.data
                        retour.docContenu = DocumentADT.getMimeTypeDocument(this.META_EXTENSION) + [btoa(new Uint8Array(thisDoc.DOC_CONTENU.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))]
                        return retour
                    }

                })
        } else {
            return this.getContenuByIndexedDB()
        }

    }

    /**
     * construit et retourne la référence correspondant au document pour IndexedDB
     * @returns {String} - chaine représentant l'adresse du document 
     */
    getReferenceDocument() {
        return constants.localDoc + this.ID_ENTETE + '_' + this.ID_LIGNE + '_' + this.ID_DOCUMENT
    }




    /**
     * Fonction de sauvegarde d'un document
     * @returns {Promesse} - Retourne la promesse correspondant au type de sauvegarde
     */
    save() {
        if (this.ID_DOCUMENT <= 0) {
            return this._add()
        }
        return false
    }

    /**
     * Construit la requète d'ajout d'un document
     * @returns {Promise} - Retourne la promese d'ajout
     * @private
     */
    _add() {
        return new Promise((resolve, reject) => {
            const url = `/api/audit/${this.ID_ENTETE}/document/`
            let body = Object.assign({}, this)
            this.getContenuByIndexedDB()
                .then((retour) => {
                    const fileReader = new FileReader();
                    fileReader.onloadend = () => {
                        const content = fileReader.result;
                        body.DOC_CONTENU = content
                        resolve(
                            new Promise((resolve, reject) => {
                                DocumentADT.postFetch(url, body)
                                    .then((res) => {
                                        if (res && res.data) {
                                            this.dropIndexedDB()
                                            this.ID_DOCUMENT = res.data.ID_DOCUMENT
                                            this.MODIF = false
                                            resolve(this)
                                        }
                                    })
                                    .catch(reject)
                            })
                        )
                    }
                    if (retour && retour.blob) {
                        fileReader.readAsDataURL(retour.blob)
                    } else {
                        resolve(new Promise((_) => { _() }))
                    }

                }).catch(reject)

        })

    }

    /**
     * Construit la requète de suppression d'un document
     * @returns {Promesse} - Retourne la promese de suppression
     * @private
     */
    _delete() {
        const url = `/api/audit/${this.ID_ENTETE}/document/${this.ID_DOCUMENT}`
        return DocumentADT.delFetch(url)
    }

    /**
     * Construit la requète de modification d'un document
     * @returns {Promesse} - Retourne la promese de modification
     * @private
     */
    _update() {
        const url = `/api/audit/${this.ID_ENTETE}/document/${this.ID_DOCUMENT}`
        const body = this
        return DocumentADT.putFetch(url, body)
    }

}
