import React, { useState, createContext } from "react";


const ListeContext = createContext();

const ListeProvider = (props) => {
    const [filtreAudit, setFiltreAudit] = useState({})
    return (
        <ListeContext.Provider value={[filtreAudit, setFiltreAudit]}>
            {props.children}
        </ListeContext.Provider>
    );
};

export { ListeContext, ListeProvider };