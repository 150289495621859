import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Pays } from './pays'
import { Referentiel } from './referentiel'
import { Region } from './region'
import { TypeActivite } from './typeActivite'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class Site extends Globale {

    constructor(unSite = {}) {
        super()

        this.ID_SITE = unSite.ID_SITE
        this.SITE_ENTITE = unSite.SITE_ENTITE
        this.SITE_NOM = unSite.SITE_NOM
        this.SITE_VILLE = unSite.SITE_VILLE
        this.SITE_CP = unSite.SITE_CP
        this.ADRESSE = unSite.ADRESSE
        this.AMIANTE = unSite.AMIANTE
        this.BATIMENT_CONNECT = unSite.BATIMENT_CONNECT
        this.CAPACIT_MAX = unSite.CAPACIT_MAX
        this.CONF_URBAN = unSite.CONF_URBAN
        this.DERN_RENOV_MAJ = unSite.DERN_RENOV_MAJ
        this.ETAT = unSite.ETAT
        this.LOUE = unSite.LOUE
        this.PROPRIETAIRE = unSite.PROPRIETAIRE
        this.SERVITUDE = unSite.SERVITUDE
        this.ANNEE_EXPL_ORPEA = unSite.ANNEE_EXPL_ORPEA
        this.ANNEE_EXPL = unSite.ANNEE_EXPL
        this.ANNEE_ACQUIS = unSite.ANNEE_ACQUIS
        this.ANNEE_CONS = unSite.ANNEE_CONS
        this.NB_BATIMENT = unSite.NB_BATIMENT
        this.NB_LIT = unSite.NB_LIT
        this.PERMIS_CONS_ACPT = unSite.PERMIS_CONS_ACPT
        this.PERMIS_CONS_DEP = unSite.PERMIS_CONS_DEP
        this.SRF_SOL = unSite.SRF_SOL
        this.SRF_NETTE = unSite.SRF_NETTE
        this.SRF_PLANCHER = unSite.SRF_PLANCHER
        this.SRF_CONS_REST = unSite.SRF_CONS_REST
        this.SRF_BRUTE = unSite.SRF_BRUTE
        this.SPRF_TERRAIN = unSite.SPRF_TERRAIN
        this.ZN_MON_HISTO = unSite.ZN_MON_HISTO
        this.ZN_INOND = unSite.ZN_INOND
        this.ZN_SISMIQ = unSite.ZN_SISMIQ
        this.ZN_EXP_RADON = unSite.ZN_EXP_RADON
        this.DAT_CRE = unSite.DAT_CRE
        this.PFL_MAJ = unSite.PFL_MAJ
        this.PFL_CRE = unSite.PFL_CRE
        this.DAT_MAJ = unSite.DAT_MAJ

        this.BATIMENTS = unSite.BATIMENTS || []

        // champs utilisés pour les tdb, mis à jour à chaque enregistrement
        this.ACTIVITES = unSite.ACTIVITES || []
        this.LISTE_IDACTIVITE = unSite.LISTE_IDACTIVITE
        this.LISTE_LIBACTIVITE = unSite.LISTE_LIBACTIVITE

        this.REGION = Region.getByID(unSite.REGION ? unSite.REGION.ID_REGION : 0)
        this.PAYS = Pays.getByID(unSite.PAYS ? unSite.PAYS.ID_PAYS : 0)

        this.ACTIVITES = []
        if (unSite.ACTIVITES && unSite.ACTIVITES.length > 0) {
            unSite.ACTIVITES.forEach(uneActivite => {
                const monActivite = TypeActivite.getByID(uneActivite.IDCHAN_TYPE)
                monActivite.IDCHAN_TYPE = uneActivite.IDCHAN_TYPE
                monActivite.ID_SITE_ACTIVITE = uneActivite.ID_SITE_ACTIVITE

                this.ACTIVITES.push(monActivite)
            });
        }
    }

    static getRefName() {
        return 'Site'
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {Site}
     * @static
     */
    static byLocalStorage() {
        let ListeSite = JSON.parse(window.localStorage.getItem(constants.localSiteListe))
        if (ListeSite === undefined || ListeSite === null || !Array.isArray(ListeSite)) { ListeSite = [] }
        return ListeSite
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListeSite) {
        if (ListeSite) {
            window.localStorage.setItem(constants.localSiteListe, JSON.stringify(ListeSite))
        }
    }

    toLocalStorage() {
        window.localStorage.setItem(constants.localSite + this.ID_SITE, JSON.stringify(this))
    }

    updateLocalStorage() {
        let listeSite = Site.byLocalStorage()
        const index = listeSite.findIndex(unSite => unSite.ID_SITE === this.ID_SITE)
        if (index !== -1) {
            listeSite[index] = this
        } else {
            listeSite.push(this)
        }
        Site.toLocalStorage(listeSite)
    }

    static getListeActivite(ACTIVITES) {
        let liste = ''

        if (ACTIVITES !== undefined && Array.isArray(ACTIVITES)) {
            liste = ACTIVITES
                .sort((a, b) => {
                    if (a.ORDRE === undefined) { a.ORDRE = 0 }
                    if (b.ORDRE === undefined) { b.ORDRE = 0 }
                    return a.ORDRE - b.ORDRE
                })
                .map(uneActivite => uneActivite.LIBELLE)
                .join(', ')

        }



        return liste
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unSite = Site.byLocalStorage().find(unSite => unSite.ID_SITE === id)

        if (unSite) {
            return new Site(unSite)
        } else {
            return new Site()
        }
    }


    /**
     * Récupère un site depuis le WS
     * @returns {Promise} - promesse 
     */
    get() {
        const url = `/api/site/${this.ID_SITE}`
        return Site.getFetch(url, '')
            .then((res) => {
                if (res && res.data) {
                    return new Site(res.data)
                }
            })
    }


    /**
     * Récupère la liste des sites 
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG
            let saveLocal = true
            if (Object.keys(params).length > 1) {
                saveLocal = false
            }
            const url = `/api/site`
            if (force || this.byLocalStorage().length === 0) {

                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            for (let index = 0; index < res.data.length; index++) {
                                res.data[index] = new Site(res.data[index])
                            }
                            if (saveLocal) {
                                this.toLocalStorage(res.data)

                                const ref = new Referentiel()
                                ref.maj(Site.getRefName())
                            }
                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(Site.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(Site.byLocalStorage().filter((unSite) => {
                    let filtreOk = true
                    if (param.idSite) {
                        filtreOk = (param.idSite.split(',').map(x => parseInt(x)).includes(unSite.ID_SITE)) ? filtreOk : false
                    }
                    if (param.idPays) {
                        filtreOk = (param.idPays.split(',').map(x => parseInt(x)).includes(unSite.PAYS.ID_PAYS)) ? filtreOk : false
                    }
                    if (param.ville) {
                        filtreOk = (param.ville.toLowerCase().split(',').some(x => -1 !== unSite.SITE_VILLE.toLowerCase().indexOf(x))) ? filtreOk : false
                    }
                    if (param.idActivite) {
                        filtreOk = (param.idActivite.split(',').map(x => parseInt(x)).some(x => -1 !== unSite.ACTIVITES.map(y => parseInt(y.IDCHAN_TYPE)).indexOf(x))) ? filtreOk : false
                    }
                    if (param.idRegion) {
                        filtreOk = (param.idRegion.split(',').map(x => parseInt(x)).includes(unSite.REGION.ID_REGION)) ? filtreOk : false
                    }
                    return filtreOk
                }))
            }
        })
    }

    /**
     * Lance la sauvegarde de l'objet courant
     * @returns {Promise} - promesse de sauvegarde
     */
    save() {
        if (this.ID_SITE > 0) {
            return this._update()
        }
    }
    /**
    * genere la promesse d'update du site 
    * @returns {Promise} - promesse de sauvegarde
    * @private
    */
    async _update() {
        const url = `/api/site/${this.ID_SITE}`
        const body = this

        return Site.putFetch(url, body)
    }

}


