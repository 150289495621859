import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { NavLink, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import withWidth from '@material-ui/core/withWidth';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import AssignmentIcon from '@material-ui/icons/Assignment';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined'
import HomeIcon from '@material-ui/icons/Home'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CodeIcon from '@material-ui/icons/Code'
import LogoOrpea from '../../images/logo_orpea_gris.png'
import CssBaseline from '@material-ui/core/CssBaseline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Langue from './langue'
import Online from './online'
import TitreMenu from './titreMenu'
import { MenuContext } from '../../context/MenuContext'
import SousMenuAudit from './SousMenuAudit'



const useStyles = makeStyles((theme) => ({
    drawer: {
        width: theme.drawerWidth,
        flexShrink: 0,
    },

    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${theme.drawerWidth})`,
        marginLeft: theme.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            width: '100%'
        },

    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    // necessary for content to be below app bar
    logo: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            left: theme.spacing(2),
        },

    },
    drawerHeader: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },

    },
    drawerPaper: {
        width: theme.drawerWidth,
        backgroundColor: theme.palette.background.drawer,
    },
    title: {
        flexGrow: 1,
    },
    boutonHome: {
        color: theme.palette.text.negatif,
    },
    sousMenu: {
        marginLeft: '10px'
    },
    hide: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
}));

/**
 * Composant Menu
 * Affiche le menu suivant la taille de l'écran
 * @param {{ menuOpen: Boolean, setMenuOpen: Function, width: integer }} props 
 */
function Menu({ menuOpen, setMenuOpen, width }) {
    const [t] = useTranslation(['menu'])
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [countClic, setCountClic] = useState(0);
    const [sousMenu, setSousMenu] = useContext(MenuContext)

    const menu = [
        { id: 'accueil', libelle: t('menu:accueil'), path: '/accueil', icone: HomeIcon },
        { id: 'audit', libelle: t('menu:audit'), path: '/audit', icone: AssignmentIcon },
        { id: 'site', libelle: t('menu:site'), path: '/site', icone: ApartmentOutlinedIcon },
        // { id: 'siteCandidat', libelle: t('menu:reprise'), path: '/reprise', icone: ApartmentOutlinedIcon },
        { id: 'deconnection', libelle: t('menu:deconnexion'), path: '/deconnexion', icone: MeetingRoomIcon },
    ]

    /**
     * Affiche ou masque le menu
     * @param {Integer} idmenu - Id du menu cliqué 
     * @param {Boolean} ForceFerme - Force la fermeture du menu
     */
    const handleDrawerToggle = (idmenu, ForceFerme) => {

        // si on ne se trouve pas dans un menu principal...
        let objet = menu.find(element => element.id === idmenu)
        if (objet !== undefined) {
            //..on réinitialise le sous-menu à vide
            setSousMenu(undefined)
        }


        if (isSmartphone()) {
            if (ForceFerme === true) {
                // on force la fermeture si ouvert
                setMobileOpen(false)
            } else {
                setMobileOpen(!mobileOpen)
            }
        } else {
            if (ForceFerme !== true) {
                setMenuOpen(!menuOpen)
            }
        }
    };

    /**
     * Permet de savoir si on se situe sur un écran de type smartphone
     */
    function isSmartphone() {
        return (width === 'xs')
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDERS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////


    /**
     * render icone du menu
     * @param {Icons} icone 
     */
    function renderMenuIcone(icone) {
        const IconeTag = icone;
        if (IconeTag) {
            return <IconeTag />
        }
    }

    /**
     * render sous-menu (gauche)
     */
    function renderSousMenu() {
        if (sousMenu && sousMenu.name) {
            if (sousMenu.name === 'AUDIT') {

                if (sousMenu.majSousMenuDepuisPage) {
                    sousMenu.majSousMenuDepuisPage('', '', '')
                }
                return <SousMenuAudit />
            }
        }
    }

    /**
     * render menu (gauche)
     */
    function renderMenu() {
        return (
            <React.Fragment >
                <div className={classes.drawerHeader}>
                    <img alt="" className={classes.logo} src={LogoOrpea} />
                    <Hidden xsDown implementation="css" >
                        <IconButton onClick={() => handleDrawerToggle('')}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </Hidden>
                </div>

                <Divider />
                <List>
                    {menu.map((unMenu, index) => (

                        < React.Fragment key={unMenu.id}>
                            < ListItem
                                key={unMenu.id}
                                onClick={() => handleDrawerToggle(unMenu.id, true)}
                                button
                                component={NavLink}
                                to={unMenu.path}
                            >
                                <ListItemIcon>
                                    {renderMenuIcone(unMenu.icone)}
                                </ListItemIcon>
                                <ListItemText primary={unMenu.libelle} />
                            </ListItem>
                        </React.Fragment >
                    ))
                    }
                </List >
                {renderSousMenu()}
                <Divider />
                <List>
                    < ListItem
                        key='MenuVersion'
                        className={classes.Item}
                        onClick={() => setCountClic(old => old + 1)}
                    >
                        <ListItemIcon>
                            {renderMenuIcone(CodeIcon)}
                        </ListItemIcon>
                        <ListItemText secondary={process.env.REACT_APP_VERSION} />
                    </ListItem>
                </List>

            </React.Fragment >
        )
    }

    function RedirectToDevMode({ count, setCount }) {
        if (count > 5) {
            setCount(0)
            return <Redirect to={'/localData'} push />
        }
        else {
            return <></>
        }
    }

    return (
        <React.Fragment >
            <CssBaseline />

            <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: menuOpen, })} >
                {/* toolbar du haut  */}
                <Toolbar  >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => handleDrawerToggle('')}
                        className={clsx(classes.menuButton, menuOpen && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <IconButton
                        aria-label="home"
                        edge="start"
                        className={classes.boutonHome}
                        component={NavLink}
                        onClick={() => { setSousMenu(undefined) }}
                        to={menu[0].path}
                    >
                        <HomeIcon />
                    </IconButton>

                    < TitreMenu />
                    <Online />
                    <Langue />
                </Toolbar>
            </AppBar>


            {/* menu de gauche */}

            <Hidden smUp implementation="css" >
                {/* menu si version mobile : ouverture et fermeture menu*/}
                <nav >
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={isSmartphone() ? mobileOpen : false}
                        onClose={() => handleDrawerToggle('')}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {renderMenu()}
                    </Drawer>
                </nav>
            </Hidden>

            <Hidden xsDown implementation="css"  >
                {/* menu si version bureau : menu permanent */}
                <Drawer
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="persistent"
                    open={menuOpen}
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                >
                    {renderMenu()}
                </Drawer>
            </Hidden>

            <RedirectToDevMode count={countClic} setCount={setCountClic} />

        </React.Fragment >
    );
}

export default withWidth()(Menu)