import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { TodoPriorite } from './todoPriorite'
import { TodoStatut } from './todoStatut'


/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class Todo extends Globale {

    constructor(uneTodo = {}) {
        super()
        if (this.constructor === Todo) {
            throw new TypeError('Abstract class "Todo" cannot be instantiated directly');
        }
        this.ID_TODO = uneTodo.ID_TODO
        this.LIBELLE = uneTodo.LIBELLE
        this.DESCRIPTION = uneTodo.DESCRIPTION
        this.IDUTILISATEUR = uneTodo.IDUTILISATEUR
        this.NOM_UTILISATEUR = uneTodo.NOM_UTILISATEUR
        this.DATE_ACTION = uneTodo.DATE_ACTION

        this.STATUT = TodoStatut.getByID(uneTodo.STATUT ? uneTodo.STATUT.ID_TODO_STATUT : 0)
        this.PRIORITE = TodoPriorite.getByID(uneTodo.PRIORITE ? uneTodo.PRIORITE.ID_TODO_PRIORITE : 0)

        this.DATECREMOD = uneTodo.DATECREMOD ? uneTodo.DATECREMOD : { DAT_CRE: null, DAT_MAJ: null, PFL_CRE: '', PFL_MAJ: '' }

        // sert pour tableau de modif
        this.MODIF = uneTodo.MODIF
        this.SUPPR = uneTodo.SUPPR
        this.tableData = uneTodo.tableData


    }

    /**
     * Retourne un objet Todo ou false si inexistant dans le local storage
     * @param {int} idTodo - ID du site candidat à chercher
     * @returns {Todo}
     * @static
     */
    static byLocalStorage(idTodo, type) {
        const uneTodo = JSON.parse(window.localStorage.getItem(constants.localTodo + type + "_" + idTodo))
        if (uneTodo) {
            return new Todo(uneTodo)
        } else {
            return null
        }
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    toLocalStorage() {
        window.localStorage.setItem(constants.localTodo + this.type + "_" + this.ID_TODO, JSON.stringify(this))
    }

    /**
     * Récupère un site candidat depuis le WS
     * @returns {Promise} - promesse 
     */
    get(type) {
        const url = `/api/todo/${type}/${this.ID_TODO}`
        return Todo.getFetch(url, '')
            .then((res) => {
                if (res && res.data) {
                    return new Todo(res.data)
                }
            })
    }



    /**
     * Lance la sauvegarde de l'objet courant
     * @returns {Promise} - promesse de sauvegarde
     */
    save(type) {
        if (this.ID_TODO > 0) {
            return this._update(type)
        } else {
            return this._add(type)
        }
    }

    /**
     * genere la promesse d'update du site candidat
     * @returns {Promise} - promesse de sauvegarde
     * @private
     */
    async _update(type) {
        const url = `/api/todo/${type}/${this.ID_TODO}`
        const body = this

        return Todo.putFetch(url, body)
    }

    /**
     * genere la promesse d'insert du site candidat
     * @returns {Promise} - promesse de sauvegarde
     * @private
     */
    async _add(type) {
        const url = `/api/todo/${type}/`
        const body = this

        return Todo.postFetch(url, body)
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(params, type) {
        return new Promise((resolve, reject) => {
            const url = `/api/todo/${type}?`
            this.getFetch(url, params)
                .then((res) => {
                    if (res.data && res.data) {
                        for (let index = 0; index < res.data.length; index++) {
                            res.data[index] = new Todo(res.data[index])
                        }
                        this.listeToSessionStorage(res.data)
                        resolve(res.data)
                    } else {
                        resolve(this.listeBySessionStorage())
                    }
                })
                .catch(
                    (err) => {
                        if (isNoInternetError(err)) {
                            resolve(this.listeBySessionStorage())
                        } else {
                            reject(err)
                        }
                    }
                )

        })
    }

    /**
     * Sauvegarde la liste des sites candidats en local
     * @static
     * @param {Array} ListeTodo - Liste des sites candidats
     */
    static listeToSessionStorage(ListeTodo, type) {
        window.sessionStorage.setItem(constants.listeTodo + "_" + type, JSON.stringify(ListeTodo))
    }

    /**
     * Récupère la liste des sites candidats depuis la session
     * @static
     * @returns {Array} - Liste des sites candidats
     */
    static listeBySessionStorage(type) {
        let ListeTodo = JSON.parse(window.sessionStorage.getItem(constants.listeTodo + "_" + type))
        if (ListeTodo === null) {
            ListeTodo = []
        }
        return ListeTodo
    }

    /**
   * Fonction de comparaison entre une todo passée en parametre et la todo courante
   * @param {Todo} uneTodo - Représente le document du localStorage à comparer avec le document courant
   * @param {Boolean} identique - Variable de controle de similitude de la réponse du WS et du localStorage pour une réponse / un controle 
   */
    compareTo(uneTodo, identique) {
        if (this.MODIF === true) {
            identique = false
        } else if (uneTodo.ID_TODO < 0) {
            identique = false
        } else {
            uneTodo.MODIF = false
            identique = (uneTodo.LIBELLE !== this.LIBELLE) ? false : identique
            identique = (uneTodo.DESCRIPTION !== this.DESCRIPTION) ? false : identique
            identique = (uneTodo.IDUTILISATEUR !== this.IDUTILISATEUR) ? false : identique
            identique = (uneTodo.NOM_UTILISATEUR !== this.NOM_UTILISATEUR) ? false : identique
            identique = (uneTodo.STATUT !== this.STATUT) ? false : identique
            identique = (uneTodo.PRIORITE !== this.PRIORITE) ? false : identique
            identique = (uneTodo.DATE_ACTION !== this.DATE_ACTION) ? false : identique

        }
    }


}


