import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import DescriptionIcon from "@material-ui/icons/Description";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import { AuditContext } from "../../context/AuditContext";
import { UserContext } from "../../context/UserContext";
import FormAttribut from "./auditFormAttribut";
import FormBudgets from "./auditFormBudgets";
import FormCommentaire from "./auditFormCommentaire";
import FormDocumentListe from "./auditFormDocumentListe";
// import TableauTodo from '../todo/TableauTodo';
import { Fragment } from "react";
import { Badge, Hidden } from "@material-ui/core";
import theme from "../../theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "../outils/utilitaires/accordion";
import Box from "../outils/utilitaires/box";
import { Audit } from "../../classes/audit";
// import useTraceUpdate from '../outils/utilitaires/useTraceUpdate';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
    controlePanelExpanded: { ...theme.controlePanel.expanded },
    controlePanelTypography: { ...theme.controlePanel.typography },
    controlePanelDetails: { ...theme.controlePanel.details },
    controlePanelSummary: { ...theme.controlePanel.summary },

    paperBox: {
        paddingBottom: theme.spacing(1),
    },
    titre: {
        backgroundColor: theme.palette.primary.main,
        height: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
    },
}));
/**
 * Composant FormControle
 * Ce composant affiche un bloc commentaire
 * @param      {{
 *               controle : Controle,
 *               onChange : Function,
 *               panelControleDemande: String
 *          }} props
 */
export default function FormControle({ controle, onChange, panelControleDemande, idFamille, idSecteur }) {
    const { monAudit, refAttribut /*, readOnly*/ } = useContext(AuditContext);
    const UserCtx = useContext(UserContext);
    const [reponseControle, setReponseControle] = useState();
    const classes = useStyles();
    const [t] = useTranslation(["audit"]);
    const [nbDoc, setNbDoc] = useState();
    const [docLoad, setDocLoad] = useState();
    const [budgetRenseigne, setBudgetRenseigne] = useState();
    const [etatRenseigne, setEtatRenseigne] = useState();
    const [commentaireRenseigne, setCommentaireRenseigne] = useState();

    // useTraceUpdate({ reponseControle }, FormControle.name)

    useEffect(() => {
        const maReponse = monAudit.getReponseByControle(controle.ID_CONTROLE);
        setReponseControle(maReponse);
    }, [controle.ID_CONTROLE, monAudit]);

    const refreshPastille = useCallback(
        (element = "") => {
            const unAudit = Audit.byLocalStorage(monAudit.ID_ENTETE);
            const maReponse = unAudit.getReponseByControle(controle.ID_CONTROLE);
            if (maReponse) {
                if (element === "document" || element === "") {
                    setNbDoc(maReponse.DOCUMENTS.filter((unDoc) => unDoc.ID_DOCUMENT > 0).length);
                    let docOnLoad = maReponse.DOCUMENTS.filter((unDoc) => unDoc.ID_DOCUMENT < 0).length;
                    docOnLoad += maReponse.DOCUMENTS.filter((unDoc) => unDoc.SUPPR).length;
                    docOnLoad += maReponse.DOCUMENTS.filter((unDoc) => unDoc.MODIF).length;
                    setDocLoad(docOnLoad);
                }
                if (element === "budget" || element === "") {
                    setBudgetRenseigne(maReponse.BUDGETS.filter((unBud) => unBud.BUD_LIG_MONTANT > 0).length > 0);
                }
                if (element === "attribut" || element === "") {
                    setEtatRenseigne(maReponse.ATTRIBUTS.filter((unAtt) => (unAtt.LIG_VALEUR !== "" && unAtt.LIG_VALEUR !== null) || unAtt.ID_VALEUR_ATT > 0).length > 0);
                }
                if (element === "commentaire" || element === "") {
                    setCommentaireRenseigne(maReponse.LIG_COMMENTAIRE !== "" && maReponse.LIG_COMMENTAIRE !== null);
                }
            }
        },
        [controle.ID_CONTROLE, monAudit.ID_ENTETE]
    );

    useEffect(() => {
        refreshPastille();
    }, [refreshPastille]);

    /**
     * changement du panel en cours
     * @param {String} panel
     */
    const handleChangePanel = (panel, expanded) => {
        if (onChange) {
            onChange("controle", panel);
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDERS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * render de l'état du controle
     */
    function RenderEtat() {
        if (controle.ATTRIBUTS.length > 0) {
            return controle.ATTRIBUTS.map((att) => {
                // on ne traite que l'état du controle
                const attribut = refAttribut.find((ref) => ref.ID_ATTRIBUT === att.ID_ATTRIBUT && ref.ATT_CODE.startsWith("ETAT") === true);
                if (attribut) {
                    return (
                        <Box key={"att_key_" + controle.ID_CONTROLE + "_ctrl_" + attribut.ID_ATTRIBUT} titre={attribut.ATT_LIB} icone={AssignmentTurnedInIcon}>
                            <FormAttribut idControle={controle.ID_CONTROLE} idSecteur={idSecteur} idFamille={idFamille} attribut={attribut} refreshPastille={() => refreshPastille("attribut")} />
                        </Box>
                    );
                } else {
                    return <Fragment key={"Att_key_unknown_" + controle.ID_CONTROLE + "_" + att.ID_ATTRIBUT}></Fragment>;
                }
            });
        }
        return <Fragment key={"Att_key_unkno" + controle.ID_CONTROLE}></Fragment>;
    }

    /**
     * render des atttributs
     */
    function RenderAttributs() {
        // si au moins 1 attribut autre ( il y aura forcément 1 concernant l'état donc length > 1)
        if (controle && controle.ATTRIBUTS && controle.ATTRIBUTS.length > 1) {
            return (
                <Box titre={t("audit:infosComp")} icone={InfoIcon} key={"ctrl_" + controle.ID_CONTROLE + "_box_infoCompl"}>
                    <>
                        {controle.ATTRIBUTS.map((att) => {
                            const attribut = refAttribut.find((ref) => ref.ID_ATTRIBUT === att.ID_ATTRIBUT && ref.ATT_CODE.startsWith("ETAT") !== true);
                            if (attribut) {
                                return (
                                    <FormAttribut
                                        idControle={controle.ID_CONTROLE}
                                        idSecteur={idSecteur}
                                        idFamille={idFamille}
                                        attribut={attribut}
                                        key={"att_key_" + controle.ID_CONTROLE + "_ctrl_" + attribut.ID_ATTRIBUT}
                                        refreshPastille={() => refreshPastille("attribut")}
                                    />
                                );
                            }
                            return <Fragment key={"noAtt_CTRL_" + controle.ID_CONTROLE + "_noatt_" + att.ID_ATTRIBUT}></Fragment>;
                        })}
                    </>
                </Box>
            );
        }
        return <Fragment key={"noAtt_CTRL_" + controle.ID_CONTROLE}></Fragment>;
    }

    function RenderEnteteControle() {
        return (
            <>
                <Hidden smUp>
                    <div style={{ flexBasis: "2.5em", flexWrap: "wrap", display: "flex", marginRight: theme.spacing(1), minWidth: "2.5em" }}>
                        <AssignmentTurnedInIcon fontSize="small" color={etatRenseigne ? "primary" : "disabled"} />
                        <ChatBubbleIcon fontSize="small" color={commentaireRenseigne ? "primary" : "disabled"} />
                        <Badge badgeContent={docLoad > 0 ? <CircularProgress color="inherit" size={9} /> : nbDoc} max={9} color="secondary" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                            <DescriptionIcon fontSize="small" color={nbDoc > 0 ? "primary" : "disabled"} />
                        </Badge>
                        <EuroSymbolIcon fontSize="small" color={budgetRenseigne ? "primary" : "disabled"} />
                    </div>
                </Hidden>
                <Hidden xsDown>
                    <div style={{ flexBasis: "6.3em", flexWrap: "wrap", display: "flex", marginRight: theme.spacing(1), minWidth: "6.3em" }}>
                        <AssignmentTurnedInIcon color={etatRenseigne ? "primary" : "disabled"} />
                        <ChatBubbleIcon color={commentaireRenseigne ? "primary" : "disabled"} />
                        <Badge badgeContent={docLoad > 0 ? <CircularProgress color="inherit" size={9} /> : nbDoc} max={9} color="secondary" anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                            <DescriptionIcon color={nbDoc > 0 ? "primary" : "disabled"} />
                        </Badge>
                        <EuroSymbolIcon color={budgetRenseigne ? "primary" : "disabled"} />
                    </div>
                </Hidden>

                {controle.CTRL_LIB}
            </>
        );
    }

    /**
     * render du controle et de ses attributs
     */
    if (reponseControle) {
        return (
            <Accordion
                key={"key_ctrl_" + controle.ID_CONTROLE}
                name={"sect_" + idSecteur + "_ctrl_" + controle.ID_CONTROLE}
                onChange={handleChangePanel}
                panel={panelControleDemande.indexOf("sect_" + idSecteur + "_ctrl_" + controle.ID_CONTROLE) !== -1 ? "sect_" + idSecteur + "_ctrl_" + controle.ID_CONTROLE : ""}
                libelle={<RenderEnteteControle />}
                AccordeonClassName={{
                    summary: classes.controlePanelSummary,
                    typography: classes.controlePanelTypography,
                    details: classes.controlePanelDetails,
                    expanded: classes.controlePanelExpanded,
                }}
            >
                <FormGroup>
                    <FormControl>
                        <Grid component="span" container spacing={3}>
                            <RenderEtat />
                            <RenderAttributs />
                            <FormCommentaire idControle={controle.ID_CONTROLE} idSecteur={idSecteur} idFamille={idFamille} refreshPastille={() => refreshPastille("commentaire")} />
                            <FormBudgets idControle={controle.ID_CONTROLE} idSecteur={idSecteur} idFamille={idFamille} refreshPastille={() => refreshPastille("budget")} />
                            {UserCtx.droits["DroitModuleGED"] !== undefined && UserCtx.droits["DroitModuleGED"].visu && (
                                <FormDocumentListe idControle={controle.ID_CONTROLE} idSecteur={idSecteur} idFamille={idFamille} refreshPastille={() => refreshPastille("document")} />
                            )}
                            {/* {(UserCtx.droits['DroitModuleTodo'] !== undefined && UserCtx.droits['DroitModuleTodo'].visu && process.env.REACT_APP_HOST === 'http://realestate-audit.local') &&
                                // <TableauTodo readOnly={readOnly} reponseControle={reponseControle} setControle={setControle} idIncrement={idIncrement} />
                            } */}
                        </Grid>
                    </FormControl>
                </FormGroup>
            </Accordion>
        );
    }
    // Un component doit toujours retourner une valeur (même quand la condition du dessus est fausse)
    return <Fragment key={"ctrl_" + controle.ID_CONTROLE}></Fragment>;
}
