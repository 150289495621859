import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check'
import LogoOrpea from '../../images/logo_orpea.png'
import TextFied from '../outils/generique/TextFied'
import Alert from '@material-ui/lab/Alert'
import * as constants from '../../constants'
import { construitErreur } from '../../services/erreur'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.secondary,
        minHeight: '100vh',
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    entete: {
        background: theme.palette.primary.main,
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(6),
        textAlign: 'center',
        height: 'max-content',
        width: "100%",
    },
    contenu: {
        marginBottom: theme.spacing(2),
    },
    logo: {
        width: "75%",
        maxWidth: "400px",
    },
}));

/**
 * Composant MFA
 * Ce composant affiche le formulaire de saisie de MFA 
 * @param {{setConnect:Function}} props 
 */
export default function MFA({ setConnect }) {
    const classes = useStyles();
    const { key } = useParams()
    const [mfa, setMFA] = useState('')
    const [loading, setLoading] = useState(false)
    const [nbTentative, setNbTentative] = useState(0)
    const [t] = useTranslation(['connexion', 'commun'])
    const [erreurTxt, setErreurTxt] = useState('')


    /**
     * Fonction de controle du MFA
     * @param {integer} value 
     */
    async function checkMFA(value) {
        fetch(`/api/auth/connectionCheckMFA/${key}/${value}`)
            .then(construitErreur)
            .then((data) => {
                if (data) {
                    let ConnexionInfo = { 'cpt': data.Cpt, 'lang': data.LangueUser }
                    window.localStorage.setItem(constants.infUser, JSON.stringify(ConnexionInfo))
                    setConnect(true)
                }
            })
            .catch((error) => {
                if (error.message === '403') {
                    setErreurTxt(t('connexion:failedMFA'))
                    setNbTentative(nbTentative + 1)
                }
            })
            .finally(setLoading(false))
    }

    /**
     * MAJ du state MFA
     * @param {Event} event - Evenement sur modification du champs de saisie du MFA
     */
    const handleChange = event => {
        event.preventDefault()
        setMFA(event.target.value)
    }

    /**
     * Validation du MFA
     * @param {Event} event - Evenement de validation du formulaire
     */
    const handleSubmit = event => {
        event.preventDefault()
        setLoading(true)
        checkMFA(mfa)
    }

    /**
     * 
     * @param {*} libelle 
     * @param {*} name 
     * @param {*} value 
     * @param {*} onChange 
     * @param {*} helperText 
     * @param {*} errorText 
     * @param {*} disabled 
     */
    function renderInputText(libelle, name, value, onChange, helperText = '', errorText = '', disabled = false) {
        return (
            < TextFied as={name} type={'number'} name={name} libelle={libelle} value={value} onChange={onChange} helperText={helperText}
                errorText={errorText} disabled={disabled} maxLength={6} />
        )
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Grid container justify="flex-start" alignItems="stretch" direction="column" className={classes.root}>
                <Grid item className={classes.entete}>
                    <img alt="Logo" src={LogoOrpea} className={classes.logo} />
                </Grid>
                <Grid container justify="center" alignItems="flex-start">
                    <Grid item xs={11} sm={9} md={6} lg={3} className={classes.contenu}  >
                        <Paper className={classes.paper} align="center" >
                            <Typography variant="h5" component="h5" >{t('connexion:titre')}</Typography>
                            {erreurTxt !== '' ? <Alert severity="error">{erreurTxt}</Alert> : ''}
                            {renderInputText(t('connexion:MFA'), 'MFA', mfa, handleChange, '', '', false)}
                            {!loading ?
                                <FormControl>
                                    <Button type='submit' color="primary" variant="outlined" startIcon={<CheckIcon />} onClick={handleSubmit}>{t('commun:submit')}</Button>
                                </FormControl> :
                                <>
                                    <CircularProgress />
                                    <span className="sr-only">{t('connexion:userSearch')} </span>
                                </>
                            }
                        </Paper >
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}