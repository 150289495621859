import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import GetAppIcon from '@material-ui/icons/GetApp'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAfficheErreur } from '../../services/erreur'
import TextFied from '../outils/generique/TextFied'
import TextFiedMulti from '../outils/generique/TextFiedMulti';
import ExcelPreview from '../../images/preview/preview_excel.png'
import NoPreview from '../../images/preview/no_preview.png'
import PDFPreview from '../../images/preview/preview_pdf.png'
import DWGPreview from '../../images/preview/preview_dwg.png'
import PowerPointPreview from '../../images/preview/preview_powerpoint.png'
import TextPreview from '../../images/preview/preview_text.png'
import WordPreview from '../../images/preview/preview_word.png'
import Download from '../../images/preview/download.png'
import { Online, Offline } from 'react-detect-offline'
import ErrorIcon from '@material-ui/icons/SyncDisabled';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        maxWidth: "300px",
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    media: {
        height: 140,
    },
    contentCircularProgress: {
        display: 'flex',
        alignItems: 'center',
        placeContent: 'center',
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    backdropImg: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    cardBackRight: {
        marginLeft: 'auto!important',
    },
}));
/**
 * Composant FormDocument
 * Ce composant affiche un document
 * @param {{unDocument : DocumentADT,deleteDocument: function,modif:function,setLocal:function, Droit:Object  }}
 */
export default function FormDocument({ unDocument, deleteDocument, modif, setLocal, Droit }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [libelle, setLibelle] = useState(unDocument.META_LIBELLE && unDocument.META_LIBELLE.trim() !== '' ? unDocument.META_LIBELLE : unDocument.META_NOM)
    const [description, setDescription] = useState(unDocument.META_COMMENTAIRE)
    const [messageErreur, setMessageErreur] = useState({})
    const [chargement, setChargement] = useState(false)
    const [afficheErreur] = useAfficheErreur()
    const [DocContenu, setDocContenu] = useState(undefined)
    const [t] = useTranslation(['audit', 'commun', 'document'])
    const [enSave, setEnSave] = useState(false)


    /**
     * chargement différé de l'affichage du document quand un blob existe
     */
    useEffect(() => {
        if ((unDocument.ID_DOCUMENT < 0 || unDocument.MODIF === true) !== enSave) {
            setEnSave(unDocument.ID_DOCUMENT < 0 || unDocument.MODIF === true)
        }
    }, [unDocument.ID_DOCUMENT, unDocument.MODIF, enSave])

    /**
     * chargement différé de l'affichage du document quand un blob existe
     */
    useEffect(() => {
        if (unDocument.blob !== undefined) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                const content = fileReader.result;
                setDocContenu(content)
            }
            fileReader.readAsDataURL(unDocument.blob)
        }
    }, [unDocument.blob])


    /**
     * Charge un document
     * @param {DocumentADT} monDoc 
     */
    function chargeDoc(monDoc) {
        monDoc.getDocContenu()
            .then((monRetour) => {
                if (monRetour && monRetour.docContenu) {
                    setDocContenu(monRetour.docContenu)
                } else if (monRetour && monRetour.blob) {
                    const newDoc = unDocument
                    newDoc.blob = monRetour.blob
                    modif(newDoc)
                } else {
                    afficheErreur(t('document:Erreur chargement document'))
                }
            })
            .catch(afficheErreur)
            .finally(() => { setChargement(false) })

    }


    /**
     * Fonction de controle de saisie
     */
    function controleSaisie() {
        let erreur = {}
        switch (true) {
            case libelle === undefined || libelle === "":
                erreur.libelle = t("document:Le libelle du document est obligatoire")
                break;
            default:

        }
        return erreur
    }

    /**
     * Mise à jour d'un document dans les élèments locaux
     */
    function setDocument() {
        const newData = unDocument
        let erreur = controleSaisie()
        setMessageErreur(erreur)
        if (Object.keys(erreur).length === 0) {
            newData.META_LIBELLE = libelle
            newData.META_COMMENTAIRE = description
            newData.MODIF = true
            newData.update = false
            newData.insert = false
            if (newData.ID_DOCUMENT < 0) {
                newData.toIndexedDB()
            }
            modif(newData)
            setLocal(newData)
        }
    }

    /**
     * Passe le composant en mode modification
     */
    function ouvreForm() {
        const newData = unDocument
        newData.update = true
        modif(newData)
    }

    /**
     * annule la modification
     */
    function annuleForm() {
        if (unDocument.insert && unDocument.insert === true) {
            deleteDocument(unDocument.ID_DOCUMENT)
        } else {
            const newData = unDocument
            newData.update = false
            modif(newData)
        }
    }

    /**
     * ferme la prévisualisation de l'image
     */
    const handleClose = () => {
        setOpen(false);
    }

    /**
     * Récupère la prévisualisation du doucment
     * @param {DocumentADT} monDoc - document courant
     */
    function getPreview(monDoc) {
        let preview
        if (DocContenu === undefined) {
            preview = Download
        } else if (estUneImage(monDoc)) {
            preview = DocContenu
        } else {
            switch (monDoc.META_EXTENSION) {
                case 'csv':
                case 'xls':
                case 'xlsx':
                    preview = ExcelPreview
                    break;
                case 'pdf':
                    preview = PDFPreview
                    break;
                case 'doc':
                case 'docx':
                    preview = WordPreview
                    break;
                case 'ppt':
                case 'pptx':
                    preview = PowerPointPreview
                    break;
                case 'txt':
                    preview = TextPreview
                    break;
                case 'dwg':
                    preview = DWGPreview
                    break;
                default:
                    preview = NoPreview
                    break;
            }
        }

        return preview
    }

    /**
     * Controle si le document courant est une image
     * @param {DocumentADT} monDoc - document courant 
     */
    function estUneImage(monDoc) {
        if (monDoc && DocContenu && typeof DocContenu === 'string') {
            return DocContenu.startsWith('data:image/')
        }
        return false
    }

    /**
     * ouvre la prévisualisation de l'image
     */
    function handleClickPreview() {
        if (DocContenu === undefined) {
            setChargement(true)
            chargeDoc(unDocument)
        } else if (estUneImage(unDocument)) {
            setOpen(true)
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDERS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Fonction de render d'un champs text simple
     * @param {String} libelle - Libelle du champ 
     * @param {String} name - Nom du champ
     * @param {String} value - Valeur du champ
     * @param {Function} onChange - Fonction à executer sur modification du champ
     * @param {String} helperText - Texte d'aide
     * @param {String} errorText - Message d'erreur
     * @param {Boolean} disabled - Statut actif/ non actif du champ
     */
    function RenderInputText(libelle, name, value, onChange, helperText = '', errorText = '', disabled = false) {
        return (
            < TextFied as={name} type={'text'} name={name} libelle={libelle} value={value} onChange={onChange} helperText={helperText}
                errorText={errorText} disabled={disabled} key={"key_" + name} />
        )
    }
    /**
     * Fonction de render d'un champs text multiligne
     * @param {String} libelle - Libelle du champ 
     * @param {String} name - Nom du champ
     * @param {String} value - Valeur du champ
     * @param {Function} onChange - Fonction à executer sur modification du champ
     * @param {String} helperText - Texte d'aide
     * @param {String} errorText - Message d'erreur
     * @param {Boolean} disabled - Statut actif/ non actif du champ
     */
    function RenderInputTextMulti(libelle, name, value, onChange, helperText = '', errorText = '', disabled = false) {
        return (
            < TextFiedMulti as={name} type={'text'} name={name} libelle={libelle} value={value} onChange={onChange} helperText={helperText}
                errorText={errorText} disabled={disabled} rows={3} key={"key_" + name} />
        )
    }

    /**
     * Fonction de render d'un document en mode modification
     */
    function renderUpdate() {
        return (
            <>
                <CardContent>
                    {RenderInputText(t('document:Libelle'), "Libelle_ctrl_" + unDocument.ID_CONTROLE + "_doc_" + unDocument.ID_DOCUMENT, libelle, (e) => setLibelle(e.target.value), '', messageErreur.libelle)}
                    {RenderInputTextMulti(t('document:Description'), "Description_ctrl_" + unDocument.ID_CONTROLE + "_doc_" + unDocument.ID_DOCUMENT, description, (e) => setDescription(e.target.value), '', messageErreur.description)}
                </CardContent>
                <CardActions>
                    <Button className={classes.button} size="small" color="primary" onClick={() => setDocument()} variant="outlined"  >
                        <CheckIcon />
                    </Button>
                    <Button className={classes.button} size="small" color="primary" onClick={annuleForm} variant="outlined" >
                        <ClearIcon />
                    </Button>
                </CardActions>
            </>
        )
    }

    /**
     * Fonction de render d'un document en mode affichage
     */
    function renderFixe() {
        const handleDownload = async (e) => {
            if (DocContenu === undefined) {
                e.preventDefault()
                setChargement(true);
                chargeDoc(unDocument)

                document.getElementById('down_doc_' + unDocument.ID_DOCUMENT).click()
            }
        }
        return (
            <>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2"> {libelle} </Typography>
                    <Typography variant="body2" color="textSecondary" component="p"> {description} </Typography>
                </CardContent>
                <CardActions>
                    <Button id={'down_doc_' + unDocument.ID_DOCUMENT} className={classes.button} onClick={handleDownload} component="a" href={DocContenu} target="_blank" rel="noopener noreferrer" size="small" color="primary" variant="outlined" disabled={!Droit.visu} download>
                        <GetAppIcon />
                    </Button >
                    <Button className={classes.button} size="small" color="primary" onClick={ouvreForm} variant="outlined" disabled={!Droit.modif}>
                        <CreateIcon />
                    </Button >
                    <Button className={classes.button} size="small" color="primary" onClick={() => { deleteDocument(unDocument.ID_DOCUMENT) }} variant="outlined" disabled={!Droit.supp}>
                        <DeleteIcon />
                    </Button >
                    {enSave &&
                        <div className={classes.cardBackRight}>
                            <Online polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}> <CircularProgress size={16} thickness={5} /> </Online>
                            <Offline polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}><ErrorIcon color="secondary" /></Offline>
                        </div>
                    }
                </CardActions>
            </>
        )
    }

    /**
     * On ne render pas un document supprimé
     */
    if (unDocument.SUPPR === true) {
        return (<></>)
    }

    return (
        <>
            <Card className={classes.root}>
                <CardActionArea>
                    {chargement ?

                        <CardMedia
                            className={classes.media}
                            component="div"
                        >
                            <div className={classes.contentCircularProgress}>
                                <CircularProgress />
                            </div>
                        </CardMedia>
                        :
                        <CardMedia
                            className={classes.media}
                            src={getPreview(unDocument)}
                            component="img"
                            title={libelle}
                            onClick={handleClickPreview}
                        />
                    }

                </CardActionArea>
                {unDocument.update && unDocument.update === true ?
                    renderUpdate() : renderFixe()
                }
            </Card>
            {estUneImage(unDocument) &&
                <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                    <img alt="" src={DocContenu} className={classes.backdropImg} />
                </Backdrop>
            }
        </>
    )
}

