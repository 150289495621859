import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AuditContext } from '../../context/AuditContext'
import FormControle from './auditFormControle'
import Accordion from '../outils/utilitaires/accordion';
// import useTraceUpdate from '../outils/utilitaires/useTraceUpdate';

const useStyles = makeStyles((theme) => ({
    secteurPanelSummary: { ...theme.secteurPanel.summary },
    secteurPanelTypography: { ...theme.secteurPanel.typography },
}));

/**
 * Composant FormSecteur
 * Ce composant affiche un secteur
 * @param {{ secteur:RefSecteur, onChange: Function, panelSecteurDemande: Array, panelControleDemande:Array }} props 
 */
export default function FormSecteur({ secteur, onChange, panelSecteurDemande, panelControleDemande, idFamille }) {
    const classes = useStyles()
    const { monAudit } = useContext(AuditContext)



    // useTraceUpdate({ secteur, onChange, panelSecteurDemande, panelControleDemande, idIncrement }, FormSecteur.name)
    // useTraceUpdate({ panelSecteurDemande }, FormSecteur.name)


    const isOpen = useCallback(() => {
        return panelSecteurDemande.indexOf('sect_' + secteur.ID_SECTEUR) !== -1 ? true : false
    }, [panelSecteurDemande, secteur.ID_SECTEUR])

    /**
     * changement du panel en cours
     * @param {String} panel - panel à changer
     */
    const handleChangePanel = (panel, expanded) => {

        if (onChange) {
            onChange('secteur', panel)
        }
    }




    /**
     * Render des controles d'un secteur
     */
    function renderControles() {
        return secteur.CONTROLES.map(controle => {
            return (
                <FormControle
                    key={'ctrl_key_' + controle.ID_CONTROLE}
                    onChange={onChange}
                    controle={controle}
                    panelControleDemande={panelControleDemande}
                    idFamille={idFamille}
                    idSecteur={secteur.ID_SECTEUR}
                />
            )
        })

    }




    let renderSecteur = false
    secteur.CONTROLES.forEach(controle => {
        const reponseControle = monAudit.getReponseByControle(controle.ID_CONTROLE)
        if (!renderSecteur) {
            if (reponseControle) {
                renderSecteur = true
            }
        }

    })


    if (renderSecteur) {
        return (

            <Accordion
                key={'sect_' + secteur.ID_SECTEUR}
                name={'sect_' + secteur.ID_SECTEUR}
                onChange={handleChangePanel}
                panel={isOpen() ? 'sect_' + secteur.ID_SECTEUR : ''}
                libelle={secteur.SECT_LIB}
                AccordeonClassName={{
                    'summary': classes.secteurPanelSummary,
                    'typography': classes.secteurPanelTypography,
                }}

            >
                {renderControles()}
            </Accordion >



        )
    }
    return (<></>)
}