import React, { useState, createContext, useCallback, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom'
import { Attribut } from "../classes/attribut";
import { Audit } from "../classes/audit";
import { TypeAudit } from "../classes/typeAudit";
import { UserContext } from './UserContext'
import { useAfficheErreur } from '../services/erreur'
import { useTranslation } from 'react-i18next';
import NoData from '../component/outils/utilitaires/noData'

const AuditContext = createContext();

const AuditProvider = (props) => {
    let { IdEntete } = useParams()
    const [monAudit, setAudit] = useState(null)
    const [readOnly, setReadOnly] = useState(false)
    const UserCtx = useContext(UserContext)
    const [typesAudits, setTypesAudits] = useState()
    const [refAttribut, setRefAttributs] = useState()
    const [noData, setNoData] = useState(false)
    const [afficheErreur] = useAfficheErreur()
    const [t] = useTranslation(['commun', 'audit'])
    const [refreshDoc, setRefreshDoc] = useState(false)


    //***********************************************
    //CHARGEMENT REFERENTIEL ET AUDIT
    //***********************************************
    useEffect(() => {

        Attribut.getListe()
            .then((listeAtt) => {
                if (listeAtt && listeAtt.length > 0) {
                    if (JSON.stringify(listeAtt) !== JSON.stringify(refAttribut)) {
                        setRefAttributs(listeAtt)
                    }
                } else {
                    throw Error(t('audit:Impossible de charger le referentiel des attributs'))
                }
            }).catch(err => {
                afficheErreur(err)
                setNoData(true)
            })

        TypeAudit.getListe()
            .then((listeType) => {
                if (listeType && listeType.length > 0) {
                    if (JSON.stringify(listeType) !== JSON.stringify(typesAudits)) {
                        setTypesAudits(listeType)
                    }
                } else {
                    throw Error(t('audit:Impossible de charger le referentiel des types d\'audit'))
                }
            }).catch(err => {
                afficheErreur(err)
                setNoData(true)
            })

        if (monAudit === null) {
            const monAuditLocal = Audit.byLocalStorage(IdEntete)
            let unAudit = monAudit
            if (unAudit === null) { unAudit = new Audit() }
            unAudit.ID_ENTETE = IdEntete
            unAudit.get()
                .then((res) => {
                    if (monAuditLocal === null || monAuditLocal.DAT_DERN_MODIF === undefined || new Date(res.DAT_DERN_MODIF) > new Date(monAuditLocal.DAT_DERN_MODIF)) {
                        setAudit(res)
                        res.toLocalStorage()
                    } else {
                        setAudit(monAuditLocal)
                    }

                }).catch(err => {
                    afficheErreur(err)
                    setNoData(true)
                })
        }


    }, [IdEntete, refAttribut, typesAudits, t, monAudit, afficheErreur])

    /**
 * init du readOnly
 */
    useEffect(() => {
        if (monAudit !== null) {
            if (UserCtx.droits['DroitModuleAudit'] === undefined || UserCtx.droits['DroitModuleAudit'].modif === false) { setReadOnly(true) }
            if (monAudit.STAT_CODE && monAudit.STAT_CODE === 'CLOTURER') { setReadOnly(true) }
            if (monAudit.EST_DERNIER_ADT && monAudit.EST_DERNIER_ADT === false) { setReadOnly(true) }
        }
        return () => {
            setReadOnly(false)
        }

    }, [monAudit, UserCtx.droits])



    const handleChange = useCallback((element, event) => {
        let reponse, IDControle, IDAttrbiut, IDBudget, LibChamp
        const keyElement = event.target.name
        let valeur = event.target.value
        if (event.target.type === 'checkbox') { valeur = event.target.checked }

        //consolidation audit local session en cas de refresh
        let newVersionAudit = Audit.byLocalStorage(IdEntete)
        switch (element) {
            case 'attribut':
                [, , IDControle, IDAttrbiut] = keyElement.split('_')
                reponse = newVersionAudit.getReponseByControle(parseInt(IDControle))
                const attribut = reponse.getAttributByIDAttribut(parseInt(IDAttrbiut))
                attribut.setValeur(valeur)
                reponse.setAttribut(attribut)
                break;
            case 'budget':
                [, , IDControle, IDBudget] = keyElement.split('_')
                reponse = newVersionAudit.getReponseByControle(parseInt(IDControle))
                const budget = reponse.getBudget(parseInt(IDBudget))
                budget.setValeur(valeur)
                break;
            case 'commentaire':
                [, , IDControle] = keyElement.split('_')
                reponse = newVersionAudit.getReponseByControle(parseInt(IDControle))
                reponse.setValeurCommentaire(valeur)
                break;
            case 'document':
                [, , IDControle] = keyElement.split('_')
                reponse = newVersionAudit.getReponseByControle(parseInt(IDControle))
                reponse.DOCUMENTS = valeur
                break;
            case 'todo':
                [, , IDControle] = keyElement.split('_')
                reponse = newVersionAudit.getReponseByControle(parseInt(IDControle))
                reponse.TODOS = valeur
                break;
            case 'forecast':
                [, , IDControle, LibChamp] = keyElement.split('_')
                reponse = newVersionAudit.getReponseByControle(parseInt(IDControle))
                reponse.FORECAST.setValeur(LibChamp, valeur)
                break;
            default:
                break;
        }


        reponse.toLocalStorage(newVersionAudit.ID_ENTETE)


    }, [IdEntete])




    return (
        <AuditContext.Provider value={{ monAudit: monAudit, onChangeControle: handleChange, readOnly: readOnly, typesAudits: typesAudits, refAttribut: refAttribut, setRefreshDoc: setRefreshDoc, refreshDoc: refreshDoc }}>
            {noData ?
                <NoData />
                :
                props.children}
        </AuditContext.Provider>
    );
};

export { AuditContext, AuditProvider };