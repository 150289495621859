export function idToCodeLang(id_lang) {
    const index = refLangue.findIndex((uneLangue) => uneLangue.id === parseInt(id_lang))
    if (index >= 0) {
        return refLangue[index].code
    } else {
        return 'en'
    }
}

export function codeLangToId(code_lang = '') {
    const index = refLangue.findIndex((uneLangue) => code_lang.startsWith(uneLangue.code))
    if (index >= 0) {
        return refLangue[index].id
    } else {
        return 3
    }
}

export const refLangue = [
    { id: 18, libelle: 'Czech', code: 'cs' },
    { id: 3, libelle: 'English', code: 'en' },
    { id: 5, libelle: 'French', code: 'fr' },
    { id: 1, libelle: 'German', code: 'de' },
    { id: 11, libelle: 'Italian', code: 'it' },
    { id: 34, libelle: 'Polish', code: 'pl' },
    { id: 14, libelle: 'Portuguese', code: 'pt' },
    { id: 7, libelle: 'Spanish', code: 'es' }
]