import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import NewVersion from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { estVisible } from '../../services/stickyTableHeader'
import { UserContext } from '../../context/UserContext'
import { TitreContext } from '../../context/TitreContext'
import { ListeContext } from '../../context/ListeContextAudit'
import NoData from '../outils/utilitaires/noData'
import Chargement from '../outils/utilitaires/chargement'
import Filtre from './auditListeFiltre'
import { useAfficheErreur } from '../../services/erreur'
import { Audit } from '../../classes/audit';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    ...theme.table,
    statutAFaire: {
        borderLeftColor: theme.palette.error.dark,
    },
    statutEnCours: {
        borderLeftColor: theme.palette.warning.main,
    },
    statutCloture: {
        borderLeftColor: theme.palette.success.main,
    },
    hover: {},
    selected: {}
}));

/**
 * Composant AuditListe
 * Ce composant affiche la liste des audits
 */
export default function AuditListe() {
    let activeEventScroll = true

    const myStorage = window.localStorage
    const UserCtx = useContext(UserContext)
    const [, setTitre] = useContext(TitreContext)
    const [filtre, setFiltre] = useContext(ListeContext)
    const [t] = useTranslation(['audit', 'site', 'commun'])

    const [redirect, setRedirect] = useState(null)
    const [adt, setAdt] = useState()
    const [options, setOptions] = useState(filtre)
    const [loading, setLoading] = useState(false)

    const [renderFloatHeader, setRenderFloatHeader] = useState(false)
    const [leftScroll, setLeftScroll] = useState(0)

    const [orderBy, setOrderBy] = useState('SITE_ENTITE')
    const [direction, setDirection] = useState('asc')

    const classes = useStyles();

    const droitDupplication = !(UserCtx.droits['DroitModuleAuditHistorique'] === undefined || UserCtx.droits['DroitModuleAuditHistorique'].crea === false)

    const { enqueueSnackbar } = useSnackbar()
    const [afficheErreur] = useAfficheErreur()

    useEffect(() => {
        setTitre(t('menu:audit'))
    })

    /**
     * chargement de la liste des audits
     */
    useEffect(() => {

        const getAudit = () => {
            setLoading(true)
            let param = prepareFiltre() || {}
            param.lang = UserCtx.langue
            Audit.getListe(param)
                .then((ListeADT) => {
                    setAdt(ListeADT)
                })
                .catch(afficheErreur)
                .finally(() => {
                    setLoading(false)
                })
        }
        getAudit()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myStorage, options, UserCtx.User])

    /**
     * fonction de Raz des options du filtre
     */
    function razOptions() {
        setFiltre({})
        setOptions({})

        return {}
    }

    /**
     * fonction qui mémorise le filtre audit
     * @param {Object} newOptions - Represente les valeurs saisie dans le forumlaire
     */
    function memoriseOptions(newOptions) {
        setFiltre(newOptions)
        setOptions(newOptions)
    }

    /**
     * préparation du filtre avant appel API : on transforme les listes sous forme de chaine
     */
    function prepareFiltre() {
        let rechString = {}
        for (const [key, value] of Object.entries(options)) {
            rechString[key] = value.map(unElement => unElement.value).join(',')
        }
        return (rechString)
    }

    /**
     * donne la couleur selon le statut de l'audit
     * @param {String} status - Code statut d'un audit
     */
    function donneStyleButton(status) {
        let styleApplique = classes.statutDefault

        switch (status) {
            case 'A_FAIRE':
                styleApplique = [classes.statutDefault, classes.statutAFaire].join(" ")
                break
            case 'EN_COURS':
                styleApplique = [classes.statutDefault, classes.statutEnCours].join(" ")
                break
            case 'CLOTURER':
                styleApplique = [classes.statutDefault, classes.statutCloture].join(" ")
                break
            default:
        }

        return styleApplique
    }

    /**
     * donne initiale type d'audit
     * @param {String} typeLib - Type de l'audit
     */
    function donneInitialesTypeAudit(typeLib) {
        let initiale = ''

        // pour chaque mot du libellé, on retourne la 1ere lettre du mot si celui-ci contient au moins 4 lettres
        if (typeLib && typeLib !== null) {
            let tabLib = typeLib.split(' ')
            if (tabLib.length > 0) {
                tabLib.forEach(mot => {
                    let unMot = mot.trim()
                    if (unMot.length > 3) {
                        initiale = initiale + unMot[0].toUpperCase()
                    }
                })
            }
        }
        return initiale
    }

    /**
     * Création d'une nouvelle version d'un audit
     * @param {Integer} id - Id de l'audit à duppliquer
     */
    function handleNewVersion(id) {
        const audit = new Audit()
        audit.ID_ENTETE = id
        audit.duplique()
            .then((res) => {
                if (res.data) {
                    const new_audit = res.data
                    enqueueSnackbar(t('audit:La nouvelle version a bien été créée', { variant: "success" }))
                    setRedirect(new_audit)
                }
            })
            .catch(afficheErreur)
    }


    /**
     * function appelée à chaque modification du scroll de la table
     */
    function toggleScroll() {
        if (estVisible('positionColumn1')) {
            setRenderFloatHeader(false)
        } else {
            setRenderFloatHeader(true)
        }
    }

    /**
     * Change la position du header floatant
     */
    function setPositionLeft() {
        let floatHeader = document.getElementById('floatHeader')
        let tableContent = document.getElementById('tableContent')
        let scrollLeft = tableContent.scrollLeft
        let left = 0 - scrollLeft
        setLeftScroll(left)
        if (floatHeader) {
            floatHeader.style.left = left + 'px'
        }

    }

    /**
     * activation de l'évènement d'écoute du scrolling
     */
    if (activeEventScroll === true) {
        document.addEventListener("scroll", function (e) { toggleScroll() })
        activeEventScroll = false
    }

    /**
     * Fonction de tri du tableau
     * @param {Object} element1 - Premier élément à comparer
     * @param {Object} element2 - Deuxième élément à comparer
     */
    function sortElement(element1, element2) {

        const el1 = getElementValue(orderBy, element1)
        const el2 = getElementValue(orderBy, element2)
        if (el1 && el2) {
            if (direction === 'asc') {
                return el1.localeCompare(el2, undefined, { 'numeric': true })
            } else {
                return el2.localeCompare(el1, undefined, { 'numeric': true })
            }
        }
        return 0
    }

    /**
     * Fonction permettant de recupérer la valeur de l'element sur lequel porte le filtre
     * @param {string} orderBy - Correspond au nom de l'élément sur lequel on filtre
     * @param {Object} parent - Correspond à l'objet sur lequel appliquer le filtre
     */
    function getElementValue(orderBy, parent) {
        const index = orderBy.indexOf('.')
        if (index >= 0) {
            return getElementValue(orderBy.slice(index + 1), parent[orderBy.slice(0, index)])
        }
        return parent[orderBy] + ''
    }

    /**
     * Défini l'ordre de tri du tableau
     * @param {String} type - ascendant ou descendant 
     */
    function defineSort(type) {
        if (type === orderBy) {
            setDirection(direction === 'asc' ? 'desc' : 'asc')
        } else {
            setOrderBy(type)
            setDirection('asc')
        }
    }

    /**
     * render redirection vers le détail de l'audit
     */
    function renderRedirect() {
        if (redirect && redirect.ID_ENTETE && redirect.ID_ENTETE > 0) {
            return <Redirect to={'/audit/' + redirect.ID_ENTETE} push />
        }
    }

    /**
     * render entêtes du tableau
     */
    function renderTableHead() {
        return (
            <TableHead>
                <TableRow id="headerTable" >
                    <TableCell id="positionColumn1" className={classes.cellThStatut}>&nbsp;</TableCell>
                    <TableCell data-type="SITE_ENTITE" className={classes.cellTh}>
                        <TableSortLabel
                            active={orderBy === 'SITE_ENTITE'}
                            direction={orderBy === 'SITE_ENTITE' ? direction : 'asc'}
                            onClick={() => { defineSort('SITE_ENTITE') }}
                        >
                            {t('site:siteEntite')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell data-type="SITE_NOM" className={classes.cellTh}>
                        <TableSortLabel
                            active={orderBy === 'SITE_NOM'}
                            direction={orderBy === 'SITE_NOM' ? direction : 'asc'}
                            onClick={() => { defineSort('SITE_NOM') }}
                        >
                            {t('site:siteName')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell data-type="SITE_VILLE" className={classes.cellTh}>
                        <TableSortLabel
                            active={orderBy === 'SITE_VILLE'}
                            direction={orderBy === 'SITE_VILLE' ? direction : 'asc'}
                            onClick={() => { defineSort('SITE_VILLE') }}
                        >
                            {t('site:siteVille')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell data-type="PAYS_LIB" className={classes.cellTh}>
                        <TableSortLabel
                            active={orderBy === 'PAYS_LIB'}
                            direction={orderBy === 'PAYS_LIB' ? direction : 'asc'}
                            onClick={() => { defineSort('PAYS_LIB') }}
                        >
                            {t('site:sitePays')}
                        </TableSortLabel>
                    </TableCell>
                    {droitDupplication && <TableCell className={classes.cellTh}>{t('commun:Action')}</TableCell>}
                </TableRow>
            </TableHead>
        )
    }

    function renderTableFloatHead() {
        let headerBase = document.getElementById('headerTable')
        let subHeader = []
        headerBase.childNodes.forEach(unTH => {
            subHeader.push(
                <span className={unTH.className} style={{ 'width': unTH.offsetWidth }} key={'key_' + unTH.textContent}>
                    {unTH.dataset.type ?
                        <TableSortLabel
                            active={orderBy === unTH.dataset.type}
                            direction={orderBy === unTH.dataset.type ? direction : 'asc'}
                            onClick={() => { defineSort(unTH.dataset.type) }}
                        >
                            {unTH.textContent}
                        </TableSortLabel>
                        :
                        unTH.textContent
                    }
                </span >
            )
        })
        return (
            <div className={classes.HeaderFloat} id='floatHeader' style={{ 'left': leftScroll === 0 ? 'auto' : leftScroll + 'px' }}>
                {subHeader}
            </div>
        )

    }

    /**
     * render données
     */
    function renderTableRow() {

        return (

            adt
                .sort(sortElement)
                .map((unAdt, index) => (
                    <TableRow
                        key={unAdt.ID_ENTETE}
                        hover
                        classes={{ hover: classes.hover, selected: classes.selected }}
                        className={(index % 2 === 0) ? classes.rowPaire : classes.rowImpaire}
                    >
                        <TableCell
                            className={donneStyleButton(unAdt.STAT_CODE)}
                            //data-tip={unAdt.TYPE_LIB + ' ( ' + unAdt.STAT_LIB + ' )'}
                            onClick={() => setRedirect(unAdt)}
                        >
                            <Tooltip disableFocusListener title={unAdt.TYPE_LIB + ' ( ' + unAdt.STAT_LIB + ' )'} >
                                <Typography variant="body2" component="span">
                                    {donneInitialesTypeAudit(unAdt.TYPE_LIB)}
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell} onClick={() => setRedirect(unAdt)}>{unAdt.SITE_ENTITE}</TableCell>
                        <TableCell className={classes.cell} onClick={() => setRedirect(unAdt)}>{unAdt.SITE_NOM}</TableCell>
                        <TableCell className={classes.cell} onClick={() => setRedirect(unAdt)}>{unAdt.SITE_VILLE}</TableCell>
                        <TableCell className={classes.cell} onClick={() => setRedirect(unAdt)}>{unAdt.PAYS_LIB}</TableCell>
                        {droitDupplication && unAdt.EST_DERNIER_ADT === unAdt.ID_ENTETE && unAdt.STAT_CODE === 'CLOTURER' &&
                            <TableCell className={classes.cell}>
                                <IconButton edge="start" color="primary" aria-label="New version" onClick={() => handleNewVersion(unAdt.ID_ENTETE)} >
                                    <NewVersion />
                                </IconButton>
                            </TableCell>
                        }
                        {droitDupplication && (unAdt.EST_DERNIER_ADT !== unAdt.ID_ENTETE || unAdt.STAT_CODE !== 'CLOTURER') &&
                            <TableCell className={classes.cell}></TableCell>
                        }

                    </TableRow >
                ))
        )
    }

    /**
     * render structure du tableau
     */
    function renderTable() {
        if (adt) {
            if (adt.length > 0) {
                return (
                    <React.Fragment>
                        <TableContainer className={classes.content} id="tableContent" onScroll={setPositionLeft} >
                            {renderFloatHeader && renderTableFloatHead()}
                            <Table>
                                {renderTableHead()}
                                <TableBody>
                                    {renderTableRow()}
                                </TableBody>
                            </Table >
                        </TableContainer >
                    </React.Fragment>
                )

            } else {
                return (<NoData />)
            }
        }
        if (loading === true) {
            return (<Chargement active={true} />)
        }
    }

    return (
        <React.Fragment>
            <Filtre key={options} setOptions={memoriseOptions} razOptions={razOptions} options={options} />
            {renderRedirect()}
            {renderTable()}
        </React.Fragment>
    )
}