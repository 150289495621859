import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Grid, IconButton, TextareaAutosize, Typography } from '@material-ui/core';
import { UserContext } from '../../context/UserContext'
import { TitreContext } from '../../context/TitreContext'
import * as constant from '../../constants'
import VisiblityIcon from '@material-ui/icons/VisibilityOutlined'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Tableau from '../outils/utilitaires/Tableau';
import Box from '../outils/utilitaires/box';
import db from '../../services/db'





const useStyles = makeStyles((theme) => {
    return {
        content: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        divider: {
            ...theme.divider,
            marginBottom: theme.spacing(1),
        },
    }
});

const InfoButton = withStyles((theme) => ({
    ...theme.logs.button.info
}))(Button);

const WarningButton = withStyles((theme) => ({
    ...theme.logs.button.warning
}))(Button);

const LogsButton = withStyles((theme) => ({
    ...theme.logs.button.logs
}))(Button);

const ErrorButton = withStyles((theme) => ({
    ...theme.logs.button.error
}))(Button);

const FullButton = withStyles((theme) => ({
    ...theme.logs.button.full
}))(Button);

const ClearButton = withStyles((theme) => ({
    ...theme.logs.button.clear
}))(Button);

/**
 * Composant LocalDataManager
 * Affiche la page de gestion des données locales  de l'utilisateur
 */
export default function LocalDataManager() {
    const UserCtx = useContext(UserContext)
    const [, setTitre] = useContext(TitreContext)
    const classes = useStyles();
    const [t] = useTranslation(['menu', 'commun', 'parametre'])
    const [items, setItems] = useState([]);
    const [dataJSON, setDataJson] = useState({});
    const [open, setOpen] = useState(false);
    const [logAffiche, setLogAffiche] = useState('full')
    const [log, setLog] = useState([])

    const handleClose = () => {
        setOpen(false);
    };
    /** change le titre de la page */
    useEffect(() => {
        setTitre(t('menu:Developpeur mode'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        switch (logAffiche) {
            case 'full':
                db.table('logs').toArray((data) => {
                    setLog(data)
                })
                break;
            case 'log':
                db.table('logs').where({ type: 'log' }).toArray((data) => {
                    setLog(data)
                })
                break;
            case 'error':
                db.table('logs').where({ type: 'error' }).toArray((data) => {
                    setLog(data)
                })
                break;
            case 'warning':
                db.table('logs').where({ type: 'warn' }).toArray((data) => {
                    setLog(data)
                })
                break;
            case 'info':
                db.table('logs').where({ type: 'info' }).toArray((data) => {
                    setLog(data)
                })
                break;
            default:
                break;
        }
    }, [logAffiche])

    useEffect(() => {
        const newItems = []
        for (let i = 0; i < localStorage.length; i++) {

            let key = localStorage.key(i)
            // let data = JSON.parse(localStorage.getItem(key))
            let libelle = constant.affichageUser[key] && constant.affichageUser[key].libelle ? constant.affichageUser[key].libelle : undefined
            let group = constant.affichageUser[key] && constant.affichageUser[key].group ? constant.affichageUser[key].group : undefined
            if (libelle === undefined && key.indexOf('_') !== -1) {
                for (const property in constant.affichageUser) {
                    if (key.indexOf(property) !== -1) {
                        libelle = constant.affichageUser[property].libelle
                        libelle += ' n°' + key.split('_')[1]
                        group = constant.affichageUser[property].group
                    }

                }
            }
            if (libelle === undefined) {
                libelle = key
                group = 'Autre'
            }

            newItems.push({ key: key, libelle: libelle, group: group })
        }
        setItems(newItems)
    }, [])

    function handleDelete(listeItems, oldData) {
        localStorage.removeItem(oldData.key)
        setItems(listeItems)
    }
    function isJson(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }
    function handleDeleteSW() {
        if (navigator.serviceWorker !== undefined) {
            navigator.serviceWorker.getRegistrations()
                .then(function (registrations) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                })
        }
    }

    function localStorageRender() {
        return (
            <Box titre={t('parametre:Liste des données locales')}>
                <Tableau
                    columns={[
                        { title: t('parametre:Groupe'), field: 'group', type: 'string', small: true, defaultSort: 'asc' },
                        { title: t('parametre:Libelle'), field: 'libelle', type: 'string', small: true },
                    ]}
                    data={items}
                    setData={handleDelete}
                    deleteAuth={true}
                    expandable={false}
                    actions={[{
                        icon: VisiblityIcon,
                        tooltip: t('parametre:Ouvrir le détail'),
                        onClick: (event, rowData) => {
                            let data = localStorage.getItem(rowData.key)
                            if (isJson(data)) {
                                data = JSON.parse(data)
                            }
                            setOpen(true)
                            setDataJson(data)
                        }
                    },
                    {
                        icon: DeleteIcon,
                        tooltip: 'Supprime tous les refernetiels',
                        isFreeAction: true,
                        onClick: () => {
                            localStorage.clear()
                            setItems([])
                            UserCtx.setConnect(false)
                        }
                    }

                    ]}

                />

            </Box>
        )
    }

    function serviceWorkerRender() {
        const isActif = navigator.serviceWorker !== undefined
        return (
            <Box titre={t('parametre:Service Worker')} containerChlid={true}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography paragraph >
                        Etat du Service Workers : {isActif ? t('parametre:Actif') : t('parametre:Inactif')}
                        {isActif ?
                            <IconButton aria-label="close" className={classes.margin} onClick={handleDeleteSW}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            :
                            <></>
                        }
                    </Typography>
                </Grid>
            </Box>
        )
    }

    function databaseRender() {
        const deleteBDD = () => {
            db.table('documents').clear()
        }

        return (
            <Box titre={t('parametre:Base de données locale (IndexedDB)')} containerChlid={true}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography paragraph >
                        Vider la base de données :

                        <IconButton aria-label="close" className={classes.margin} onClick={deleteBDD}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>

                    </Typography>
                </Grid>
            </Box>
        )
    }

    function consoleRender() {

        const deleteBDD = () => {
            db.table('logs').clear()
        }

        return (
            <Box titre={t('parametre:Base de données locale (IndexedDB)')} containerChlid={true}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <ButtonGroup variant="contained">
                        <LogsButton onClick={() => setLogAffiche('log')}>logs</LogsButton>
                        <InfoButton onClick={() => setLogAffiche('info')}>info</InfoButton>
                        <WarningButton onClick={() => setLogAffiche('warning')}>warning</WarningButton>
                        <ErrorButton onClick={() => setLogAffiche('error')}>error</ErrorButton>
                        <FullButton onClick={() => setLogAffiche('full')}>Full</FullButton>
                        <ClearButton onClick={() => { deleteBDD(); setLogAffiche('full'); }}>Clear</ClearButton>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <TextareaAutosize
                        style={{ width: '90%', marginLeft: '2%' }}
                        rowsMax={10}
                        defaultValue={
                            log.sort((a, b) => (a.date - b.date)).map(element => (
                                JSON.stringify(element, null, 2)
                            ))
                        }
                        readOnly={true}
                    />





                </Grid>
            </Box >
        )
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>

            <div className={classes.content}>
                {open ?


                    <div>
                        <IconButton aria-label="close" className={classes.margin} onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        <pre>{JSON.stringify(dataJSON, null, 2)}</pre>
                    </div>

                    :
                    <>
                        {consoleRender()}
                        {databaseRender()}
                        {serviceWorkerRender()}
                        {localStorageRender()}
                    </>
                }
            </div >
        </>
    )
}

