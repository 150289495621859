import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';

////////////////////////////////////////////////////////////////////////
//                      import REALESTATE
import { useSnackbar } from 'notistack';

import { UserContext } from '../../context/UserContext'
import { TitreContext } from '../../context/TitreContext'

import Tableau from '../outils/utilitaires/Tableau';
import Chargement from '../outils/utilitaires/chargement'
import ModRegion from '../outils/modele/Region';
import ModPays from '../outils/modele/Pays';
import ModTypeActivite from '../outils/modele/TypeActivite';

import { SiteUpdate } from '../../classes/siteUpdate';
import { Site } from '../../classes/site';

import { MAJBatiment, prepare_MAJActivite } from '../../services/site';
import { AjouteDerniereModif } from '../../services/derniereModif'
import { useAfficheErreur } from '../../services/erreur'
import TextField from '../outils/generique/TextFied';
import TextFiedMulti from '../outils/generique/TextFiedMulti';
import Box from '../outils/utilitaires/box';
import Interrupteur from '../outils/generique/Interrupteur';
import SelectMono from '../outils/generique/SelectMono';


////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paperBox: {
        paddingBottom: "15px",
    },
    firstInterr: {
        marginTop: "30px",
    },
    bouton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SiteForm() {
    const UserCtx = useContext(UserContext)
    const [, setTitre] = useContext(TitreContext)
    const [t] = useTranslation(['site', 'commun', 'menu'])
    let { idSite } = useParams()

    const [site, setSite] = useState(Site.getByID(idSite))

    const [messageErreur, setMessageErreur] = useState({})
    const [loading, setLoading] = useState(false)

    const [readOnly, setReadOnly] = useState(false)

    const [boutonValiderInactif, setBoutonValiderInactif] = useState(readOnly)

    const [dataBatiment, setDataBatiment] = useState([])
    const [dataActivite, setDataActivite] = useState([])

    const { enqueueSnackbar } = useSnackbar()

    const [afficheErreur] = useAfficheErreur()

    // tableau de données pour les champs listes
    const anneeData = []
    const classes = useStyles();

    useEffect(() => {
        let estEnErreur = false
        if (Object.keys(messageErreur).length > 0) {
            estEnErreur = Object.keys(messageErreur).every((champ) => messageErreur[champ] !== '')
        }
        if (estEnErreur) {
            setBoutonValiderInactif(estEnErreur)
        } else {
            setBoutonValiderInactif(readOnly)
        }


    }, [messageErreur, readOnly])

    //initialisation du site
    useEffect(() => {

        const unSite = new Site()
        if (idSite) {
            setLoading(true)
            if (UserCtx.droits['DroitModuleSite'].modif === false) { setReadOnly(true); setBoutonValiderInactif(true) }
            unSite.ID_SITE = idSite
            unSite.get()
                .then((res) => {
                    setSite(res)
                    setDataBatiment(res.BATIMENTS)
                    setDataActivite(Array.from(res.ACTIVITES, (item) => ({ 'value': item.IDCHAN_TYPE, 'label': item.LIBELLE, 'code': item.CODE, 'key': item.IDCHAN_TYPE })))

                })
                .catch(afficheErreur)
                .finally(() => {
                    setTitre(t('menu:site'), unSite.SITE_NOM)
                    setLoading(false)
                })
        } else {
            if (UserCtx.droits['DroitModuleSite'].crea === false) { setReadOnly(true); setBoutonValiderInactif(true) }
            setSite(unSite)
        }

        return () => {
            setSite()
            setDataBatiment([])
            setDataActivite([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserCtx.droits, idSite])

    // alimentation du tableau d"années à fournir au composant <select>
    function dataAnnee() {

        let dateDuJour = new Date()

        if (!anneeData || anneeData.length === 0) {
            for (let pas = 1900; pas <= dateDuJour.getFullYear(); pas++) {
                let uneData = {}
                uneData.id = parseInt(pas)
                uneData.libelle = pas
                uneData.code = pas
                anneeData.push(uneData)
            }
        }
        return anneeData
    }



    //Alimentation de la liste déroulante dans les batiments
    function listeNiveau() {
        const element = {}
        for (let index = -10; index < 100; index++) {
            element[index] = (index < 0) ? 'R' + index : 'R+' + index
        }
        return element
    }


    /**
      * sélection d'une activité
      * @param {Event} event - Event de selection d'une activité
      */
    function handleChangeActivite(tabActivite, erreur) {
        setDataActivite(tabActivite)

        return erreur
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //  SETTERS (remarque : chaque champ appelle la fonction setDonnee. 
    //  selon le nom/type du champ, on peut y ajouter des contrôles spécifiques
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    function controleSaisie(name, value, type = undefined, min = undefined, max = undefined) {

        let controleTxt = ''


        return controleTxt
    }

    // controle et met à jour le state site
    function setDonnee(event, erreur, min, max) {

        let controleTxt = ''

        // récupère les infos de l'évènement
        let { name, value, type, checked } = event.target;

        // on met à jour le state
        switch (true) {
            case type === 'checkbox':   // traitement pour les checkbox


                if (checked === true)
                    setSite({ ...site, [name]: 1 })
                else {
                    setSite({ ...site, [name]: 0 })
                }
                break;

            default:                    // traitement par défaut

                setSite({ ...site, [name]: value })
                break;
        }
        if (erreur !== '') {
            controleTxt = erreur
        } else {
            controleTxt = controleSaisie(name, value, type, min, max)
        }
        // on met à jour l'erreur
        setMessageErreur({ ...messageErreur, [name]: controleTxt })

        return controleTxt
    }

    // enregistrement du formulaire
    function EnregistreFormulaire() {

        let tabErreur = []
        let controleText = ''
        const SiteSave = new Site(site)

        SiteSave.BATIMENTS = dataBatiment
        SiteSave.ACTIVITES = prepare_MAJActivite(dataActivite, SiteSave.ACTIVITES)

        // on controle chaque champ de saisie possédant un controle     
        tabErreur['SITE_ENTITE'] = controleSaisie('SITE_ENTITE', site.SITE_ENTITE)
        tabErreur['PAYS'] = controleSaisie('PAYS', site.PAYS)
        tabErreur['REGION'] = controleSaisie('REGION', site.REGION)
        tabErreur['SPRF_TERRAIN'] = controleSaisie('SPRF_TERRAIN', site.SPRF_TERRAIN, 'number', 0)
        tabErreur['SRF_SOL'] = controleSaisie('SRF_SOL', site.SRF_SOL, 'number', 0)
        tabErreur['SRF_CONS_REST'] = controleSaisie('SRF_CONS_REST', site.SRF_CONS_REST, 'number', 0)
        tabErreur['NB_BATIMENT'] = controleSaisie('NB_BATIMENT', site.NB_BATIMENT, 'number', 0)
        tabErreur['SRF_BRUTE'] = controleSaisie('SRF_BRUTE', site.SRF_BRUTE, 'number', 0)
        tabErreur['SRF_NETTE'] = controleSaisie('SRF_NETTE', site.SRF_NETTE, 'number', 0)
        tabErreur['SRF_PLANCHER'] = controleSaisie('SRF_PLANCHER', site.SRF_PLANCHER, 'number', 0)
        tabErreur['NB_LIT'] = controleSaisie('NB_LIT', site.NB_LIT, 'number', 0)
        tabErreur['CAPACIT_MAX'] = controleSaisie('CAPACIT_MAX', site.CAPACIT_MAX, 'number', 0)

        // cumule les erreurs des champs
        controleText += tabErreur['SITE_ENTITE'] + ' '
        controleText += tabErreur['PAYS'] + ' '
        controleText += tabErreur['REGION'] + ' '
        controleText += tabErreur['SPRF_TERRAIN'] + ' '
        controleText += tabErreur['SRF_SOL'] + ' '
        controleText += tabErreur['SRF_CONS_REST'] + ' '
        controleText += tabErreur['NB_BATIMENT'] + ' '
        controleText += tabErreur['SRF_BRUTE'] + ' '
        controleText += tabErreur['SRF_NETTE'] + ' '
        controleText += tabErreur['SRF_PLANCHER'] + ' '
        controleText += tabErreur['NB_LIT'] + ' '
        controleText += tabErreur['CAPACIT_MAX'] + ' '

        if (controleText.trim() !== '') {
            // si des erreurs existent, on n'appelle pas l'API
            setMessageErreur(tabErreur)
            return false
        }

        if (navigator.onLine) {

            setBoutonValiderInactif(true)

            SiteSave.save()
                .then((res) => {
                    if (res && res.data) {

                        const newSite = new Site(res.data)

                        // maj du site
                        setSite(newSite)
                        setDataActivite(Array.from(newSite.ACTIVITES, (item) => ({ 'value': item.IDCHAN_TYPE, 'label': item.LIBELLE, 'code': item.CODE, 'key': item.IDCHAN_TYPE })))

                        // maj des bâtiments
                        MAJBatiment(newSite.ID_SITE, newSite.BATIMENTS)
                            .then((listeBatiment) => {
                                setDataBatiment(listeBatiment)
                                newSite.updateLocalStorage()
                            })
                            .catch(() => {
                                enqueueSnackbar(t("site:Erreur lors de la sauvegarde des batiments"), { variant: 'error' })
                            })

                        newSite.updateLocalStorage()
                        enqueueSnackbar(t("commun:Sauvegarde effectuée"), { variant: 'success' })
                    }
                })
                .catch(afficheErreur)
                .finally(() => setBoutonValiderInactif(readOnly))

        } else {
            const ListeSiteToUpdate = new SiteUpdate()
            ListeSiteToUpdate.add(SiteSave.ID_SITE)
            SiteSave.toLocalStorage()
            SiteSave.updateLocalStorage()
        }

        //Maj LastModif
        AjouteDerniereModif('site', site)
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDERS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    // render du formulaire
    function renderForm() {
        if (site && ((Number(site.ID_SITE) === 0 && Number(idSite) === 0) || site.ID_SITE > 0)) {
            return (

                < React.Fragment >

                    {t &&
                        <Button disabled={boutonValiderInactif} aria-label={t('commun:submit')} className={classes.bouton} variant="outlined" color="primary" type="submit" onClick={() => EnregistreFormulaire()} startIcon={<CheckIcon />}>{t('commun:submit')}</Button>
                    }
                    <FormGroup className={classes.content} >
                        <Grid container spacing={1}>
                            <Box titre={t('site:titreIdentification')} lg={6}>
                                <TextField value={site.SITE_ENTITE} name={'SITE_ENTITE'} libelle={t('site:siteEntite')} onChange={setDonnee} disabled={true} required />
                                <TextField value={site.SITE_NOM} name={'SITE_NOM'} libelle={t('site:siteName')} onChange={setDonnee} disabled={readOnly} required />
                                <TextFiedMulti value={site.ADRESSE} name={'ADRESSE'} libelle={t('site:siteAdresse')} onChange={setDonnee} disabled={readOnly} />
                                <TextField value={site.SITE_CP} name={'SITE_CP'} libelle={t('site:siteCP')} onChange={setDonnee} disabled={readOnly} />
                                <TextField value={site.SITE_VILLE} name={'SITE_VILLE'} libelle={t('site:siteVille')} onChange={setDonnee} disabled={readOnly} />
                                <ModPays onChange={setDonnee} name={'PAYS'} value={site.PAYS.ID_PAYS} disabled={readOnly} required />
                                <ModRegion onChange={setDonnee} name={'REGION'} value={site.REGION.ID_REGION} disabled={readOnly} dependance={site.PAYS.ID_PAYS} />
                                <ModTypeActivite multi={true} onChange={handleChangeActivite} value={dataActivite} disabled={readOnly} />
                            </Box>

                            <Box titre={t('site:titreUrbanisme')} lg={6}>
                                <TextField type="number" value={site.SPRF_TERRAIN} name={'SPRF_TERRAIN'} libelle={t('site:surfaceTerrain')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <TextField type="number" value={site.SRF_SOL} name={'SRF_SOL'} libelle={t('site:surfaceSol')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <TextField type="number" value={site.SRF_CONS_REST} name={'SRF_CONS_REST'} libelle={t('site:surfaceConsRest')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <div className={classes.firstInterr}>
                                    <Interrupteur value={site.AMIANTE} name={'AMIANTE'} libelle={t('site:amiante')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.PERMIS_CONS_DEP} name={'PERMIS_CONS_DEP'} libelle={t('site:permisDepose')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.PERMIS_CONS_ACPT} name={'PERMIS_CONS_ACPT'} libelle={t('site:permisAccepte')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.CONF_URBAN} name={'CONF_URBAN'} libelle={t('site:conforUrban')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.ZN_SISMIQ} name={'ZN_SISMIQ'} libelle={t('site:zoneSismiq')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.ZN_INOND} name={'ZN_INOND'} libelle={t('site:zoneInond')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.ZN_EXP_RADON} name={'ZN_EXP_RADON'} libelle={t('site:zoneRadon')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.ZN_MON_HISTO} name={'ZN_MON_HISTO'} libelle={t('site:zoneMonuHisto')} onChange={setDonnee} disabled={readOnly} />
                                </div >
                                <TextFiedMulti value={site.SERVITUDE} name={'SERVITUDE'} libelle={t('site:servitude')} onChange={setDonnee} disabled={readOnly} />
                            </Box>

                            <Box titre={t('site:titreDescription')} lg={6}>
                                <TextField type="number" value={site.NB_BATIMENT} name={'NB_BATIMENT'} libelle={t('site:nbBatiment')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <SelectMono value={site.ANNEE_CONS} name={'ANNEE_CONS'} libelle={t('site:anneeConstr')} onChange={setDonnee} disabled={readOnly} mesChoix={dataAnnee()} />
                                <SelectMono value={site.DERN_RENOV_MAJ} name={'DERN_RENOV_MAJ'} libelle={t('site:anneeDernRenov')} onChange={setDonnee} disabled={readOnly} mesChoix={dataAnnee()} />
                                <SelectMono value={site.ANNEE_ACQUIS} name={'ANNEE_ACQUIS'} libelle={t('site:anneeAcqui')} onChange={setDonnee} disabled={readOnly} mesChoix={dataAnnee()} />
                                <SelectMono value={site.ANNEE_EXPL} name={'ANNEE_EXPL'} libelle={t('site:anneeExpl')} onChange={setDonnee} disabled={readOnly} mesChoix={dataAnnee()} />
                                <SelectMono value={site.ANNEE_EXPL_ORPEA} name={'ANNEE_EXPL_ORPEA'} libelle={t('site:anneeExplOrpea')} onChange={setDonnee} disabled={readOnly} mesChoix={dataAnnee()} />
                                <div className={classes.firstInterr}>
                                    <Interrupteur value={site.PROPRIETAIRE} name={'PROPRIETAIRE'} libelle={t('site:proprietaire')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.LOUE} name={'LOUE'} libelle={t('site:loue')} onChange={setDonnee} disabled={readOnly} />
                                    <Interrupteur value={site.BATIMENT_CONNECT} name={'BATIMENT_CONNECT'} libelle={t('site:batimtConnect')} onChange={setDonnee} disabled={readOnly} />
                                </div>
                                <TextField type="number" value={site.SRF_BRUTE} name={'SRF_BRUTE'} libelle={t('site:surfaceBrute')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <TextField type="number" value={site.SRF_NETTE} name={'SRF_NETTE'} libelle={t('site:surfaceNette')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <TextField type="number" value={site.SRF_PLANCHER} name={'SRF_PLANCHER'} libelle={t('site:surfacePlancher')} onChange={setDonnee} disabled={readOnly} min={0} />

                            </Box>
                            <Box titre={t('site:titreExploitation')} lg={6}>
                                <TextField type="number" value={site.NB_LIT} name={'NB_LIT'} libelle={t('site:nblitsAutorises')} onChange={setDonnee} disabled={readOnly} min={0} />
                                <TextField type="number" value={site.CAPACIT_MAX} name={'CAPACIT_MAX'} libelle={t('site:capaciteMax')} onChange={setDonnee} disabled={readOnly} min={0} />
                            </Box>

                            <Box titre={t('site:titreBatiments')}>
                                <Tableau
                                    columns={[
                                        { title: t('site:N°bâtiment'), field: 'N_BATIMENT', type: 'numeric', small: true },
                                        { title: t('site:Nb total de niveaux'), field: 'NB_TOT_BAT', type: 'numeric', small: true },
                                        { title: t('site:Nb total de niveaux en sous-sol'), field: 'NIVEAU_SOUS_SOL', type: 'numeric', },
                                        {
                                            title: t('site:Rez-de-chaussé'), field: 'REZ_CHAUSSEE', type: 'boolean'
                                            , render: rowData => (rowData.REZ_CHAUSSEE === 1 || rowData.REZ_CHAUSSEE === true ? t('commun:oui') : t('commun:non'))
                                            , editComponent: tableData => (
                                                <Interrupteur value={tableData.rowData.REZ_CHAUSSEE} name={'REZ_CHAUSSEE_' + tableData.id} libelle="" onChange={e => {
                                                    var data = { ...tableData.rowData }
                                                    data.REZ_CHAUSSEE = e.target.checked
                                                    tableData.onRowDataChange(data)
                                                }} disabled={readOnly} />
                                            ),
                                        },
                                        { title: t('site:Rez-de-Niveau le plus élevé'), field: 'NIVEAU_ELEV', lookup: listeNiveau() },
                                    ]}
                                    data={dataBatiment}
                                    setData={setDataBatiment}
                                    disabled={readOnly}
                                    updateAuth={UserCtx.droits['DroitModuleSiteBatiment'].modif}
                                    deleteAuth={UserCtx.droits['DroitModuleSiteBatiment'].supp}
                                    addAuth={UserCtx.droits['DroitModuleSiteBatiment'].crea}
                                />
                            </Box>

                        </Grid>
                    </FormGroup >

                </React.Fragment >
            )
        }
        if (loading === true) {
            return (<Chargement active={true} />)
        }
    }

    return (
        <React.Fragment>
            {renderForm()}
        </React.Fragment>
    )
}
