import React, { useState, createContext } from "react";


const ListeContext = createContext();

const ListeProvider = (props) => {
    const [filtreSite, setFiltreSite] = useState({})
    return (
        <ListeContext.Provider value={[filtreSite, setFiltreSite]}>
            {props.children}
        </ListeContext.Provider>
    );
};

export { ListeContext, ListeProvider };