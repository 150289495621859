import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class TodoPriorite extends Globale {

    constructor(unTodoPriorite = {}) {
        super()

        this.ID_TODO_PRIORITE = unTodoPriorite.ID_TODO_PRIORITE
        this.LIBELLE = unTodoPriorite.LIBELLE
        this.CODE = unTodoPriorite.CODE
        this.ORDRE = unTodoPriorite.ORDRE


    }

    static getRefName() {
        return 'TodoPriorite'
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {SiteCandidat}
     * @static
     */
    static byLocalStorage() {
        let ListeTodoPriorite = JSON.parse(window.localStorage.getItem(constants.localTodoPrioriteListe))
        if (ListeTodoPriorite === null || !Array.isArray(ListeTodoPriorite)) { ListeTodoPriorite = [] }
        return ListeTodoPriorite
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListeTodoPriorite) {
        if (ListeTodoPriorite) {
            window.localStorage.setItem(constants.localTodoPrioriteListe, JSON.stringify(ListeTodoPriorite))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unTodoPriorite = this.byLocalStorage().find(unePriorite => unePriorite.ID_TODO_PRIORITE === id)

        if (unTodoPriorite) {
            return new TodoPriorite(unTodoPriorite)
        } else {
            return new TodoPriorite()
        }
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG

            const url = `/api/referentiel/prioriteTodo`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(TodoPriorite.getRefName())

                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(this.byLocalStorage())
            }
        })
    }

}


