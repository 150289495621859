import { Globale } from './globale'

/**
 * Sert à la gestion des budgets dans l'application
 * @class
 * @extends Globale
 */
export class Budget extends Globale {
    constructor(unBudget) {
        super()
        this.BUD_LIG_ANNEE = unBudget.BUD_LIG_ANNEE
        this.BUD_LIG_MONTANT = unBudget.BUD_LIG_MONTANT
        this.ID_LIGNE_BUDGET = unBudget.ID_LIGNE_BUDGET
        this.MODIF = unBudget.MODIF
    }


    /**
     * Fonction permettant de mettre à jour la montant du budget
     * @param {Integer/String} uneValeur - Valeur du budget
     */
    setValeur(uneValeur) {
        this.BUD_LIG_MONTANT = uneValeur
        this.MODIF = true

    }
    /**
     * Fonction de comparaison entre le budget passé en parametre et le budget courant
     * @param {Budget} unAtt - Représente le budget du localStorage à comparer avec le budget courant
     * @param {Boolean} identique - Variable de controle de similitude de la réponse du WS et du localStorage pour une réponse / un controle 
     */
    compareTo(unBud, identique) {
        if (unBud.MODIF === true) {
            identique = false
        } else {
            unBud.MODIF = false
            identique = (unBud.BUD_LIG_MONTANT !== this.BUD_LIG_MONTANT) ? false : identique
        }
    }
}