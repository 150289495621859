import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { TitreContext } from '../../context/TitreContext'


const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
}));

/**
 * Composant TitreMenu
 * Affiche le titre des pages
 */
export default function TitreMenu() {
    const classes = useStyles();
    const [titreApp,] = useContext(TitreContext)

    /**
     * Retourne le titre de la page
     */
    function donneTitre() {
        let titre = titreApp['principal']

        if (titreApp['complement'] && titreApp['complement'] !== '') {
            titre = titre + ' - ' + titreApp['complement']
        }

        return titre
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <React.Fragment>
            <Typography width='200px' variant="h6" className={classes.title} noWrap={true}>{donneTitre()}</Typography>
        </React.Fragment>
    )
}
