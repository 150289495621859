
import React, { useState, useEffect, useContext } from 'react';
import SelectMono from '../generique/SelectMono'
import SelectMulti from '../generique/SelectMulti'
import { TypeActivite } from '../../../classes/typeActivite';
import { useAfficheErreur } from '../../../services/erreur';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/UserContext'

export default function ModTypeActivite({ multi = false, onChange, value, disabled, ...Rest }) {

    const [afficheErreur] = useAfficheErreur()
    const [listeTypeActivite, setListeTypeActivite] = useState([])
    const [t] = useTranslation(['modele'])
    const [refreshLangue, setRefreshLangue] = useState(true)
    const UserCtx = useContext(UserContext)

    useEffect(() => {
        const identifiant = Rest.name || 'SEL_TypeActivite'
        UserCtx.setAfterChangeLangue(old => [...old, [identifiant,
            () => {
                setRefreshLangue(true)
            }]])
        return () => {
            let old = [...UserCtx.afterChangeLangue]
            const index = old.findIndex(([id, uneFonction]) => id === identifiant)
            if (index >= 0) { old.splice(index, 1) }
            UserCtx.setAfterChangeLangue(old)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (refreshLangue) {
            refresh()
            setRefreshLangue(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshLangue])

    function refresh() {
        TypeActivite.getListe()
            .then((liste) => {
                setListeTypeActivite(
                    Array.from(liste, (item) => ({ 'value': item.IDCHAN_TYPE, 'label': item.LIBELLE, 'code': item.CODE, 'key': item.IDCHAN_TYPE }))
                )
            })
            .catch(afficheErreur)
    }


    function handleChange(e, ...autre) {
        if (multi === false) {
            e.target.value = TypeActivite.getByID(e.target.value)
        }
        return onChange(e, ...autre)
    }

    const communAtt = {
        libelle: Rest.libelle ? Rest.libelle : t('modele:TypeActiviteLibelle'),
        name: Rest.name ? Rest.name : 'SEL_TypeActivite',
        disabled: disabled,
        value: value,
        placeHolder: <Trans t={t} i18nKey={'modele:TypeActivitePlaceHolder'} />,
        helperText: Rest.helperText,
        errorText: Rest.errorText,
        onChange: handleChange,
    }


    if (multi) {
        return (
            < SelectMulti choix={listeTypeActivite}  {...communAtt} />
        )
    } else {
        return (
            < SelectMono mesChoix={listeTypeActivite}  {...communAtt} />
        )
    }

}
