import * as constants from '../constants'
import { Globale } from './globale'
import { isNoInternetError } from '../services/erreur'
import { Pays } from './pays'
import { TypeClinique } from './typeClinique'
import { Site } from './site'
import { TypeAudit } from './typeAudit'
import { SiteCandidatStatut } from './siteCandidatStatut'
import { Portefeuille } from './portefeuille'
import { TodoStatut } from './todoStatut'
import { TodoPriorite } from './todoPriorite'
import { User } from './user'
import { Attribut } from './attribut'
import { Region } from './region'

/**
 * Sert à la gestion des referentiels dans l'application
 * @class
 * @extends Globale
 */
export class Referentiel extends Globale {
    constructor() {
        super()

        if (Referentiel.existeDeja) {
            return Referentiel.instance
        }

        Referentiel.instance = this
        Referentiel.existeDeja = true

        this.content = JSON.parse(window.localStorage.getItem(constants.dateMajReferentiel))
        if (this.content === null) {
            this.content = []
        }
        return this
    }

    /**
    * Met à jour la date de dernier chargement d'un referentiel dans le localStorage
    * @param {String} ref - nom de l'objet à mettre à jour
    */
    maj(ref) {
        const index = this.content.findIndex(search => search.ref === ref)
        const date = new Date()
        if (index >= 0) {
            this.content[index].dateModif = date
            this.content[index].version = process.env.REACT_APP_VERSION
        } else {
            this.content.push({ 'ref': ref, 'dateModif': date, 'version': process.env.REACT_APP_VERSION })
        }
        this.toLocalStorage()
    }

    /**
     * 
     * @param {String} name 
     */
    getDateModif(name) {
        const index = this.content.findIndex(search => search.ref === name)
        if (index >= 0) {
            return this.content[index].dateModif
        } else {
            return new Date()
        }
    }

    /**
     * Sauvegarde la liste des élèments à sauvegardé dans le localStorage
     */
    toLocalStorage() {
        if (this.content.length > 0) {
            window.localStorage.setItem(constants.dateMajReferentiel, JSON.stringify(this.content))
        } else {
            window.localStorage.removeItem(constants.dateMajReferentiel)
        }
    }

    /**
     * refresh un ou plusieurs référentiel
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    refresh(ListeReferentielATraiter = [], force = false) {
        return new Promise((resolve, reject) => {
            // on controle de quand date le dernier rafraichissement des referentiels
            //Si trop vieux on "force" à vrai
            //Si vieille version du referentiel, on force a vrai
            let indexMax = -1
            if (Array.isArray(this.content)) {
                indexMax = this.content.findIndex(search => search.ref && search.ref === 'globale')
            } else {
                this.content = []
            }
            let dateRefreshMaximal = indexMax >= 0 ? new Date(this.content[indexMax].dateModif) : new Date('01-01-1900')
            let version = indexMax >= 0 ? this.content[indexMax].version : ""
            dateRefreshMaximal.setHours(dateRefreshMaximal.getHours() + parseInt(process.env.REACT_APP_TEMPS_REFRESH_REFERENTIEL))
            if (dateRefreshMaximal < new Date() || version !== process.env.REACT_APP_VERSION) {
                force = true
            }
            const url = `/api/referentiel/modif`
            Referentiel.getFetch(url)
                .then((res) => {
                    if (res && res.data) {
                        const Referentiels = res.data
                        let obj_a_traiter = []
                        Referentiels.forEach(unReferentiel => {
                            //Pour chaque referentiel retourné par le WS :
                            //  on compare avec le tableau ListeReferentielATraiter, si pas précisé, on travail avec tous les réferentiels
                            //  on récupère l'objet lié
                            //  on controle si _refToObj a renvoyer une classe et si cette classe n'est pas déjà dans les objets à traiter
                            if (ListeReferentielATraiter.length === 0 || ListeReferentielATraiter.includes(unReferentiel.ref)) {
                                const Obj = this._refToObj(unReferentiel.ref)
                                if (typeof Obj === 'function' && !obj_a_traiter.includes(Obj)) {
                                    const index = this.content.findIndex(search => search.ref && typeof Obj.getRefName === 'function' && search.ref === Obj.getRefName())
                                    let dateLocal = index >= 0 ? new Date(this.content[index].dateModif) : undefined
                                    let dateReferentiel = unReferentiel.dateModif ? new Date(unReferentiel.dateModif) : new Date()
                                    // Si pas de date de dernier chargement 
                                    // OU date de dernier chargement plus ancienne que la date de dernière MAJ du referenciel
                                    // OU Si on force l'execution
                                    // OU Si le localStorage est vide
                                    // OU si pas de version ou version obsolete
                                    if (unReferentiel.version === undefined || unReferentiel.version !== process.env.REACT_APP_VERSION || dateLocal === undefined || dateLocal < dateReferentiel || force || (typeof Obj.byLocalStorage === 'function' && Obj.byLocalStorage().length === 0)) {
                                        if (typeof Obj.getListe === 'function') {
                                            obj_a_traiter.push(Obj.getListe({}, true))
                                        }
                                    }
                                }

                            }
                        })

                        //execution des getliste
                        Promise.allSettled(obj_a_traiter)
                            .then(() => {
                                this.maj('globale')
                                resolve()
                            })
                            .catch(reject)


                    } else {
                        reject()
                    }
                })
                .catch(
                    (err) => {
                        if (isNoInternetError(err)) {
                            resolve()
                        } else {
                            reject(err)
                        }
                    }
                )

        })
    }

    _refToObj(ref) {

        switch (ref) {
            case 'CONS_PAYS': return Pays
            case 'CONS_REF_TYPE_CLINIQUE': return TypeClinique
            case 'CONS_REF_SITE': return Site
            case 'CONS_REF_ADT_FAMILLE' || 'CONS_REF_ADT_CONTROLE' || 'CONS_REF_ADT_SECTEUR'
                || 'CONS_REF_ADT_TYPE' || 'CONS_ADT_CONTROLE_ATTRIBUT': return TypeAudit
            case 'CONS_REF_CNDT_STATUT': return SiteCandidatStatut
            case 'REF_PORTEFEUILLE': return Portefeuille
            case 'CONS_REF_TODO_STATUT': return TodoStatut
            case 'CONS_REF_TODO_PRIORITE': return TodoPriorite
            case 'CONS_PROFIL': return User
            case 'CONS_REF_ADT_ATT' || 'CONS_REF_ADT_VAL_ATT' || 'CONS_ADT_CONTROLE_ATTRIBUT': return Attribut
            case 'CONS_REF_REGION': return Region


            default:
                return {}

        }

    }
}