import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Pays } from './pays'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class Region extends Globale {

    constructor(unRegion = {}) {
        super()

        this.ID_REGION = unRegion.ID_REGION
        this.LIBELLE = unRegion.LIBELLE
        this.CODE = unRegion.CODE
        this.PAYS = Pays.getByID(unRegion.PAYS ? unRegion.PAYS.ID_PAYS : 0)
        this.REGION_PARENT = { 'ID_REGION': (unRegion.REGION_PARENT) ? unRegion.REGION_PARENT.ID_REGION : 0 }
    }

    static getRefName() {
        return 'Region'
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {Region}
     * @static
     */
    static byLocalStorage() {
        let ListeRegion = JSON.parse(window.localStorage.getItem(constants.localRegionListe))
        if (ListeRegion === null || !Array.isArray(ListeRegion)) { ListeRegion = [] }
        return ListeRegion
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListeRegion) {
        if (ListeRegion) {
            window.localStorage.setItem(constants.localRegionListe, JSON.stringify(ListeRegion))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unRegion = this.byLocalStorage().find(uneRegion => uneRegion.ID_REGION === id)

        if (unRegion) {
            return new Region(unRegion)
        } else {
            return new Region()
        }
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG

            const url = `/api/referentiel/region`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(Region.getRefName())

                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(this.byLocalStorage())
            }
        })
    }

}


