import React, { useState } from 'react';
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
    },
    formControle: {
        display: "block",
        margin: "5px",
    },
    input: {
        width: "100%",
    },
    helper: {
        fontStyle: 'italic',
        width: "100%",
    }
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
TextFiedMulti.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    libelle: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.any,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    rows: PropTypes.number.isRequired,
}

TextFiedMulti.defaultProps = {
    // value: '',
    disabled: false,
    helperText: '',
    errorText: '',
    maxLength: -1,
    required: false,
    rows: 4,
    libelle: '',
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TextFiedMulti({ value, name, libelle, disabled, onChange, helperText, errorText, maxLength, required, rows }) {
    const classes = useStyles();
    const [erreur, setErreur] = useState(errorText)
    const [t] = useTranslation(['commun'])
    const [localValue, setLocalValue] = useState(value)


    // function appelée à chaque saisie
    const handleChange = (event) => {
        let uneErreur = ''
        let valeurSaisie = event.target.value

        setLocalValue(valeurSaisie)

        if (required && valeurSaisie === '') {
            uneErreur = t('commun:helper')
        }
        // exécute le controle demandé par la props
        if (onChange) {
            uneErreur = onChange(event, uneErreur)
        }

        if (erreur !== uneErreur) {
            // initialise l'erreur selon le retour de la props
            setErreur(uneErreur)
        }

    };

    // retourne vrai si une erreur existe sinon faux
    function estEnErreur() {
        return (erreur && erreur !== '') ? true : false
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <FormGroup key={'ctrl_id_' + name} className={classes.root}>
            <FormControl error={estEnErreur()} disabled={disabled} required={required} className={classes.formControle}>
                <InputLabel htmlFor={'input_name_' + name + '_label'}>
                    {libelle}
                </InputLabel>
                <Input
                    className={classes.input}
                    name={name}
                    value={localValue !== undefined && localValue !== null ? localValue : ''}
                    rows={rows}
                    onChange={handleChange}
                    id={'input_name_' + name + '_label'}
                    aria-describedby={'input_name_' + name + '_helper'}
                    disabled={disabled}
                    inputProps={{
                        maxLength: maxLength
                    }}
                    multiline
                />
                {
                    (estEnErreur() || helperText !== '') &&
                    < FormHelperText
                        id={'input_name_' + name + '_helper'}
                        className={classes.helper}
                    >

                        {
                            estEnErreur() ?
                                erreur
                                :
                                helperText
                        }

                    </FormHelperText>
                }
            </FormControl >
        </FormGroup>
    );
}
