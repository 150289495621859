//Stored in local storage
export const localAttributs = 'localAtt'
export const localSiteListe = 'localSiteListe'
export const localPaysListe = 'localPaysListe'
export const refType = 'refType'
export const infUser = 'infUser'
export const dateMajReferentiel = 'dmr'
export const LocalLastMaj = 'LastMajLoc'
export const LocalAdtToUPD = 'reponsesAuditToSave'
export const LocalSiteToUPD = 'siteToUpd'
export const LocalSiteCndtToUPD = 'siteCndtToUPD'
export const localAudit = 'localAdt_'
export const localSite = 'localSite_'
export const localDoc = 'localDoc_'
export const localTodo = 'localTodo_'
export const localSiteCandidat = 'localCDT_'
export const localSiteCandidatStatutListe = 'localSiteCndtStatutListe'
export const localTypeCliniqueListe = 'localTypeCliniqueListe'
export const localTypeActiviteListe = 'localTypeActiviteListe'
export const localPortefeuilleListe = 'localPortefeuilleListe'
export const localTodoStatutListe = 'localTodoStatutListe'
export const localTodoPrioriteListe = 'localTodoPrioriteListe'
export const localUserListe = 'localUserListe'
export const localRegionListe = "localRegionListe"

// stored in session storage
export const listeAudits = 'listeAudits'
export const listeSiteCandidat = 'listeSiteCandidat'
export const listeTodo = 'listeTodo'


//En Attente de save
export const saveEnAttenteADT = 's_adt_'


//detail a afficher
export const affichageUser = {
    localAtt: { group: 'Referentiel', libelle: 'referentiel attribut' },
    localSiteListe: { group: 'Referentiel', libelle: 'referentiel site' },
    localPaysListe: { group: 'Referentiel', libelle: 'referentiel Pays' },
    refType: { group: 'Referentiel', libelle: 'referentiel Type' },
    localSiteCndtStatutListe: { group: 'Referentiel', libelle: 'referentiel statuts des sites candidats' },
    localTypeCliniqueListe: { group: 'Referentiel', libelle: 'referentiel des types cliniques' },
    localTypeActiviteListe: { group: 'Referentiel', libelle: 'referentiel des types d\'activité' },
    localPortefeuilleListe: { group: 'Referentiel', libelle: 'referentiel portefeuille' },
    localTodoStatutListe: { group: 'Referentiel', libelle: 'referentiel des statuts des Todos' },
    localTodoPrioriteListe: { group: 'Referentiel', libelle: 'referentiel des priorités des todos' },
    localUserListe: { group: 'Referentiel', libelle: 'referentiel des users' },
    localRegionListe: { group: 'Referentiel', libelle: 'referentiel des régions' },

    dmr: { group: 'Referentiel', libelle: 'Date de maj des referentiels' },


    localAdt_: { group: 'Audit', libelle: 'Audit local' },
    localSite_: { group: 'Site', libelle: 'Site local' },
    localDoc_: { group: 'Document', libelle: 'Document local' },
    localTodo_: { group: 'Todo', libelle: 'Todo local' },
    localCDT_: { group: 'Site Candidat', libelle: 'Site Candidat local' },


    infUser: { group: 'User', libelle: 'Info de l\'utilisateur connecté' },
    LastMajLoc: { group: 'User', libelle: 'Derniers éléments modifiés localement' },


    siteToUpd: { group: 'Update', libelle: 'Site à save' },
    reponsesAuditToSave: { group: 'Update', libelle: 'Audit à save' },
    siteCndtToUPD: { group: 'Update', libelle: 'Site Candidat à save' },

    s_adt_: { group: 'Audit', libelle: 'Audit local en attente de save' },

}