import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormFamille from './auditFormFamille'


import { TitreContext } from '../../context/TitreContext'
import { MenuContext } from '../../context/MenuContext'
import { useAfficheErreur } from '../../services/erreur'
import Chargement from '../outils/utilitaires/chargement'
import { AuditContext } from '../../context/AuditContext'



const useStyles = makeStyles((theme) => ({
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
}));

/**
 * Composant FormAudit
 * Ce composant est la racine du formulaire d'audit 
 */
export default function FormAudit() {

    const { monAudit, typesAudits, refAttribut, setRefreshDoc } = useContext(AuditContext)
    const classes = useStyles();


    // gestion de l'affichage du chargement
    const [load, setLoad] = useState(true)

    const [, setTitre] = useContext(TitreContext)
    const [sousMenu, setSousMenu] = useContext(MenuContext)
    const [t] = useTranslation(['commun', 'audit'])



    // gestion ouverture/fermeture des panels 
    const [panelFamille, setPanelFamille] = useState('')
    const [panelSecteur, setPanelSecteur] = useState([])
    const [panelControle, setPanelControle] = useState([])

    const [afficheErreur] = useAfficheErreur()

    const isLoading = useCallback(() => {
        let loading = false
        if (monAudit === undefined || monAudit === null) { loading = true }
        if (typesAudits === undefined || typesAudits.length <= 0) { loading = true }
        if (refAttribut === undefined || refAttribut.length <= 0) { loading = true }
        return loading
    }, [monAudit, refAttribut, typesAudits])



    useEffect(() => {
        if (isLoading() !== load) {
            setLoad(isLoading())
        }
    }, [isLoading, load])


    /**
     * Permet de mettre à jour le titre de la page
     */
    useEffect(() => {
        if (load) {
            setTitre(t('menu:audit'))
        } else {
            setTitre(t('menu:audit'), monAudit.SITE_NOM)
        }

    }, [load, monAudit, setTitre, t])


    /**
     * initialisation du sous-menu du TOP
     */
    useEffect(() => {
        let unSousMenu = undefined

        if (load === false) {
            let unTypeAudit = typesAudits.find(unType => unType.ID_TYPE === monAudit.ID_TYPE)
            if (unTypeAudit) {
                unSousMenu = {}
                unSousMenu.unTypeAudit = unTypeAudit
                unSousMenu.majPageDepuisSousMenu = changePanel
                unSousMenu.name = 'AUDIT'
            }
            setSousMenu(unSousMenu)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load, monAudit, typesAudits])

    /**
     * timer pour sauvegarder régulièrement la saisie
     */
    useEffect(() => {
        if (load === false) {
            const idInterval = setInterval(() => {
                if (monAudit && monAudit.isModified()) {
                    monAudit.save()
                        .then(() => {
                            setRefreshDoc(old => !old)
                        })
                        .catch(afficheErreur)
                }
            }, process.env.REACT_APP_TIME_SAVE_AUDIT)

            return () => clearInterval(idInterval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load, monAudit])


    /**
     * Charge le panel depuis le menu et le panel
     * @param {String} newPanelFamille - panel famille
     * @param {String} newPanelSecteur - panel secteur
     * @param {String} newPanelControle - panel controle
     * @param {Boolean} depuisFlagMenu 
     */
    const changePanel = (typePanel, newPanel, depuisFlagMenu = false) => {
        let panelSousMenu = newPanel
        if (typePanel === 'famille') {
            setPanelFamille(newPanel)
            setPanelSecteur([])
            setPanelControle([])

        }
        else if (typePanel === 'secteur') {
            const index = panelSecteur.indexOf(newPanel)
            if (index === -1) {
                setPanelSecteur([...panelSecteur, newPanel])
            } else {
                let panelUpd = [...panelSecteur]
                panelUpd.splice(index, 1)
                setPanelSecteur(panelUpd)
                panelSousMenu = ''
            }
        } else if (typePanel === 'controle') {
            const index = panelControle.indexOf(newPanel)
            if (index === -1) {
                setPanelControle([...panelControle, newPanel])
            } else {
                let panelUpd = [...panelControle]
                panelUpd.splice(index, 1)
                setPanelControle(panelUpd)
                panelSousMenu = ''
            }
        }

        // si sous-menu existe..
        if (sousMenu) {
            // ..et si appel depuis la page..
            if (depuisFlagMenu === false) {
                // ..on appelle la fonction pour mettre à jour le composant sousMenu
                if (sousMenu.majSousMenuDepuisPage) {
                    sousMenu.majSousMenuDepuisPage(typePanel, panelSousMenu)
                }
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDERS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        load ?
            <Chargement active={load} />
            :

            <div className={classes.content}>

                {
                    typesAudits.find(unType => unType.ID_TYPE === monAudit.ID_TYPE).FAMILLES.map(famille =>

                        <FormFamille
                            famille={famille}
                            key={'fam_' + famille.ID_FAMILLE}
                            onChange={changePanel}
                            panelFamilleDemande={panelFamille}
                            panelSecteurDemande={panelSecteur}
                            panelControleDemande={panelControle}
                            id={'fam_' + famille.ID_FAMILLE}
                        />
                    )
                }

            </div>

    )
}