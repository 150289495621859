// import React from 'react'

// const UserContext = React.createContext()

// export const UserProvider = UserContext.Provider
// export const UserConsumer = UserContext.Consumer

// export default UserContext


import React, { useState, createContext } from "react";
import * as constants from '../constants'

const UserContext = createContext();

const UserProvider = (props) => {
    let User = JSON.parse(window.localStorage.getItem(constants.infUser))
    const [droits, setDroits] = useState((User !== null && User.droits) ? User.droits : undefined)
    const [langue, setLangue] = useState((User !== undefined && User !== null && User.lang !== undefined && User.lang !== null) ? User.lang : 5)
    const [isConnect, setConnect] = useState((User !== undefined && User !== null) ? true : false)
    const [afterChangeLangue, setAfterChangeLangue] = useState([])

    const ContextUser = {}
    ContextUser.User = User
    ContextUser.droits = droits
    ContextUser.setDroits = setDroits
    ContextUser.langue = langue
    ContextUser.changeLangue = setLangue
    ContextUser.setConnect = setConnect
    ContextUser.isConnect = isConnect
    ContextUser.afterChangeLangue = afterChangeLangue
    ContextUser.setAfterChangeLangue = setAfterChangeLangue

    return (
        <UserContext.Provider value={ContextUser}>
            {props.children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };