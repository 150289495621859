import { Globale } from './globale'

/**
 * Sert à la gestion des budgets dans l'application
 * @class
 * @extends Globale
 */
export class Forecast extends Globale {
    constructor(unForecast) {
        super()
        this.ID_LIGNE_FORECAST = unForecast.ID_LIGNE_FORECAST
        this.IDCHAN_TYPE = unForecast.IDCHAN_TYPE
        this.FOR_LIG_MONTANT = unForecast.FOR_LIG_MONTANT
        this.FOR_COMMENTAIRE = unForecast.FOR_COMMENTAIRE
        this.MODIF = unForecast.MODIF
    }


    /**
     * Fonction permettant de mettre à jour la valeur des elements forecast
     * * @param {String} libChamp - libelle de la methode à changer
     * @param {Integer/String} uneValeur - Valeur du forecast
     */
    setValeur(libChamp, uneValeur) {
        this[libChamp] = uneValeur
        this.MODIF = true

    }
    /**
     * Fonction de comparaison entre le budget passé en parametre et le budget courant
     * @param {Budget} unAtt - Représente le budget du localStorage à comparer avec le budget courant
     * @param {Boolean} identique - Variable de controle de similitude de la réponse du WS et du localStorage pour une réponse / un controle 
     */
    compareTo(unForecast, identique) {
        if (unForecast.MODIF === true) {
            identique = false
        } else {
            unForecast.MODIF = false
            identique = (unForecast.IDCHAN_TYPE !== this.IDCHAN_TYPE) ? false : identique
            identique = (unForecast.FOR_LIG_MONTANT !== this.FOR_LIG_MONTANT) ? false : identique
            identique = (unForecast.FOR_COMMENTAIRE !== this.FOR_COMMENTAIRE) ? false : identique
        }
    }
}