import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

////////////////////////////////////////////////////////////////////////
//                      import REALESTATE
import './App.css'
import Top from './menu/top'
import Routing from './menu/Router'
import Connexion from './connexion/connexion'
import * as constants from '../constants'
import { UserContext } from '../context/UserContext'
import { construitErreur, useAfficheErreur } from '../services/erreur'
import Chargement from './outils/utilitaires/chargement'
import { TitreProvider } from "../context/TitreContext";
import { MenuProvider } from '../context/MenuContext';
////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: 50,
        marginTop: 50,
        width: `100%`,
        marginLeft: '-' + theme.drawerWidth,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },

    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    content2: {
        flexGrow: 1,
        width: `100%`,
    },
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function App() {
    const classes = useStyles();
    const from = JSON.parse(window.sessionStorage.getItem('redirect'))
    const [menuOpen, setMenuOpen] = useState(true)
    const [afficheErreur] = useAfficheErreur()
    const UserCtx = useContext(UserContext)


    useEffect(() => {
        const ac = new AbortController()
        if (UserCtx.isConnect === true && UserCtx.droits === undefined) {
            fetch(`/api/auth/droits`, ac)
                .then(construitErreur)
                .then((res) => {
                    if (res && res.droits) {
                        UserCtx.setDroits(res.droits)
                        window.localStorage.setItem(constants.infUser, JSON.stringify(UserCtx.User))
                    }
                })
                .catch(afficheErreur)
        }
        return () => ac.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserCtx.isConnect, UserCtx.droits])







    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    function ConnexionRedirection() {
        if (from) {
            window.sessionStorage.removeItem('redirect')
            return (
                <div className={classes.root}>
                    <Top setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
                    <main className={clsx(classes.content, { [classes.contentShift]: menuOpen, })}>
                        <Routing />
                        <Redirect to={from} />
                    </main>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <Top setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
                <main className={clsx(classes.content, { [classes.contentShift]: menuOpen, })}>
                    <Routing />
                </main >
            </div >
        )
    }

    if (!UserCtx.isConnect) {
        return (
            <div className={classes.root}>
                <main className={classes.content2}>
                    <Connexion setConnect={UserCtx.setConnect} />
                </main >
            </div>
        )
    } else {
        if (UserCtx.droits === undefined) {
            return <Chargement active={true} />
        }
        return (
            <MenuProvider>
                <TitreProvider>

                    {ConnexionRedirection()}

                </TitreProvider>
            </MenuProvider>



        )
    }
}
