import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import { goToAnchor } from 'react-scrollable-anchor'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import { MenuContext } from '../../context/MenuContext'
import jump from 'jump.js'
import easing from 'jump.js/src/easing'
import ImgFamille from '../../images/audit/sousMenu/famille.png'
import ImgSecteur from '../../images/audit/sousMenu/secteur.png'
import ImgControle from '../../images/audit/sousMenu/controle.png'
import SelectMono from '../outils/generique/SelectMono';




const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
    },
    input: {
        width: "100%",
        margin: "0px",
    },
    label: {
        marginBottom: "0px",
        marginTop: "10px",
    },
    helper: {
        fontStyle: 'italic',
        width: "100%",
    },
    sousMenu: {
        marginTop: "10px",
        marginLeft: "16px",
        marginRight: "16px"
    },
    icone: {
        width: '25px',
        height: '25px'
    },
    gridSelect: {
        paddingLeft: '35px'
    }
}));

/**
 * Composant SousMenuAudit
 * Ce composant affiche le menu de navigation des audits
 */
export default function SousMenuAudit() {

    const classes = useStyles()
    const [idFamille, setIdFamille] = useState(0)
    const [idSecteur, setIdSecteur] = useState(0)
    const [idControle, setIdControle] = useState(0)
    const [sousMenu, setSousMenu] = useContext(MenuContext)
    const [t] = useTranslation(['audit'])


    const majSousMenuDepuisPage = useCallback((typePanel, newPanel) => {
        if (typePanel === 'famille') {
            setIdFamille(newPanel.toString())
            setIdSecteur(0)
            setIdControle(0)
        } else if (typePanel === 'secteur') {
            setIdSecteur(newPanel)
            setIdControle(0)
        } else if (typePanel === 'controle') {
            const splitPanel = newPanel.split('_')
            if (splitPanel.length > 1) {
                setIdSecteur(splitPanel[0] + '_' + splitPanel[1])
            }
            setIdControle(newPanel)
        }

    }, [])

    /**
     * initialise la fonction de maj du sous-menu depuis la page
     */
    useEffect(() => {
        // initialise la fonction de maj du sous-menu depuis la page
        let unSousMenu = sousMenu
        unSousMenu.majSousMenuDepuisPage = majSousMenuDepuisPage
        setSousMenu(unSousMenu)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [majSousMenuDepuisPage])

    /**
     * fonction appelée à chaque saisie  de famille
     * @param {Event} event - Event de changement des familles
     */
    const handleChange = (event, type) => {
        if (typeof sousMenu.majPageDepuisSousMenu === 'function') {
            sousMenu.majPageDepuisSousMenu(type, event.target.value, true)
        }

        majSousMenuDepuisPage(type, event.target.value)

        setTimeout(() => {
            jump('#id-' + event.target.value.toString(), {
                duration: 100,
                offset: -130,
                easing: easing,
                a11y: false
            })
        }, [500])
    }


    /**
     * render de la liste des controles
     * @param {Object} secteur - Représente le secteur selectionné
     */
    function RenderControles({ secteur }) {

        if (secteur && secteur.CONTROLES && secteur.CONTROLES.length > 0) {
            return (
                <React.Fragment >
                    <Grid component="span" container alignItems="center">

                        <Grid component="span" item xs={1} sm={1} md={1} lg={1}>
                            <img src={ImgControle} alt={t('audit:controle')} className={classes.icone} />
                        </Grid>

                        <Grid component="span" item xs={11} sm={11} md={11} lg={11} className={classes.gridSelect}>
                            <SelectMono
                                libelle={t('audit:controle')}
                                key={'key_SousMenuControlePanel'}
                                name={'SousMenuControlePanel'}
                                onChange={(e) => { handleChange(e, 'controle') }}
                                value={(idControle && idControle !== null) ? idControle : 0}
                                placeHolder={''}
                                mesChoix={
                                    secteur.CONTROLES ?
                                        secteur.CONTROLES.map((controle) => ({
                                            key: 'sect_' + secteur.ID_SECTEUR + '_ctrl_' + controle.ID_CONTROLE,
                                            value: 'sect_' + secteur.ID_SECTEUR + '_ctrl_' + controle.ID_CONTROLE,
                                            label: controle.CTRL_LIB,
                                        }))
                                        :
                                        []
                                }
                            />
                        </Grid>
                    </Grid>
                </React.Fragment >
            )
        } else {
            return <></>
        }
    }

    /**
     * Render de la liste des familles
     * @param {Object} famille - représente la famille selectionnée
     */
    function RenderSecteurs({ famille }) {
        let secteurSelect = {}

        if (famille && famille.SECTEURS && famille.SECTEURS.length > 0) {

            // si un secteur est sélectionné, on le mémorise
            if (idSecteur && idSecteur !== null && idSecteur !== '') {
                secteurSelect = famille.SECTEURS.find(element => 'sect_' + element.ID_SECTEUR === idSecteur)
            }

            return (
                <React.Fragment >
                    <Grid component="span" container alignItems="center">

                        <Grid component="span" item xs={1} sm={1} md={1} lg={1}>
                            <img src={ImgSecteur} alt={t('audit:secteur')} className={classes.icone} />
                        </Grid>

                        <Grid component="span" item xs={11} sm={11} md={11} lg={11} className={classes.gridSelect}>
                            <SelectMono
                                libelle={t('audit:secteur')}
                                key={'key_SousMenuSecteurPanel'}
                                name={'SousMenuSecteurPanel'}
                                onChange={(e) => { handleChange(e, 'secteur') }}
                                value={(idSecteur && idSecteur !== null) ? idSecteur : 0}
                                placeHolder={''}
                                mesChoix={
                                    famille.SECTEURS ?
                                        famille.SECTEURS.map((secteur) => ({
                                            key: 'sect_' + secteur.ID_SECTEUR,
                                            value: 'sect_' + secteur.ID_SECTEUR,
                                            label: secteur.SECT_LIB,
                                        }))
                                        :
                                        []
                                }
                            />
                        </Grid>
                    </Grid>

                    <RenderControles secteur={secteurSelect} />
                </React.Fragment >
            )
        } else {
            return <></>
        }
    }


    /**
     * Render de la liste des familles d'un audit
     */
    function RenderFamilles() {

        let familleSelect = {}

        if (sousMenu && sousMenu.unTypeAudit && sousMenu.unTypeAudit.FAMILLES && sousMenu.unTypeAudit.FAMILLES.length > 0) {
            // si une famille est sélectionnée, on la mémorise
            if (idFamille !== undefined && idFamille !== 0) {
                familleSelect = sousMenu.unTypeAudit.FAMILLES.find(element => 'fam_' + element.ID_FAMILLE === idFamille)
            }
            return (
                <React.Fragment >
                    <Grid component="span" container alignItems="center">

                        <Grid component="span" item xs={1} sm={1} md={1} lg={1} >
                            <img src={ImgFamille} alt={t('audit:famille')} className={classes.icone} />
                        </Grid>

                        <Grid component="span" item xs={11} sm={11} md={11} lg={11} className={classes.gridSelect}>
                            <SelectMono
                                libelle={t('audit:famille')}
                                key={'key_SousMenuFamillePanel'}
                                name={'SousMenuFamillePanel'}
                                onChange={(e) => { handleChange(e, 'famille') }}
                                value={(idFamille !== undefined) ? idFamille : 0}
                                placeHolder={''}
                                mesChoix={
                                    sousMenu.unTypeAudit.FAMILLES ?
                                        sousMenu.unTypeAudit.FAMILLES.map((famille) => ({
                                            key: 'fam_' + famille.ID_FAMILLE,
                                            value: 'fam_' + famille.ID_FAMILLE,
                                            label: famille.FAM_LIB,
                                        }))
                                        :
                                        []
                                }
                            />
                        </Grid>
                    </Grid>
                    <RenderSecteurs famille={familleSelect} />
                </React.Fragment >
            )
        } else {
            return <></>
        }
    }

    return (
        <React.Fragment>
            <Divider />
            <List className={classes.sousMenu}>
                <RenderFamilles />
            </List>
        </React.Fragment >
    )
}