import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class Portefeuille extends Globale {

    constructor(unPortefeuille = {}) {
        super()

        this.IDPORTEFEUILLE = unPortefeuille.IDPORTEFEUILLE
        this.LIBELLE = unPortefeuille.LIBELLE
        this.IDTYPEPORTEFEUILLE = unPortefeuille.IDTYPEPORTEFEUILLE

    }

    static getRefName() {
        return 'Portefeuille'
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {SiteCandidat}
     * @static
     */
    static byLocalStorage() {
        let ListePortefeuille = JSON.parse(window.localStorage.getItem(constants.localPortefeuilleListe))
        if (ListePortefeuille === null || !Array.isArray(ListePortefeuille)) { ListePortefeuille = [] }
        return ListePortefeuille
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListePortefeuille) {
        if (ListePortefeuille) {
            window.localStorage.setItem(constants.localPortefeuilleListe, JSON.stringify(ListePortefeuille))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unPortefeuille = this.byLocalStorage().find(unPort => unPort.IDPORTEFEUILLE === id)

        if (unPortefeuille) {
            return new Portefeuille(unPortefeuille)
        } else {
            return new Portefeuille()
        }
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            let params = {}
            params = param
            const url = `/api/referentiel/portefeuille`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(Portefeuille.getRefName())

                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(this.byLocalStorage())
            }
        })
    }

}


