import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { UserContext } from '../../context/UserContext'
import { TitreContext } from '../../context/TitreContext'
import CardLastAudit from './CardLastAudit'
import CardLastSite from './CardLastSite'
import CardSaveEnAttente from './CardSaveEnAttente'




const useStyles = makeStyles((theme) => ({
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    divider: {
        ...theme.divider,
        marginBottom: theme.spacing(1),
    },
}));

/**
 * Composant Accueil
 * Affiche la page d'accueil de l'utilisateur
 */
export default function Accueil() {
    const UserCtx = useContext(UserContext)
    const [, setTitre] = useContext(TitreContext)
    const classes = useStyles();
    const [t] = useTranslation(['accueil', 'menu'])

    /** change le titre de la page */
    useEffect(() => {
        setTitre(t('menu:accueil'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>

            <div className={classes.content}>
                <>
                    <Typography className={classes.libelle} color="textPrimary" variant="h3" gutterBottom>{t('accueil:Bonjour')} {UserCtx.User.cpt}</Typography>

                    <CardLastAudit />
                    <CardLastSite />

                    <Divider className={classes.divider} />

                    <CardSaveEnAttente />

                    <Divider className={classes.divider} />
                </>

            </div >
        </>
    )
}

