import * as constants from '../constants'
import { Globale } from './globale'


/**
 * Sert à la gestion du localStorage pilotant la sauvegarde des sites candidats dans l'application quand elle est offline
 * @class
 * @extends Globale
 */
export class SiteUpdate extends Globale {
    constructor() {
        super()
        this.content = JSON.parse(window.localStorage.getItem(constants.LocalSiteToUPD))
        if (this.content === null) {
            this.content = []
        }
    }


    /**
     * Ajout un site à la liste des sites à sauvegarder
     * @param {int} idSite - idSite du site à sauvegarder
     */
    add(idSite) {
        if (-1 === this.content.findIndex(search => parseInt(search.idSite) === parseInt(idSite))) {
            this.content.push({ idSite: idSite })
        }
        this.toLocalStorage()
    }

    /**
     * Supprime un site candidat de la liste des sites candidats à sauvegarder
     * @param {int} idSite - idSite du site à supprimer
     */
    del(idSite) {

        const index = this.content.findIndex(search => parseInt(search.idSite) === parseInt(idSite))
        if (index >= 0) {
            this.content.splice(index, 1)
            this.toLocalStorage()
        }
        window.localStorage.removeItem(constants.localSite + idSite)
    }

    /**
     * Sauvegarde la liste des élèments à sauvegardé dans le localStorage
     */
    toLocalStorage() {
        if (this.content.length > 0) {
            window.localStorage.setItem(constants.LocalSiteToUPD, JSON.stringify(this.content))
        } else {
            window.localStorage.removeItem(constants.LocalSiteToUPD)
        }
    }
}