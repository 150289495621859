import { Globale } from './globale'
import { RepAttribut } from './repAttribut'
import { Budget } from './budget'
import { DocumentADT } from './documentADT'
import { TodoADT } from './todoADT'
import * as constants from '../constants'
import { Forecast } from './forecast'


/**
 * Sert à la gestion des Réponses dans l'application
 * @class
 * @extends Globale
 */
export class Reponse extends Globale {
    constructor(uneReponse) {
        super()

        this.COMMENTAIRE_MODIF = uneReponse.COMMENTAIRE_MODIF
        this.ID_CONTROLE = uneReponse.ID_CONTROLE
        this.ID_LIGNE = uneReponse.ID_LIGNE
        this.LIG_COMMENTAIRE = uneReponse.LIG_COMMENTAIRE


        this.ATTRIBUTS = []
        if (uneReponse.ATTRIBUTS && uneReponse.ATTRIBUTS.length > 0) {
            uneReponse.ATTRIBUTS.forEach(unAttribut => {
                let att = new RepAttribut(unAttribut)
                this.ATTRIBUTS.push(att)
            });
        }
        this.BUDGETS = []
        if (uneReponse.BUDGETS && uneReponse.BUDGETS.length > 0) {
            uneReponse.BUDGETS.forEach(unBudget => {
                let bud = new Budget(unBudget)
                this.BUDGETS.push(bud)
            });
        }
        this.DOCUMENTS = []
        if (uneReponse.DOCUMENTS && uneReponse.DOCUMENTS.length > 0) {
            uneReponse.DOCUMENTS.forEach(unDocument => {
                let doc = new DocumentADT(unDocument)
                doc.DOC_CONTENU = undefined
                this.DOCUMENTS.push(doc)
            });
        }
        this.TODOS = []
        if (uneReponse.TODOS && uneReponse.TODOS.length > 0) {
            uneReponse.TODOS.forEach(uneTodo => {
                let todo = new TodoADT(uneTodo)
                this.TODOS.push(todo)
            })
        }
        this.FORECAST = {}
        if (uneReponse.FORECAST && Object.keys(uneReponse.FORECAST).length > 0) {
            this.FORECAST = new Forecast(uneReponse.FORECAST)
        }

        this.DATE_SAVE_LOCAL = null
        this.IS_LOCAL_DATA = false

    }

    /**
     * stock la répoons en attente de sauvegarde dans le local storage
     */
    byLocalStorage(idAudit) {
        let saveEnCours = JSON.parse(window.localStorage.getItem(constants.saveEnAttenteADT + idAudit))
        if (saveEnCours === undefined || saveEnCours === null || saveEnCours[this.ID_LIGNE] === undefined) {
            return null
        }
        return new Reponse(saveEnCours[this.ID_LIGNE])
    }


    /**
     * stock la répoons en attente de sauvegarde dans le local storage
     */
    toLocalStorage(idAudit) {
        let saveEnCours = JSON.parse(window.localStorage.getItem(constants.saveEnAttenteADT + idAudit))
        if (saveEnCours === undefined || saveEnCours === null) {
            saveEnCours = {}
        }
        this.DATE_SAVE_LOCAL = new Date()
        saveEnCours[this.ID_LIGNE] = this
        window.localStorage.setItem(constants.saveEnAttenteADT + idAudit, JSON.stringify(saveEnCours))
    }

    /**
      * delete la réponse en attente de sauvegarde dans le local storage
      */
    delLocalStorage(idAudit) {
        let saveEnCours = JSON.parse(window.localStorage.getItem(constants.saveEnAttenteADT + idAudit))
        if (saveEnCours !== undefined && saveEnCours !== null && saveEnCours[this.ID_LIGNE] !== undefined) {
            delete saveEnCours[this.ID_LIGNE]
            if (saveEnCours.length > 0) {
                window.localStorage.setItem(constants.saveEnAttenteADT + idAudit, JSON.stringify(saveEnCours))
            } else {
                window.localStorage.removeItem(constants.saveEnAttenteADT + idAudit)
            }
        }
    }

    /**
     * Permet de récuperer un attribut via son ID
     * @param {int} id - représentant l'ID_LIGNE_ATT
     * @returns {RepAttribut}
     */
    getAttribut(id) {
        return this.ATTRIBUTS.find(search => search.ID_LIGNE_ATT === id)
    }
    /**
     * Permet de mettre à jour un attribut
     * @param {RepAttribut} unAttribut - représente l'objet Attribut a mettre à jour
     */
    setAttribut(unAttribut) {
        const index = this.ATTRIBUTS.findIndex(search => search.ID_LIGNE_ATT === unAttribut.ID_LIGNE_ATT)
        if (index >= 0) {
            this.ATTRIBUTS[index] = unAttribut
        }
    }


    /**
     * Permet de récuperer un attribut via son ID
     * @param {int} id - représentant l'ID_ATTRIBUT
     * @returns {RepAttribut}
     */
    getAttributByIDAttribut(id) {
        return this.ATTRIBUTS.find(search => search.ID_ATTRIBUT === id)
    }

    /**
     * Permet de récuperer un budget via son ID
     * @param {int} id -représente l'objet Attribut a mettre à jour
     * @returns {Budget}
     */
    getBudget(id) {
        return this.BUDGETS.find(search => search.ID_LIGNE_BUDGET === id)
    }

    /**
     * Permet de mettre à jour un budget
     * @param {Budget} unBudget - représentant l'ID_LIGNE_BUDGET
     */
    setBudget(unBudget) {
        const index = this.BUDGETS.findIndex(search => search.ID_LIGNE_BUDGET === unBudget.ID_LIGNE_BUDGET)
        if (index >= 0) {
            this.BUDGETS[index] = unBudget
        }
    }

    /**
     * Permet de récuperer un document via son ID
     * @param {int} id - représentant l'ID_DOCUMENT
     * @returns {DocumentADT} 
     */
    getTodo(id) {
        return this.TODOS.find(search => search.ID_TODO === id)
    }

    /**
     * Permet de récuperer un document via son ID
     * @param {int} id - représentant l'ID_DOCUMENT
     * @returns {DocumentADT} 
     */
    getDocument(id) {
        return this.DOCUMENTS.find(search => search.ID_DOCUMENT === id)
    }

    /**
     * Permet de récuperer tous les nouveaux document
     * @returns {[DocumentADT]} - tableau d'objet documents
     */
    getNewDocument() {
        return this.DOCUMENTS.filter(search => search.ID_DOCUMENT < 0)
    }

    /**
     * Fonction permettant de mettre à jour le commentaire
     * @param {Integer/String} uneValeur - Valeur du commentaire
     */
    setValeurCommentaire(uneValeur) {
        this.LIG_COMMENTAIRE = uneValeur
        this.COMMENTAIRE_MODIF = true

    }

    /**
     * Fonction de comparaison entre la réponse passé en parametre et la réponse courante
     * @param {Reponse} unAtt - Représente la réponse du localStorage à comparer avec l'attribut courant
     * @param {Boolean} identique - Variable de controle de similitude de la réponse du WS et du localStorage pour une réponse / un controle 
     */
    compareTo(uneRep, identique) {
        //Controle si modification faite depuis le lancement de la procédure de sauvegarde
        identique = (this.COMMENTAIRE_MODIF === true) ? false : identique
        identique = (this.LIG_COMMENTAIRE !== uneRep.LIG_COMMENTAIRE) ? false : identique

        this.ATTRIBUTS.forEach(unAttribut => {
            const att = uneRep.getAttribut(unAttribut.ID_LIGNE_ATT)
            unAttribut.compareTo(att, identique)
        });

        this.BUDGETS.forEach(unBudget => {
            const bud = uneRep.getBudget(unBudget.ID_LIGNE_BUDGET)
            unBudget.compareTo(bud, identique)
        });

        this.DOCUMENTS.forEach(unDocument => {
            if (unDocument.ID_DOCUMENT > 0) {
                const doc = uneRep.getDocument(unDocument.ID_DOCUMENT)
                unDocument.compareTo(doc, identique)
            }
        });

        //Suppression des docs supprimés par le WS dans le localStorage
        const docSuppr = this.DOCUMENTS.filter(unDoc => unDoc.SUPPR_OK === true)
        this.DOCUMENTS = uneRep.DOCUMENTS.filter(unDoc => docSuppr.every((unDocSuppr) => unDocSuppr.ID_DOCUMENT !== unDoc.ID_DOCUMENT))

        this.TODOS.forEach(uneTodo => {
            if (uneTodo.ID_TODO > 0) {
                const todo = uneRep.getTodo(uneTodo.ID_TODO)
                if (todo) {
                    uneTodo.compareTo(todo, identique)
                }
            }
        });

        //Suppression des todos supprimés ou ajouter par le WS dans le localStorage
        const TodoSuppr = this.TODOS.filter(uneTodo => uneTodo.SUPPR_OK === true)
        this.TODOS = uneRep.TODOS.filter(uneTodo => TodoSuppr.every((uneTodosSuppr) => uneTodosSuppr.ID_TODO !== uneTodo.ID_TODO))

    }
}
