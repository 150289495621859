import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useTranslation } from 'react-i18next'
import * as constants from '../../constants'
import MFA from './MFA'
import Login from './login'
import { idToCodeLang } from '../../services/langue'
import { Referentiel } from '../../classes/referentiel'


/**
 * Composant Connexion
 * Ce composant est la racine de la connexion
 * @param {{setConnect : function}} props 
 */
export default function Connexion({ setConnect }) {
    const location = useLocation()
    const { i18n } = useTranslation()
    

    /**
     * redirige l'utilisateur une fois connecté
     */
    useEffect(() => {
        if (location.pathname === "/accueil") {

            const paramsUrl = qs.parse(location.search)
            if (paramsUrl && paramsUrl.Cpt && paramsUrl.LangueUser !== undefined) {
                const UserInfo = { 'cpt': paramsUrl.Cpt, 'lang': paramsUrl.LangueUser }
                window.localStorage.setItem(constants.infUser, JSON.stringify(UserInfo))
                if (paramsUrl.LangueUser) {
                    i18n.changeLanguage(idToCodeLang(paramsUrl.LangueUser))
                }
                const MesRef = new Referentiel()
                MesRef.refresh()
                setConnect(true)
            }
        }
    })


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Router>
            <Switch>
                <Route exact path='/login'>
                    <Login />
                </Route>
                <Route path='/MFA/:key'>
                    <MFA setConnect={setConnect} />
                </Route>
                <Route path="*">
                    <Redirect to={{ pathname: '/login', state: { from: location } }} />
                </Route>
            </Switch>
        </Router>
    )
}
