import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function FilterIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50" >
            <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M30 454 c0 -18 23 -51 80 -112 l80 -87 0 -90 0 -90 49 -27 c27 -16
54 -28 60 -28 7 0 11 39 11 118 l0 117 80 87 c57 61 80 94 80 112 l0 26 -220
0 -220 0 0 -26z"/>
            </g>
        </SvgIcon>
    );
}

