import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des types d'activité dans l'application
 * @class
 * @extends Globale
 */
export class TypeActivite extends Globale {

    constructor(unTypeActivite = {}) {
        super()

        this.IDCHAN_TYPE = unTypeActivite.IDCHAN_TYPE
        this.LIBELLE = unTypeActivite.LIBELLE
        this.CODE = unTypeActivite.CODE
        this.ORDRE = unTypeActivite.ORDRE
    }

    static getRefName() {
        return 'TypeActivite'
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {SiteCandidat}
     * @static
     */
    static byLocalStorage() {
        let ListeTypeActivite = JSON.parse(window.localStorage.getItem(constants.localTypeActiviteListe))
        if (ListeTypeActivite === null || !Array.isArray(ListeTypeActivite)) { ListeTypeActivite = [] }
        return ListeTypeActivite
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListeTypeActivite) {
        if (ListeTypeActivite) {
            window.localStorage.setItem(constants.localTypeActiviteListe, JSON.stringify(ListeTypeActivite))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unTypeActivite = this.byLocalStorage().find(unType => parseInt(unType.IDCHAN_TYPE) === parseInt(id))

        if (unTypeActivite) {
            return new TypeActivite(unTypeActivite)
        } else {
            return new TypeActivite()
        }
    }


    /**
     * Récupère la liste des types d'activite
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG

            const url = `/api/referentiel/typeActivite`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(TypeActivite.getRefName())


                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            }
            resolve(this.byLocalStorage())
        })
    }
}