import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class Attribut extends Globale {

    constructor(unAttribut = {}) {
        super()
        this.ID_ATTRIBUT = unAttribut.ID_ATTRIBUT
        this.ATT_LIB = unAttribut.ATT_LIB
        this.ATT_CODE = unAttribut.ATT_CODE
        this.ATT_TYPE_H = unAttribut.ATT_TYPE_H
        this.ATT_TYPE_V = unAttribut.ATT_TYPE_V
        this.VALEURS = unAttribut.VALEURS || []
    }

    static getRefName() {
        return 'Attribut'
    }

    static getLastDateUpd() {
        const ref = new Referentiel()
        return ref.getDateModif(Attribut.getRefName())
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {SiteCandidat}
     * @static
     */
    static byLocalStorage() {
        let ListeAttribut = JSON.parse(window.localStorage.getItem(constants.localAttributs))
        if (ListeAttribut === null) { ListeAttribut = [] }
        return ListeAttribut
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListeAttribut) {
        if (ListeAttribut) {
            window.localStorage.setItem(constants.localAttributs, JSON.stringify(ListeAttribut))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unAttribut = this.byLocalStorage().find(unAttribut => unAttribut.ID_ATTRIBUT === id)

        if (unAttribut) {
            return new Attribut(unAttribut)
        } else {
            return null
        }
    }

    hasValeur() {
        return this.VALEURS.length > 0
    }

    isInterrupteur(){
        return this.ATT_CODE.startsWith('OUINON') ? true : false
    }

    isEsayNumberEntry(){
        return (this.ATT_CODE.startsWith('NOMBRE') || this.ATT_CODE.startsWith('DATE')) ? true : false
    }


    getInterrupteurValue(OuiOuNon){
        if (OuiOuNon){
            let valeurOui = this.VALEURS.find(search => search.VAL_ATT_CODE === 'OUI')
            return valeurOui.ID_VALEUR_ATT
        }else{
            let valeurNon = this.VALEURS.find(search => search.VAL_ATT_CODE === 'NON')
            return valeurNon.ID_VALEUR_ATT
        }
    }

    getValueByLibelle(libelle){
        let attributValeur = this.VALEURS.find(search => search.VAL_ATT_LIB.toString() === libelle.toString())
        if(attributValeur !== undefined){
            return attributValeur.ID_VALEUR_ATT
        }
        return null
    }

    static getStyleEtat(codeEtat) {
        let style = ''
        switch (true) {
            case codeEtat.startsWith('ACCEP'):
                style = 'success'
                break;

            case codeEtat.startsWith('MOYEN'):
                style = 'warning'
                break;

            case codeEtat.startsWith('INACC'):
                style = 'danger'
                break;

            default:
                style = 'secondary'
                break;
        }
        return style
    }
    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG

            const url = `/api/referentiel/attribut`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(Attribut.getRefName())
                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(this.byLocalStorage())
            }
        })
    }

}


