import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.negatif,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        paddingLeft: theme.spacing(1),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    itemTexte: {
        marginLeft: '5px',
        fontSize: '1rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans - serif',
        fontWeight: '400',
        lineHeight: '2.25',
        letterSpacing: '0.00938em',
        // lineHeight: '40px',
    }
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
TitreBox.propTypes = {
    titre: PropTypes.string.isRequired,
    icone: PropTypes.object
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TitreBox({ titre, icone }) {
    const classes = useStyles();

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    function renderMenuIcone() {
        const IconeTag = icone

        if (IconeTag) {
            return (
                < Grid item >
                    <IconeTag />
                </Grid >
            )
        }
    }

    return (
        <Grid container className={classes.container} >
            {renderMenuIcone()}
            <Grid item className={classes.itemTexte} >
                <Typography variant="h6" className={classes.itemTexte}>{titre}</Typography>
            </Grid>
        </Grid>
    )
}
