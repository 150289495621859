import React from 'react'
import { Detector } from "react-detect-offline";
import OnlineIcon from '@material-ui/icons/TapAndPlay'
import IconButton from '@material-ui/core/IconButton'


/**
 * Composant OnLine
 * Ce composant permet d'afficher un icone offline lorsque l'utilisateur est offline
 */
export default function OnLine() {

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <React.Fragment>
            <IconButton edge="start" color="inherit" aria-label="langue">
                {process.env.REACT_APP_TEST_OFFLINE !== '0' &&
                    <Detector
                        polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}
                        render={({ online }) => (
                            <OnlineIcon color={online ? "primary" : "secondary"} />
                        )}
                    />
                }
            </IconButton>
        </React.Fragment>
    )
}