
import React, { useState, useEffect, useContext } from 'react';
import SelectMono from '../generique/SelectMono'
import SelectMulti from '../generique/SelectMulti'
import { TypeAudit } from '../../../classes/typeAudit';
import { useAfficheErreur } from '../../../services/erreur';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/UserContext'

export default function ModTypeAudit({ multi = false, onChange, value, disabled = false, ...Rest }) {

    const [afficheErreur] = useAfficheErreur()
    const [listeType, setListeType] = useState([])
    const [t] = useTranslation(['modele'])
    const [refreshLangue, setRefreshLangue] = useState(true)
    const UserCtx = useContext(UserContext)

    useEffect(() => {
        const identifiant = Rest.name || 'SEL_Type'
        UserCtx.setAfterChangeLangue(old => [...old, [identifiant,
            () => {
                setRefreshLangue(true)
            }]])
        return () => {
            let old = [...UserCtx.afterChangeLangue]
            const index = old.findIndex(([id, uneFonction]) => id === identifiant)
            if (index >= 0) { old.splice(index, 1) }
            UserCtx.setAfterChangeLangue(old)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (refreshLangue) {
            refresh()
            setRefreshLangue(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshLangue])
    function refresh() {
        TypeAudit.getListe()
            .then((liste) => {
                setListeType(
                    Array.from(liste, (item) => ({ 'value': item.ID_TYPE, 'label': item.TYPE_LIB, 'code': item.TYPE_CODE, 'key': item.ID_TYPE }))
                )
            })
            .catch(afficheErreur)
    }

    function handleChange(e, ...autre) {
        if (multi === false) {
            e.target.value = TypeAudit.getByID(e.target.value)
        }
        return onChange(e, ...autre)
    }

    const communAtt = {
        libelle: Rest.libelle ? Rest.libelle : t('modele:TypeLibelle'),
        name: Rest.name ? Rest.name : 'SEL_Type',
        disabled: disabled,
        value: value,
        placeHolder: <Trans t={t} i18nKey={'modele:TypePlaceHolder'} />,
        helperText: Rest.helperText,
        errorText: Rest.errorText,
        onChange: handleChange,
    }


    if (multi) {
        return (
            < SelectMulti choix={listeType}  {...communAtt} />
        )
    } else {
        return (
            < SelectMono mesChoix={listeType}  {...communAtt} />
        )
    }

}
