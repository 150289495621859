import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types'
import clsx from 'clsx';

import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@material-ui/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: "block",
    },
    formControle: {
        display: "block",
        margin: "5px",
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      FONCTIONS
///////////////////////////////////////////////////////////////////////////////////////////////////////////
function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////

SelectMulti.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.any,
    ]),
    name: PropTypes.string.isRequired,
    libelle: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    choix: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            libelle: PropTypes.string,
            code: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        })),
}

NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    selectProps: PropTypes.object.isRequired,
}

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
}

Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool,
    removeProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    ValueContainer,
}

SelectMulti.defaultProps = {
    // value: '',
    disabled: false,
    helperText: '',
    errorText: '',
    required: false,
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// aide d'utilisation :
// value: valeur à affecter au champ (valeur par défaut)
// name: nom du champ,
// libelle: libellé du champ,
// onChange: méthode appelée à chaque saisie. cette fonction doit retourner le message d'erreur ('' si ok)
// helperText: message d'aide à la saisie
////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SelectMulti({ value, name, libelle, disabled, onChange, helperText, errorText, placeHolder, required, choix }) {
    const classes = useStyles();
    const theme = useTheme();
    const [erreur, setErreur] = useState(errorText)
    const [t] = useTranslation(['commun'])
    // const [multi, setMulti] = React.useState((props.value && props.value.length > 0) ? props.value : '');

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }

    const handleChangeMulti = (event) => {
        let valeurSaisie = event
        let uneErreur = ''

        if (required && (valeurSaisie === null || valeurSaisie.length === 0)) {
            uneErreur = t('commun:helper')
        }
        // exécute le controle demandé par la props
        if (onChange) {
            uneErreur = onChange(event, uneErreur)
        }

        if (erreur !== uneErreur) {
            // initialise l'erreur selon le retour de la props
            setErreur(uneErreur)
        }

        // setMulti(event);
    }

    // retourne vrai si une erreur existe sinon faux
    function estEnErreur() {
        return (erreur && erreur !== '') ? true : false
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <FormGroup key={name} className={classes.root}>
            <FormControl error={estEnErreur()} className={classes.formControle} >
                {/* < div className={classes.divider} /> */}

                <Select
                    classes={classes}
                    styles={selectStyles}
                    inputId={"react-select-multiple" + name}
                    TextFieldProps={{
                        label: libelle + (required ? ' *' : ''),
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple' + name,
                            shrink: true,
                        },
                    }}
                    placeholder={placeHolder}
                    options={choix}
                    components={components}
                    value={(value && value.length > 0) ? value : ''}
                    key={"react-select-multiple" + name}
                    onChange={handleChangeMulti}
                    isDisabled={disabled}
                    isMulti
                />

                {
                    (estEnErreur() || helperText !== '') &&
                    < FormHelperText
                        id={'input_name_' + name + '_helper'}
                        className={classes.helper}
                    >

                        {
                            estEnErreur() ?
                                erreur
                                :
                                helperText
                        }

                    </FormHelperText>
                }
            </FormControl>
        </FormGroup >
    );
}