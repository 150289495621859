import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class TypeAudit extends Globale {

    constructor(unTypeAudit = {}) {
        super()

        this.ID_TYPE = unTypeAudit.ID_TYPE
        this.TYPE_LIB = unTypeAudit.TYPE_LIB
        this.TYPE_CODE = unTypeAudit.TYPE_CODE
        this.FAMILLES = unTypeAudit.FAMILLES || []


    }

    static getRefName() {
        return 'TypeAudit'
    }

    static getLastDateUpd() {
        const ref = new Referentiel()
        return ref.getDateModif(TypeAudit.getRefName())
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {SiteCandidat}
     * @static
     */
    static byLocalStorage() {
        let ListeTypeAudit = JSON.parse(window.localStorage.getItem(constants.refType))
        if (ListeTypeAudit === null) { ListeTypeAudit = [] }
        return ListeTypeAudit
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListeTypeAudit) {
        if (ListeTypeAudit) {
            window.localStorage.setItem(constants.refType, JSON.stringify(ListeTypeAudit))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unTypeAudit = this.byLocalStorage().find(unType => unType.ID_TYPE === id)

        if (unTypeAudit) {
            return new TypeAudit(unTypeAudit)
        } else {
            return new TypeAudit()
        }
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG

            const url = `/api/referentiel/typeAudit`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {
                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(TypeAudit.getRefName())


                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(this.byLocalStorage())
            }
        })
    }

}


