import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
import './index.css';
import App from './component/App';
import NoCookies from './component/noCookies'
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'
import { UserProvider } from './context/UserContext';
import db from './services/db'

const CookieEnabled = navigator.cookieEnabled


window.console = (function (consoleNavigateur) {

    return {
        log: function (...Arg) {
            consoleNavigateur.log(...Arg)
            if (process.env.NODE_ENV !== 'development') {
                console.logs.push({ date: new Date(), url: window.location.href, args: Arg })
                db.table('logs').put({ type: 'log', date: new Date(), url: window.location.href, args: Arg })
            }
        },
        logs: [],
        info: function (...Arg) {
            consoleNavigateur.info(...Arg)
            if (process.env.NODE_ENV !== 'development') {
                console.infos.push({ date: new Date(), url: window.location.href, args: Arg })
                db.table('logs').put({ type: 'info', date: new Date(), url: window.location.href, args: Arg })
            }
        },
        infos: [],
        warn: function (...Arg) {
            consoleNavigateur.warn(...Arg)
            if (process.env.NODE_ENV !== 'development') {
                console.warns.push({ date: new Date(), url: window.location.href, args: Arg })
                db.table('logs').put({ type: 'warn', date: new Date(), url: window.location.href, args: Arg })
            }
        },
        warns: [],
        error: function (...Arg) {
            consoleNavigateur.error(...Arg)
            if (process.env.NODE_ENV !== 'development') {
                console.errors.push({ date: new Date(), url: window.location.href, args: Arg })
                db.table('logs').put({ type: 'error', date: new Date(), url: window.location.href, args: Arg })
            }
        },
        errors: [],
    };
}(window.console));



ReactDOM.render(
    <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                maxSnack={3}
            >
                {!CookieEnabled ?
                    <NoCookies />
                    :
                    <BrowserRouter>
                        <UserProvider>
                            <App />
                        </UserProvider>
                    </BrowserRouter>
                }
            </ SnackbarProvider>
        </I18nextProvider >
    </ThemeProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (CookieEnabled) {
    serviceWorker.register({
        onUpdate: registration => {
            const waitingServiceWorker = registration.waiting

            if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener("statechange", event => {
                    if (event.target.state === "activated") {
                        window.location.reload()
                    }
                });
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
            }
        },
    });
}
