import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function FilterIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50" >
            <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M0 474 c0 -18 21 -48 68 -100 l67 -74 85 0 85 0 68 74 c46 52 67 82
67 100 l0 26 -220 0 -220 0 0 -26z"/>
                <path d="M160 188 l0 -93 49 -27 c27 -16 54 -28 60 -28 7 0 11 39 11 120 l0
120 -60 0 -60 0 0 -92z"/>
                <path fill="#FF0000" d="M320 168 c0 -7 14 -27 32 -45 l32 -33 -32 -33 c-56 -58 -33 -81 25
-25 l33 32 33 -32 c58 -56 81 -33 25 25 l-32 33 32 33 c56 58 33 81 -25 25
l-33 -32 -33 32 c-34 33 -57 41 -57 20z"/>
            </g>
        </SvgIcon>
    );
}

