
import React, { useState, useEffect, useContext, useCallback } from 'react';
import SelectMono from '../generique/SelectMono'
import SelectMulti from '../generique/SelectMulti'
import { Region } from '../../../classes/region';
import { useAfficheErreur } from '../../../services/erreur';
import { Trans, useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/UserContext'

export default function ModRegion({ multi = false, onChange, value, disabled = false, dependance = 0, forceDependance = false, ...Rest }) {

    const [afficheErreur] = useAfficheErreur()
    const [listeRegion, setListeRegion] = useState([])
    const [t] = useTranslation(['modele'])
    const [refreshLangue, setRefreshLangue] = useState(true)
    const UserCtx = useContext(UserContext)

    useEffect(() => {
        const identifiant = Rest.name || 'SEL_Region'
        UserCtx.setAfterChangeLangue(old => [...old, [identifiant,
            () => {
                setRefreshLangue(true)
            }]])
        return () => {
            let old = [...UserCtx.afterChangeLangue]
            const index = old.findIndex(([id, uneFonction]) => id === identifiant)
            if (index >= 0) { old.splice(index, 1) }
            UserCtx.setAfterChangeLangue(old)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const refresh = useCallback(() => {
        Region.getListe()
            .then((liste) => {
                if (Array.isArray(dependance) && dependance.length > 0) {
                    let ListeCumul = []
                    dependance.forEach((uneDependance) => {
                        ListeCumul = ListeCumul.concat(liste.filter(uneRegion => uneRegion.PAYS && uneRegion.PAYS.ID_PAYS === uneDependance.value))
                    })
                    liste = ListeCumul
                } else if (dependance > 0) {
                    liste = liste.filter(uneRegion => uneRegion.PAYS && uneRegion.PAYS.ID_PAYS === dependance)
                }
                liste.sort((a, b) => {
                    const lib1 = a.CODE || ""
                    const lib2 = b.CODE || ""
                    return lib1.localeCompare(lib2)
                })
                liste = liste.map(uneRegion => {
                    uneRegion.LIBELLE = '—'.repeat(uneRegion.CODE.length - 3) + ' ' + uneRegion.LIBELLE
                    return uneRegion
                })

                setListeRegion(
                    Array.from(liste, (item) => ({ 'value': item.ID_REGION, 'label': item.LIBELLE, 'code': item.CODE, 'key': item.ID_REGION }))
                )
            })
            .catch(afficheErreur)
    }, [afficheErreur, dependance])

    useEffect(() => {
        if (refreshLangue) {
            refresh()
            setRefreshLangue(false)
        }

    }, [refresh, refreshLangue])
    useEffect(() => {
        refresh()
    }, [refresh])



    function handleChange(e, ...autre) {
        if (multi === false) {
            e.target.value = Region.getByID(e.target.value)
        } else {
            //gestion de l'ajout / suppression des fils / père
            if (e && e.length > 0) {
                let ListeAjout = []
                let ListeSupprime = []
                e.forEach((maRegionSelect) => {
                    if (maRegionSelect.code.length <= 4 && (value === undefined || value.length === 0 || -1 === value.findIndex(x => x.value === maRegionSelect.value))) {
                        ListeAjout = ListeAjout.concat(listeRegion.filter(uneRegion => (uneRegion.code.startsWith(maRegionSelect.code) && -1 === ListeAjout.findIndex(x => x.code === uneRegion.code))))
                    }
                })
                if (value && value.length > 0) {
                    value.filter(uneRegion => -1 === e.findIndex(x => x.code === uneRegion.code))
                        .forEach((uneRegion) => {
                            console.log(uneRegion)
                            if (uneRegion.code.length >= 4) {
                                ListeSupprime = ListeSupprime.concat(e.filter(x => uneRegion.code.substring(0, 4) === x.code || uneRegion.code.substring(0, 3) === x.code))
                            }
                        })
                }
                e = e.concat(ListeAjout.filter(uneRegion => -1 === e.findIndex(x => x.code === uneRegion.code)))
                e = e.filter(uneRegion => -1 === ListeSupprime.findIndex(x => x.code === uneRegion.code))
            }
        }


        return onChange(e, ...autre)
    }

    const communAtt = {
        libelle: Rest.libelle ? Rest.libelle : t('modele:RegionLibelle'),
        name: Rest.name ? Rest.name : 'SEL_Region',
        disabled: (disabled || (forceDependance === true && dependance === 0)) ? true : false,
        value: value,
        placeHolder: <Trans t={t} i18nKey='modele:RegionPlaceHolder' />,
        helperText: Rest.helperText,
        errorText: Rest.errorText,
        onChange: handleChange,
    }


    if (multi) {
        return (
            < SelectMulti choix={listeRegion}  {...communAtt} />
        )
    } else {
        return (
            < SelectMono mesChoix={listeRegion}  {...communAtt} />
        )
    }

}
