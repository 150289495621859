import qs from 'querystring'
import { construitErreur } from "../services/erreur";

/**
 * Classe Globale dont hérite toutes les classes.
 * @virtual
 * @class
 */
export class Globale {
    constructor() {
        if (this.constructor === Globale) {
            throw new TypeError('Abstract class "Globale" cannot be instantiated directly');
        }
    }

    /**
     * modele de get
     * @static
     * @param {String} url - URL de l'api visé
     * @param {Object} params - Parametre à ajouter dans l'url après le ?
     */
    static getFetch(url, params = '') {
        let newUrl = url
        if (typeof params === 'object') {
            if (newUrl.indexOf('?') < 0) {
                newUrl += '?'
            }
            newUrl += qs.stringify(params)
        }
        return new Promise((resolve, reject) => {
            const ac = new AbortController()
            fetch(newUrl, ac)
                .then(construitErreur)
                .then(resolve)
                .catch(reject)
        })
    }

    /**
     * modèle de post
     * static
     * @param {String} url - URL de l'api visé
     * @param {Object} body - Contenu de la requete
     */
    static postFetch(url, body) {
        return new Promise((resolve, reject) => {
            fetch(url,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(construitErreur)
                .then(resolve)
                .catch(reject)
        })
    }

    /**
     * modèle de put
     * @static
     * @param {String} url - URL de l'api visé
     * @param {Object} body - Contenu de la requete
     */
    static putFetch(url, body) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(construitErreur)
                .then(resolve)
                .catch(reject)
        })
    }

    /**
     * modèle de delete
     * @static
     * @param {String} url - URL de l'api visé
     */
    static delFetch(url) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'DELETE',
            })
                .then(construitErreur)
                .then(resolve)
                .catch(reject)
        })
    }

}
