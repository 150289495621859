import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import TextFiedMulti from '../outils/generique/TextFiedMulti'
import { AuditContext } from '../../context/AuditContext'
import Box from '../outils/utilitaires/box';
// import useTraceUpdate from '../outils/utilitaires/useTraceUpdate'
import { Audit } from '../../classes/audit';




/**
 * Composant FormCommentaire
 * Ce composant affiche un bloc commentaire
 * @param   {{
 *              reponseControle : Reponse,
 *              setControle : Function
 *          }} props 
 */
export default function FormCommentaire({ idControle, idSecteur, idFamille, refreshPastille }) {
    const { onChangeControle, readOnly, monAudit } = useContext(AuditContext)
    const [t] = useTranslation(['audit'])
    const maReponseControle = Audit.byLocalStorage(monAudit.ID_ENTETE).getReponseByControle(idControle)
    // useTraceUpdate({ idControle, idSecteur, idFamille, refreshPastille }, FormCommentaire.name)



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (

        <Box titre={t('audit:comment')} icone={ChatBubbleIcon} key={'ctrl_' + idControle + 'comment'} >
            < TextFiedMulti
                key={'key_' + idControle + '_comment'}
                name={idFamille + '_' + idSecteur + '_' + idControle + '_comment'}
                value={maReponseControle.LIG_COMMENTAIRE}
                onChange={(e) => { onChangeControle('commentaire', e); refreshPastille() }}
                disabled={readOnly}
            />
        </Box>

    )
}

