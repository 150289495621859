import * as constants from '../constants'
import { Todo } from './todo'



/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Todo
 */
export class TodoADT extends Todo {

    constructor(uneTodo = {}) {
        super(uneTodo)
        this.ID_SITE = uneTodo.ID_SITE
        this.ID_ENTETE = uneTodo.ID_ENTETE
        this.ID_LIGNE = uneTodo.ID_LIGNE
        this.ID_TYPE = uneTodo.ID_TYPE
        this.ID_CONTROLE = uneTodo.ID_CONTROLE

        this.SITE_ENTITE = uneTodo.SITE_ENTITE
        this.SITE_NOM = uneTodo.SITE_NOM
        this.SITE_VILLE = uneTodo.SITE_VILLE
        this.SITE_PAYS = uneTodo.SITE_PAYS
        this.ID_FAMILLE = uneTodo.ID_FAMILLE
        this.ID_SECTEUR = uneTodo.ID_SECTEUR

        this.type = "ADT"
    }

    /**
     * Retourne un objet Todo ou false si inexistant dans le local storage
     * @param {int} idTodo - ID du site candidat à chercher
     * @returns {Todo}
     * @static
     */
    static byLocalStorage(idTodo) {
        return super.byLocalStorage(idTodo, this.type)
    }


    /**
     * Récupère un site candidat depuis le WS
     * @returns {Promise} - promesse 
     */
    get() {
        return super.get(this.type)
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(params) {
        return super.getListe(params, this.type)
    }

    /**
     * Sauvegarde la liste des sites candidats en local
     * @static
     * @param {Array} ListeTodo - Liste des sites candidats
     */
    static listeToSessionStorage(ListeTodo) {
        window.sessionStorage.setItem(constants.listeTodo, JSON.stringify(ListeTodo))
    }

    /**
     * Récupère la liste des sites candidats depuis la session
     * @static
     * @returns {Array} - Liste des sites candidats
     */
    static listeBySessionStorage() {
        let ListeTodo = JSON.parse(window.sessionStorage.getItem(constants.listeTodo))
        if (ListeTodo === null) {
            ListeTodo = []
        }
        return ListeTodo
    }


}


