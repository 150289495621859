import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FilterIcon from '../../images/icons/filter'
import FilterRazIcon from '../../images/icons/filterRaz'

////////////////////////////////////////////////////////////////////////
//                      import REALESTATE
import ModSite from '../outils/modele/Site';
import TextFieldMultiTag from '../outils/generique/TextFielMultiTag';
import ModPays from '../outils/modele/Pays';
import ModTypeActivite from '../outils/modele/TypeActivite';
import ModRegion from '../outils/modele/Region';

///////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    details: {
        alignItems: 'center',
    },
    actions: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    columnAction: {
        flexBasis: '100%',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    button: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    content: {
        width: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Filtre({ setOptions, options, razOptions }) {
    const classes = useStyles();
    const [t] = useTranslation(['site', 'commun'])
    const [filtreOptions, setFiltre] = useState(options)
    const [open, setOpen] = useState((filtreOptions && Object.keys(filtreOptions).length > 0) ? true : false)


    /**
      * Ajoute un pays au filtre
      * @param {Event} event - Event de selection d'un pays
      */
    function handleChange(event, item, value = undefined) {
        let filtre = []
        if (value) {
            filtre = Array.from(value, (valeur, index) => ({ 'value': valeur, 'label': valeur, 'code': '', 'key': index }))
        } else if (event) {
            event.forEach(option => {
                filtre.push(option)
            })
        }
        setFiltre({ ...filtreOptions, [item]: filtre })
    }

    // validation du filtre
    const valideFiltre = () => {
        setOptions(filtreOptions)
    }

    // réinitialisation du filtre
    const AnnuleFiltre = () => {
        setFiltre(razOptions())
    }



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // RENDERS
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className={classes.content} >
            <Accordion expanded={open} >
                {/* partie ouverture / fermeture  */}
                <AccordionSummary
                    className={classes.noPaddingLeft}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    onClick={() => setOpen(old => !old)}
                >


                    <Button aria-label={t('site:filtre')} className={classes.button} variant="outlined" color="primary" startIcon={<FilterIcon />}>
                        {t('site:filtre')}
                    </Button>


                </AccordionSummary>

                <Divider />

                {/* partie filtre */}
                <AccordionDetails className={classes.details}>
                    <div className={classes.columnAction}>
                        <ModSite multi={true} onChange={(e) => handleChange(e, 'idSite')} value={filtreOptions['idSite']} />
                        <TextFieldMultiTag value={Array.from(filtreOptions['ville'] || [], element => element.value)} disabled={false} name={'SEL_ville'} libelle={t('site:researchCity')} type="text" onChange={(e, value) => handleChange(e, 'ville', value)} helperText={t('site:filtreVille')} />
                        <ModPays multi={true} onChange={(e) => handleChange(e, 'idPays')} value={filtreOptions['idPays']} />
                        <ModRegion multi={true} onChange={(e) => handleChange(e, 'idRegion')} value={filtreOptions['idRegion']} forceDependance={true} dependance={(filtreOptions['idPays'] && filtreOptions['idPays'].length > 0) ? filtreOptions['idPays'] : 0} />
                        <ModTypeActivite multi={true} onChange={(e) => handleChange(e, 'idActivite')} value={filtreOptions['idActivite']} />
                    </div>
                </AccordionDetails>

                <Divider />

                {/* partie action */}
                <AccordionActions className={classes.actions}>
                    <div className={classes.columnAction} >

                        <Button aria-label={t('commun:search')} className={classes.button} variant="outlined" color="primary" startIcon={<FilterIcon />}
                            onClick={valideFiltre}>{t('commun:search')}
                        </Button>

                        <Button aria-label={t('commun:cancel')} variant="outlined" className={classes.button} color="primary" startIcon={<FilterRazIcon />}
                            onClick={AnnuleFiltre}>
                            {t('commun:cancel')}
                        </Button>

                    </div>
                </AccordionActions>
            </Accordion >
        </ div >
    );
}
