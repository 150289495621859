export function estVisible(id_element) {
    let cadreVisible = { xMin: 0, xMax: 0, yMin: 0, yMax: 0 }
    let elementVisible = { xMin: 0, xMax: 0, yMin: 0, yMax: 0 }
    let source = document.getElementById(id_element)
    if (!source) {
        return true
    }
    let sourceParent = source.offsetParent
    let total = { hauteur: false, largeur: false }
    let partiel = { hauteur: false, largeur: false }
    cadreVisible.xMin = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
    cadreVisible.xMax = window.pageXOffset + window.innerWidth || document.documentElement.scrollLeft + document.documentElement.clientWidth || document.body.scrollLeft + document.body.clientWidth;
    cadreVisible.yMin = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    cadreVisible.yMin = cadreVisible.yMin + 56
    cadreVisible.yMax = window.pageYOffset + window.innerHeight || document.documentElement.scrollTop + document.documentElement.clientHeight || document.body.scrollTop + document.body.clientHeight;
    elementVisible.xMin = source.offsetLeft;
    elementVisible.yMin = source.offsetTop;
    while (sourceParent) {
        elementVisible.xMin += sourceParent.offsetLeft;
        elementVisible.yMin += sourceParent.offsetTop;
        sourceParent = sourceParent.offsetParent;
    }
    elementVisible.xMax = elementVisible.xMin + source.offsetWidth;
    elementVisible.yMax = elementVisible.yMin + source.offsetHeight;
    if (cadreVisible.xMin <= elementVisible.xMin && cadreVisible.xMax >= elementVisible.xMax) {
        total.largeur = true;
    }
    else if (!(cadreVisible.xMax < elementVisible.xMin || cadreVisible.xMin > elementVisible.xMax)) {
        partiel.largeur = true;
    }
    if (cadreVisible.yMin <= elementVisible.yMin && cadreVisible.yMax >= elementVisible.yMax) {
        total.hauteur = true;
    }
    else if (!(cadreVisible.yMax < elementVisible.yMin || cadreVisible.yMin > elementVisible.yMax)) {
        partiel.hauteur = true;
    }
    if (total.largeur && total.hauteur) {
        // return (id_element + ' est entièrement visible dans la page !');
        return true
    }
    else if (total.largeur && partiel.hauteur) {
        // return (id_element + ' est entièrement visible en largeur mais tronqué en hauteur');
        return false
    }
    else if (total.hauteur && partiel.largeur) {
        // return (id_element + ' est entièrement visible en hauteur mais tronqué en largeur');
        return true
    }
    else if (partiel.hauteur && partiel.largeur) {
        // return (id_element + ' est tronqué en hauteur et en largeur');
        return false
    }
    else {
        // return (id_element + ' n\'est pas visible dans la page !');
        return false
    }
}
