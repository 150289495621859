import { Attribut } from './attribut'
import { Globale } from './globale'

/**
 * Sert à la gestion des attributs dans l'application
 * @class
 * @extends Globale
 */
export class RepAttribut extends Globale {
    constructor(unAttribut) {
        super()
        this.ID_ATTRIBUT = unAttribut.ID_ATTRIBUT
        this.ID_LIGNE_ATT = unAttribut.ID_LIGNE_ATT
        this.ID_VALEUR_ATT = unAttribut.ID_VALEUR_ATT
        this.LIG_VALEUR = unAttribut.LIG_VALEUR
        this.MODIF = unAttribut.MODIF
    }


    /**
     * Fonction permettant de mettre à jour la valeur de l'attribut
     * @param {Integer/String} uneValeur - Valeur de l'attribut
     */
    setValeur(uneValeur) {
        const unAttribut = Attribut.getByID(this.ID_ATTRIBUT)
        if(unAttribut !== null){
            if (unAttribut.hasValeur()) {
                if(unAttribut.isInterrupteur()){
                    this.ID_VALEUR_ATT = unAttribut.getInterrupteurValue(uneValeur)  
                } else if(unAttribut.isEsayNumberEntry()){ 
                    this.ID_VALEUR_ATT = unAttribut.getValueByLibelle(uneValeur)
                } else {
                    this.ID_VALEUR_ATT = uneValeur
                }
            } else {
                this.LIG_VALEUR = uneValeur
            }
            this.MODIF = true
        }
    }

    /**
     * Fonction de comparaison entre l'attribut passé en parametre et l'attribut courant
     * @param {RepAttribut} unAtt - Représente l'attribut du localStorage à comparer avec l'attribut courant
     * @param {Boolean} identique - Variable de controle de similitude de la réponse du WS et du localStorage pour une réponse / un controle 
     */
    compareTo(unAtt, identique) {
        if (unAtt.MODIF === true) {
            identique = false
        } else {
            unAtt.MODIF = false
            identique = (unAtt.ID_VALEUR_ATT !== this.ID_VALEUR_ATT) ? false : identique
            identique = (unAtt.LIG_VALEUR !== this.LIG_VALEUR) ? false : identique
        }
    }
}