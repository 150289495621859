import React from 'react';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { codeLangToId } from '../../services/langue'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import LogoOrpea from '../../images/logo_orpea.png'


let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.secondary,
        minHeight: '100vh',
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    entete: {
        background: theme.palette.primary.main,
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(6),
        textAlign: 'center',
        height: 'max-content',
        width: "100%",
    },
    contenu: {
        marginBottom: theme.spacing(2),
    },
    logo: {
        width: "75%",
        maxWidth: "400px",
    },
}));


/**
 * Composant Login
 * Ce composant affiche le formulaire de connexion de l'utilsiateur
 */
export default function Login() {
    const classes = useStyles();
    const [t, i18n] = useTranslation(['connexion'])
    let urlEnd = i18n.language ? '/' + codeLangToId(i18n.language) : ''

    const location = useLocation()
    let { from } = location.state || { from: { pathname: "/" } }

    window.sessionStorage.setItem('redirect', JSON.stringify(from))

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <Grid container justify="flex-start" alignItems="stretch" direction="column" className={classes.root}>
                <Grid item className={classes.entete}>
                    <img alt="Logo" src={LogoOrpea} className={classes.logo} />
                </Grid>
                <Grid container justify="center" alignItems="flex-start">
                    <Grid item xs={11} sm={9} md={6} lg={3} className={classes.contenu}  >
                        <Paper className={classes.paper} align="center" >
                            <Typography variant="h5" component="h5" paragraph={true}> {t('connexion:titre')}</Typography >
                            <Link href={process.env.REACT_APP_HOST + '/api/auth/connection' + urlEnd}>
                                <Button aria-label="connexion" variant="outlined" color="primary" >
                                    {t('connexion:connexion')}
                                </Button>
                            </Link>
                        </Paper >
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
