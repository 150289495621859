import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { MajDerniereModif } from '../../services/derniereModif'
import * as constants from '../../constants'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/KeyboardReturn';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined'
import { UserContext } from '../../context/UserContext'
import TitreBox from '../outils/utilitaires/titreBox'


const useStyles = makeStyles((theme) => ({
    card: {
        ...theme.card,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

/**
 * Composant CardLastSite
 * Affiche un raccourci vers le dernier site modifié par l'utilisateur
 */
export default function CardLastSite() {
    const UserCtx = useContext(UserContext)
    const [t] = useTranslation(['accueil', 'menu'])
    const classes = useStyles();
    const [adt, setAdt] = useState()

    /**
     * Charge le dernier site modifié
     */
    useEffect(() => {
        const ac = new AbortController()

        const localData = JSON.parse(window.localStorage.getItem(constants.LocalLastMaj)) || {}
        if (localData && localData.site && localData.site.element) {
            setAdt(localData.site.element)
        }

        let param = {}
        param.userModif = UserCtx.User.cpt
        param.lang = UserCtx.langue
        param.limite = 1
        param.sort = 'date'
        MajDerniereModif('site', param, ac)
            .then(() => {
                const localData = JSON.parse(window.localStorage.getItem(constants.LocalLastMaj)) || {}
                if (localData && localData.site && localData.site.element) {
                    setAdt(localData.site.element)
                }
            })
            .catch(console.error)
        return () => {
            setAdt()
            ac.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
     * Render le raccourci
     */
    function renderCard() {
        if (adt !== undefined) {
            return (
                <Card className={classes.card}>
                    <TitreBox titre={t('accueil:Dernier site modifié')} icone={ApartmentOutlinedIcon} />
                    <CardContent>
                        <Typography variant="h5" component="h2">{adt.titre}</Typography>
                        <Typography color="textSecondary">{adt.sousTitre}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="primary" variant="outlined" endIcon={<BackIcon />} component={NavLink} to={"/site/" + adt.id} >{t('accueil:Accéder')}</Button>
                    </CardActions>
                </Card >
            )
        } else {
            return (
                <Card className={classes.card} >
                    <TitreBox titre={t('accueil:Dernier site modifié')} icone={ApartmentOutlinedIcon} />
                    <CardContent>
                        <Typography color="textSecondary">{t('accueil:Aucun site modifié')}</Typography>
                    </CardContent>
                </Card>
            )
        }
    }

    return renderCard()
}

