import React from 'react';
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
    },
    formControle: {
        display: "block",
        margin: "5px",
    },
    input: {
        width: "100%",
    },
    helper: {
        fontStyle: 'italic',
        width: "100%",
    }
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
TextFieldMultiTag.propTypes = {
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    libelle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.any,
    maxLength: PropTypes.number,
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// aide d'utilisation :
// value: valeur à affecter au champ (valeur par défaut)
// disabled: true : grisé sinon actif
// name: nom du champ,
// libelle: libellé du champ,
// type: type de champ (number, text)
// min: valeur min (si de type number),
// max: valeur max (si de type number),
// onChange: méthode appelée à chaque saisie. cette fonction doit retourner le message d'erreur ('' si ok)
// helperText: message d'aide à la saisie,
// valider: booleen permettant de lancer le controle du champ à la valdiation du formulaire
// as: PropTypes.string,
////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TextFieldMultiTag(props) {

    const classes = useStyles()

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <FormGroup key={'ctrl_id_' + props.name} className={classes.root} >
            <FormControl disabled={props.disabled} className={classes.formControle}>

                <Autocomplete
                    id={'input_name_' + props.name + '_label'}
                    multiple
                    autoSelect
                    options={[]}
                    value={props.value}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    aria-describedby={'input_name_' + props.name + '_helper'}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    renderInput={(params) => (
                        <TextField {...params} maxLength={(props.maxLength && props.maxLength >= 0) ? props.maxLength : -1} className={classes.input} type={props.type} variant="standard" label={props.libelle} placeholder={props.helperText} name={props.name} />
                    )}
                />

            </FormControl >
        </FormGroup>
    );
}
