import React from 'react'
import { useTranslation } from 'react-i18next';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function NoData() {
    const [t] = useTranslation(['commun'])

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className="text-center">{t('commun:NoData')} </div>
    )
}