import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import * as constants from '../../constants'
import { UserContext } from '../../context/UserContext'


/**
 * Composant Deconnexion
 * Ce composant déconnecte l'utilisateur
 */
export default function Deconnexion() {
    const UserCtx = useContext(UserContext)

    useEffect(() => {
        UserCtx.setConnect(false)

        window.localStorage.removeItem(constants.infUser)
        window.sessionStorage.clear()

        fetch('/api/auth/delCookie')
    })


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <Redirect to='/' />
    )
}
