
import { blue, green, grey, orange, purple, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#153d7c',
            //Pour une version girly decommentez la ligne de dessous
            //main: '#FF5099',
        },
        background: {
            drawer: '#F2F2F2',
            secondary: '#F2F2F2',
            paper: '#ffffff',
            expansionPanelControle: '#FFFABC',
            default: '#fafafa',
        },
        text: {
            negatif: '#FFFFFF'
        },
        auditEtat: {
            acceptable: '#4caf50',
            moyen: '#ff9800',
            inacceptable: '#d32f2f',
            inapplicable: '#6C757D',
        },

    },
    overrides: {
        MuiAccordion: {
            root: {
                border: '1px solid rgba(0, 0, 0, .125)',
                boxShadow: 'none',
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                '&$expanded': {
                    margin: 'auto',
                },
            },
            expanded: {},
        },
        MuiAccordionSummary: {
            root: {
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                marginBottom: -1,
                minHeight: 56,
                // maxHeight: 56,
                '&$expanded': {
                    minHeight: 56,
                    // maxHeight: 56,
                },
            },
            content: {
                '&$expanded': {
                    margin: '12px 0',
                },
            },
            expanded: {},
        },
        MuiAccordionDetails: {
            root: {
                padding: 0,
            },
        },
        MuiCardMedia: {
            img: {
                objectFit: 'contain',
            },
        },
        MuiBadge: {
            root: {
                display: 'inline-flex',
                position: 'relative',
                flexShrink: '0',
                verticalAlign: 'middle',
                maxHeight: '1.5em',
            },
            badge: {
                border: `2px solid`,
                padding: '0 4px',
            },
        }
    },
    card: {
        minWidth: 275,
        maxWidth: 345,
        width: "95%",
        float: "left",
    },
    divider: {
        clear: 'both',
    },
    table: {
        size: "small",
        margin: "auto",
        rowImpaire: {
            backgroundColor: "#F5F5F5",
            "&$hover:hover, &$selected, &$selected:hover": {
                backgroundColor: "#EBEBEB",
            },
        },
        rowPaire: {
            backgroundColor: "#FFFFFF",
            "&$hover:hover, &$selected, &$selected:hover": {
                backgroundColor: "#EBEBEB",
            }
        },
        cell: {
            "$selected &": {
                color: "#0056B3",
            },
            "$hover:hover &": {
                color: "#0056B3",
            }
        },
        cellStatut: {
            padding: '0',
            paddingLeft: '2px',
            margin: '0',
            borderLeftWidth: '3px',
            borderLeftStyle: 'solid',
        },
        cellTh: {
            fontWeight: 'bold',
            backgroundColor: "#E2E2E2",
        },
        cellThStatut: {
            width: '30px',
            padding: '0',
            margin: '0',
            backgroundColor: "#E2E2E2",
            borderLeftWidth: '3px',
            borderLeftStyle: 'solid',
            borderLeftColor: "#E2E2E2",
        },
        statutDefault: {
            padding: '0',
            paddingLeft: '2px',
            margin: '0',
            borderLeftWidth: '3px',
            borderLeftStyle: 'solid',
        },
        positionRelative: {
            position: 'relative',
        },
        HeaderFloat: {
            position: 'fixed',
            top: 56,
            "@media (min-width:0px) & (orientation: landscape)": {
                top: 48
            },
            "@media (min-width:600px)": {
                top: 64
            },
            display: 'flex',
            zIndex: '1100',
        },

    },
    drawerWidth: '280px',
})

const themeExtended = createMuiTheme({
    ...theme,
    famillePanel: {
        summary: {
            height: theme.spacing(3),
            color: theme.palette.text.negatif,
            margin: "0",
            backgroundColor: theme.palette.primary.main,
        },
        typography: {
            flexBasis: '100%',
        },
    },
    secteurPanel: {
        summary: {
            backgroundColor: theme.palette.background.secondary,
            margin: "0",
        },
        typography: {
            flexBasis: '100%',
        },
        details: {
            padding: '16px 8px',
            [theme.breakpoints.only('xs')]: {
                paddingLeft: '2px',
                paddingRight: '2px',
            },
        }
    },
    controlePanel: {
        expanded: {
            backgroundColor: theme.palette.background.expansionPanelControle,
        },
        typography: {
            flexBasis: '100%',
            padding: '0px',
        },
        details: {
            padding: '16px 8px',
            [theme.breakpoints.only('xs')]: {
                paddingLeft: '2px',
                paddingRight: '2px',
            },
        }
    },
    logs: {
        button: {
            info: {
                root: {
                    color: theme.palette.getContrastText(blue[500]),
                    backgroundColor: blue[500],
                    '&:hover': {
                        backgroundColor: blue[700],
                    },
                }
            },
            warning: {
                root: {
                    color: theme.palette.getContrastText(orange[500]),
                    backgroundColor: orange[500],
                    '&:hover': {
                        backgroundColor: orange[700],
                    },
                }
            },
            logs: {
                root: {
                    color: theme.palette.getContrastText(green[500]),
                    backgroundColor: green[500],
                    '&:hover': {
                        backgroundColor: green[700],
                    },
                }
            },
            error: {
                root: {
                    color: theme.palette.getContrastText(red[500]),
                    backgroundColor: red[500],
                    '&:hover': {
                        backgroundColor: red[700],
                    },
                }
            },
            full: {
                root: {
                    color: theme.palette.getContrastText(grey[50]),
                    backgroundColor: grey[50],
                    '&:hover': {
                        backgroundColor: grey[200],
                    },
                }
            },
            clear: {
                root: {
                    color: theme.palette.getContrastText(purple[500]),
                    backgroundColor: purple[500],
                    '&:hover': {
                        backgroundColor: purple[700],
                    },
                }
            }
        }
    }
})

export default themeExtended;
