import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FormSecteur from './auditFormSecteur'
import Accordion from '../outils/utilitaires/accordion';
// import useTraceUpdate from '../outils/utilitaires/useTraceUpdate';


const useStyles = makeStyles((theme) => ({
    famillePanelSummary: { ...theme.famillePanel.summary },
    famillePanelTypography: { ...theme.famillePanel.typography },

}));



/**
 * Composant FormFamille
 * Ce composant affiche une famille
 * @param {{ famille: REfFamille, onChange: Function, panelFamilleDemande: String, panelSecteurDemande: String, panelControleDemande: String }} props 
 */
export default function FormFamille({ famille, onChange, panelFamilleDemande, panelSecteurDemande, panelControleDemande }) {
    const classes = useStyles();
    const [panelSecteur, setPanelSecteur] = useState(panelSecteurDemande)

    // useTraceUpdate({ famille, onChange, panelFamilleDemande, panelSecteurDemande, panelControleDemande }, FormFamille.name)



    useEffect(() => {
        if (panelSecteurDemande !== panelSecteur) {
            setPanelSecteur(panelSecteurDemande)
        }
    }, [panelSecteur, panelSecteurDemande])



    /**
     * changement du panel famille
     * @param {string} panel - Panel famille à changer
     */
    const handleChangePanel = (panel, expanded) => {
        if (onChange) {
            onChange('famille', (expanded) ? panel : '')
        }
    }



    return (

        <Accordion
            key={'fam_' + famille.ID_FAMILLE}
            name={'fam_' + famille.ID_FAMILLE}
            onChange={handleChangePanel}
            panel={panelFamilleDemande}
            libelle={famille.FAM_LIB}
            AccordeonClassName={{
                'summary': classes.famillePanelSummary,
                'typography': classes.famillePanelTypography
            }}

        >
            {
                famille.SECTEURS.map(secteur => {
                    return (
                        <FormSecteur
                            secteur={secteur}
                            onChange={onChange}
                            panelSecteurDemande={panelSecteur}
                            panelControleDemande={panelControleDemande}
                            key={'sect_key_' + secteur.ID_SECTEUR}
                            idFamille={famille.ID_FAMILLE}
                        />
                    )

                })
            }
        </Accordion >

    )
}

