import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import LanguageIcon from '@material-ui/icons/Language'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import * as constants from '../../constants'
import { UserContext } from '../../context/UserContext'
import { refLangue, idToCodeLang } from '../../services/langue'
import { Referentiel } from '../../classes/referentiel'






/**
 * Composant Langue
 * Ce composant permet de changer la langue de l'utilisateur
 */
export default function Langue() {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const UserCtx = useContext(UserContext)

    /**
     * Gestion de l'affichage du menu de selection de la langue - Ouverture
     * @param {Event} event - Event de clic pour ouverture du menu des langues
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    /**
     * Gestion de l'affichage du menu de selection de la langue - Fermeture
     */
    const handleClose = () => {
        setAnchorEl(null);
    }

    /**
     * Changement de la langue du user
     * @param {String} newLang - Nouveau code langue à appliquer
     */
    function setLanguage(newLang) {
        //mise à jour dans le contexte
        UserCtx.changeLangue(newLang)

        // mise à jour dans le storage
        const UserStorage = JSON.parse(window.localStorage.getItem(constants.infUser))
        UserStorage.lang = newLang
        window.localStorage.setItem(constants.infUser, JSON.stringify(UserStorage))

        const ref = new Referentiel()
        ref.refresh([], true)
            .then(() => {
                UserCtx.afterChangeLangue.forEach(([id, uneFonction]) => {
                    if (typeof uneFonction === 'function') {
                        uneFonction()
                    }
                });
            })
            .catch()

        //mise à jour du gestionaire de langue
        i18n.changeLanguage(idToCodeLang(newLang))


        handleClose();

    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <React.Fragment>
            <IconButton edge="start" color="inherit" aria-label="langue" onClick={handleClick}>
                <LanguageIcon />
            </IconButton>
            <Menu id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} >
                {refLangue.map(((uneLangue, index) => {
                    return (
                        <MenuItem key={'language_key_' + index} onClick={() => setLanguage(uneLangue.id)} >
                            <Typography variant="inherit">{uneLangue.libelle}</Typography>
                        </MenuItem>
                    )
                }))}


            </Menu>
        </React.Fragment>
    )
}