import React, { useState } from 'react';
import PropTypes from 'prop-types'

import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation, Trans } from 'react-i18next';
import { FormHelperText } from '@material-ui/core';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    rootGroup: {
        display: "block",
        margin: "10px",
        height: 40,
    },
    formControle: {
        display: "block",
    },
    libelle: {
        color: "rgba(0, 0, 0, 0.54)",
        padding: 0,
        fontSize: "1rem",
        fontFamily: "Roboto, Helvetica, Arial, sans - serif",
        fontWeight: "400",
        lineHeight: '1',
        letterSpacing: '0.00938em',
    },
    box: {
        flexWrap: "nowrap",
    }
}));

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",

    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
Interrupteur.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
    ]).isRequired,
    defaultValue: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    libelle: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    libelleGauche: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    libelleDroite: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
}
Interrupteur.defaultProps = {
    // value: '',
    disabled: false,
    helperText: '',
    errorText: '',
    required: false,
    defaultValue: false,
    libelleGauche: <Trans i18nKey="commun:non" />,
    libelleDroite: <Trans i18nKey="commun:oui" />,
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Interrupteur({ value, defaultValue, name, libelle, disabled, onChange, libelleGauche, libelleDroite, helperText, errorText, required }) {
    //on surcharge pour que la langue change sans rafraichissement
    Interrupteur.defaultProps.libelleGauche = <Trans i18nKey="commun:non" />
    Interrupteur.defaultProps.libelleDroite = <Trans i18nKey="commun:oui" />

    const classes = useStyles();
    // const [state, setState] = useState((value === 1 || value === false) ? true : defaultValue);
    const [erreur, setErreur] = useState(errorText)
    const [t] = useTranslation(['commun'])

    const handleChange = (event) => {
        let uneErreur = ''
        // setState(event.target.checked)
        if (required && event.target.checked === false) {
            uneErreur = t('commun:helper')
        }
        // exécute le controle demandé par la props
        if (onChange) {
            uneErreur = onChange(event, uneErreur)
        }

        if (erreur !== uneErreur) {
            // initialise l'erreur selon le retour de la props
            setErreur(uneErreur)
        }



    };
    // retourne vrai si une erreur existe sinon faux
    function estEnErreur() {
        return (erreur && erreur !== '') ? true : false
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <FormGroup key={'ctrl_id_' + name} className={classes.rootGroup}>
            <FormControl error={estEnErreur()} className={classes.formControle} disabled={disabled} required={required}>
                <Typography component="div" className={classes.formControle}>

                    <Grid component="label" container alignItems="center" justify="space-between" spacing={1} nowrap="true">
                        <Grid item xs zeroMinWidth nowrap="true" className={classes.libelle} >
                            {libelle + (required ? ' *' : '')}
                        </Grid>
                        <Grid item >
                            <Grid component="label" container alignItems="center" spacing={1} className={classes.box}>
                                <Grid item className={classes.libelle}>{libelleGauche}</Grid>
                                <Grid item>
                                    <AntSwitch checked={((value === 1 || value === '1' || value === true) ? true : defaultValue)} onChange={handleChange} name={name} />
                                </Grid>
                                <Grid item className={classes.libelle}>{libelleDroite}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Typography>
                {
                    (estEnErreur() || helperText !== '') &&
                    < FormHelperText
                        id={'input_name_' + name + '_helper'}
                        className={classes.helper}
                    >

                        {
                            estEnErreur() ?
                                erreur
                                :
                                helperText
                        }

                    </FormHelperText>
                }

            </FormControl >
        </FormGroup >
    );
}
