import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden'
import { useTranslation } from 'react-i18next';
import Interrupteur from '../outils/generique/Interrupteur'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NotInterested from '@material-ui/icons/NotInterested'
import Done from '@material-ui/icons/Done'
import Clear from '@material-ui/icons/Clear'
import CircularProgress from '@material-ui/core/CircularProgress';
import Help from '@material-ui/icons/Help'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import { TitreContext } from '../../context/TitreContext'
import { useSnackbar } from 'notistack';
import qs from 'querystring'
import { construitErreur, useAfficheErreur } from '../../services/erreur'
import { Offline } from 'react-detect-offline'
import { Audit } from '../../classes/audit';
import ModSite from '../outils/modele/Site';
import { Site } from '../../classes/site';
import { TypeAudit } from '../../classes/typeAudit';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    legend: {
        padding: theme.spacing(4),
    }
}));

/**
 * Composant AuditNouveau
 * Ce composant affiche le formulaire de création d'un nouvel audit
 */
export default function AuditNouveau() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [t] = useTranslation(['audit', 'commun'])
    const [, setTitre] = useContext(TitreContext)

    const steps = getSteps();

    const [referentielType, setReferentielType] = useState([])

    const [tabSite, setTabSite] = useState([])

    const [tabTypeAudit, setTabTypeAudit] = useState([])

    const [tableauEnAttente, setTableauEnAttente] = useState(true)
    const [tableauFinal, setTableauFinal] = useState(false)
    const [disabledNext, setDisabledNext] = useState(true)

    const [openLegend, setOpenLegend] = useState(false);

    const [afficheErreur] = useAfficheErreur()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [onlineStatut, setOnlineStatut] = useState()


    useEffect(() => {
        setTitre(t('menu:Création des audits'))
    })

    /**
     * Charge les référentiels
     */
    useEffect(() => {
        TypeAudit.getListe()
            .then((listeTypeAudit) => {
                setReferentielType(listeTypeAudit)
            })
            .catch(afficheErreur)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Affiche un message en cas de mode offline
     */
    useEffect(() => {
        offlineSnackbar(navigator.onLine)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Gestion du chargement des données aux changements de step
     * 1 -> Parametre le tableau de récap du step 2 en mode "en attente"
     * 2 -> Charge les infos du tableau du tableau de récap
     */
    useEffect(() => {
        if (activeStep === 2) {
            let param = {}
            const idsSites = []
            tabSite.forEach(uneOption => {
                idsSites.push(uneOption.value)
            })
            param.idSite = idsSites.join(',')

            fetch(`/api/site/nbAudit?` + qs.stringify(param))
                .then(construitErreur)
                .then((res) => {
                    if (res && res.data) {
                        const newTabSite = tabSite
                        res.data.forEach(unRetour => {
                            const i = tabSite.findIndex(unSite => unRetour.ID_SITE === parseInt(unSite.value))
                            Object.keys(unRetour).forEach((propriete) => {
                                if (i >= 0 && propriete.startsWith('TYPE_')) {
                                    newTabSite[i][propriete] = unRetour[propriete] === null ? 0 : parseInt(unRetour[propriete])
                                }
                            })
                        })
                        setTabSite(newTabSite)
                        setTableauEnAttente(false)
                    }
                })
                .catch(afficheErreur)


        } else if (activeStep === 1) {
            setTableauEnAttente(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])

    /**
     * Gestion des boutons de navigations suivant les steps
     */
    useEffect(() => {

        if (activeStep === 0) {
            setDisabledNext(tabSite.length === 0)
        } else if (activeStep === 1) {
            setDisabledNext(tabTypeAudit.length === 0)
            if (tableauFinal) { setTableauFinal(false) }
        } else if (activeStep === 2) {
            setDisabledNext(tableauEnAttente || tableauFinal)
        }
        //quoiqu'il arrive, si il n'y  pas internet, les boutons restent grisés
        if (onlineStatut === false) {
            setDisabledNext(true)
        }

    }, [activeStep, onlineStatut, tabSite.length, tabTypeAudit.length, tableauEnAttente, tableauFinal])

    /**
     * Fonction de selection d'un site
     * @param {Event} event - event avec le site selectionné
     */
    function handleChangeSite(event) {
        const addSite = []
        if (event) {
            event.forEach(option => {
                addSite.push(option)
            })
        }
        setTabSite(addSite)

    }

    /**
     * Retourne les textes d'aides du formulaire
     */
    function getSteps() {
        return [t('audit:Sélection des sites'), t('audit:Choix des types d\'audit'), t('commun:Résumé de la demande')];
    }

    /**
     * retourne le contenu du formulaire à afficher suivant le step courant
     * @param {integer} step - step courant
     */
    function getStepContent() {
        switch (activeStep) {
            case 0:
                return (
                    <>
                        <Typography>{t('audit:Sélectionnez les sites dont vous souhaitez créer les audits')}</Typography>
                        <ModSite multi={true} onChange={handleChangeSite} value={tabSite} />
                    </>
                );
            case 1:
                return (
                    <>
                        <Typography>{t('audit:Sélectionnez les types d\'audit à créer')}</Typography>
                        {referentielType.length > 0 ?
                            referentielType.map((unTypeAudit) => {

                                return (
                                    < Interrupteur
                                        key={"INT_" + unTypeAudit.TYPE_CODE}
                                        value={(tabTypeAudit.findIndex(unType => unType.ID_TYPE === unTypeAudit.ID_TYPE) !== -1) ? true : false}
                                        name={unTypeAudit.TYPE_CODE}
                                        libelle={unTypeAudit.TYPE_LIB}
                                        onChange={(event) => {
                                            const UpdTabTypeAudit = [...tabTypeAudit]
                                            const value = event.target.checked
                                            const index = UpdTabTypeAudit.findIndex(unType => unType.ID_TYPE === unTypeAudit.ID_TYPE)
                                            if (value) {
                                                if (index === -1) {
                                                    UpdTabTypeAudit.push(unTypeAudit)
                                                }
                                            } else {
                                                if (index !== -1) {
                                                    UpdTabTypeAudit.splice(index, 1)
                                                }
                                            }
                                            setTabTypeAudit(UpdTabTypeAudit)
                                        }}


                                    />
                                )
                            })
                            :
                            <></>}
                    </>
                )
            case 2:
                return (
                    <>
                        <Typography>
                            {t('commun:Résumé de la demande')}
                            <IconButton color="primary" aria-label="Helper picture" component="span" onClick={handleToggleLegend}>
                                <Help />
                            </IconButton>
                        </Typography>
                        <Backdrop className={classes.backdrop} open={openLegend} onClick={handleCloseLegend}>
                            <Paper className={classes.legend}>
                                <Typography component="h4">{t('commun:Légende')}</Typography>
                                <hr />
                                <Typography> <NotInterested color="error" /> {t('audit:Création interdite, audit déjà existant')} </Typography>
                                <Typography> <Done color="action" />{t('Création possible')}</Typography>
                                <Typography> <Done htmlColor="green" /> {t('audit:Audit créé')} </Typography>
                                <Typography> <Clear color="error" /> {t('audit:Erreur lors de la création de l\'audit')}</Typography>
                            </Paper>

                        </Backdrop>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Site</TableCell>
                                    {tabTypeAudit.map((unTypeAudit, index) => {
                                        return (
                                            <TableCell key={"Type_col_" + index}>{unTypeAudit.TYPE_LIB}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tabSite.map((unSite, index) => {
                                    return (
                                        <TableRow key={"row_" + index}>
                                            <TableCell>{unSite.label}</TableCell>
                                            {tabTypeAudit.map((unTypeAudit, indexCol) => {
                                                return (
                                                    <TableCell key={"row_" + index + "_col_" + indexCol}>
                                                        {tableauEnAttente ? <CircularProgress size={16} thickness={5} /> : ""}
                                                        {unSite['TYPE_' + unTypeAudit.ID_TYPE] && unSite['TYPE_' + unTypeAudit.ID_TYPE] > 0 ? <NotInterested color="error" /> : ""}

                                                        {!tableauFinal && unSite['TYPE_' + unTypeAudit.ID_TYPE] !== undefined && unSite['TYPE_' + unTypeAudit.ID_TYPE] === 0 ? <Done color="action" /> : ""}
                                                        {tableauFinal && unSite['STAT_TYPE_' + unTypeAudit.ID_TYPE] !== undefined && unSite['STAT_TYPE_' + unTypeAudit.ID_TYPE] === 'ok' ? <Done htmlColor="green" /> : ""}
                                                        {tableauFinal && unSite['STAT_TYPE_' + unTypeAudit.ID_TYPE] !== undefined && unSite['STAT_TYPE_' + unTypeAudit.ID_TYPE] === 'ko' ? <Clear color="error" /> : ""}


                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </>
                )
            default:
                return 'Unknown step';
        }
    }

    /**
     * Fonction de création d'un nouvel audit
     */
    const CreerAudit = () => {
        setDisabledNext(true)

        const AuditToSave = []
        tabSite.forEach((currentSite) => {
            const monSite = Site.getByID(currentSite.value)

            tabTypeAudit.forEach((currentType) => {
                if (currentSite['TYPE_' + currentType.ID_TYPE] !== undefined && currentSite['TYPE_' + currentType.ID_TYPE] === 0) {
                    const unAudit = new Audit()
                    unAudit.ID_SITE = currentSite.value
                    unAudit.SITE_PAYS = monSite.PAYS.ID_PAYS
                    unAudit.ID_TYPE = currentType.ID_TYPE
                    AuditToSave.push(unAudit)
                }
                currentSite['STAT_TYPE_' + currentType.idType] = undefined
            })

        })

        if (AuditToSave.length > 0) {
            Audit.newAudits(AuditToSave)
                .then((res) => {
                    if (res && res.data) {
                        const newTabSite = tabSite
                        res.data.forEach(unRetour => {
                            const i = tabSite.findIndex(unSite => unRetour.ID_SITE === parseInt(unSite.value))
                            if (i >= 0) {
                                newTabSite[i]['STAT_TYPE_' + unRetour.ID_TYPE] = unRetour.statut
                            }
                        })
                        setTabSite(newTabSite)
                        setTableauFinal(true)
                    }
                })
                .catch(afficheErreur)
        }
    }

    /**
     * Gestion du bouton Suivant
     */
    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            CreerAudit()
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    /**
     * Gestion du bouton retour
     */
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    /**
     * Fonction d'assemblage du formulaire pour un step
     * @param {Integer} step - step courant
     */
    const ContenuStep = () => {
        return (
            <>
                <Typography component="div">{getStepContent(activeStep)}</Typography>
                <div className={classes.actionsContainer}>
                    <div>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                        >
                            {t('commun:Retour')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            disabled={disabledNext}
                        >
                            {activeStep === steps.length - 1 ? t('commun:Créer') : t('commun:Suivant')}

                        </Button>
                        {activeStep === steps.length - 1 &&
                            <Button
                                aria-label={t('audit:Liste des audits')}
                                className={classes.button}
                                // variant="outlined"
                                color="primary"
                                component={Link}

                                to="/audit">
                                {t('audit:Liste des audits')}
                            </Button>}
                    </div>
                </div>
            </>
        )
    }

    /**
     * Fermeture de la légende
     */
    const handleCloseLegend = () => {
        setOpenLegend(false);
    }

    /**
     * Ouverture de la légende
     */
    const handleToggleLegend = () => {
        setOpenLegend(!openLegend);
    }

    /**
     * Affichage de la scnackbar pour préciser que la fonction n'est disponible qu'online
     * @param {Boolean} online - Represente si le site est lancé online ou offline
     */
    const offlineSnackbar = (online) => {
        const action = key => (
            <IconButton onClick={() => { closeSnackbar(key) }}  ><Clear /> </IconButton>
        );
        if (!online) {
            enqueueSnackbar(t('audit:La fonction de création des audits est indisponible hors ligne.'), {
                variant: 'warning',
                persist: true,
                action

            })
            setOnlineStatut(false)
        } else {
            closeSnackbar()
            setOnlineStatut(true)
        }
    }

    return (
        <div className={classes.root}>
            <Hidden smDown>
                <>
                    <Stepper activeStep={activeStep} alternativeLabel orientation="horizontal" >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Paper square elevation={0} className={classes.resetContainer}>
                        {ContenuStep(activeStep)}
                    </Paper>

                </>
            </Hidden>
            <Hidden mdUp>
                <Stepper activeStep={activeStep} orientation="vertical" >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                {ContenuStep(index)}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Hidden>
            <Offline polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }} onChange={offlineSnackbar} />
        </div >
    );
}
