import React, { createRef } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';


import MaterialTable from 'material-table';
import { Hidden } from '@material-ui/core';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import SelectMono from '../generique/SelectMono'
import Interrupteur from '../generique/Interrupteur'
import TextField from '../generique/TextFied';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
Tableau.propTypes = {
    addAuth: PropTypes.bool,
    updateAuth: PropTypes.bool,
    deleteAuth: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
    options: PropTypes.object,
    form: PropTypes.func,
    disabled: PropTypes.bool,
    expandable: PropTypes.bool,
    actions: PropTypes.array.isRequired,
}

Tableau.defaultProps = {
    addAuth: false,
    updateAuth: false,
    deleteAuth: false,
    columns: [],
    data: [],
    options: {
        actionsColumnIndex: -1
        , draggable: false
        , search: false
        , showTitle: false
        , paging: false
        , detailPanelType: 'single'
    },
    disabled: false,
    expandable: true,
    actions: [],
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Tableau(props) {

    const [t] = useTranslation(['commun'])
    const estDesactive = (!props.updateAuth || props.disabled) ? true : false
    const columnsLarge = props.columns.map(line => {
        const newLine = line
        if (newLine.type && newLine.type === 'boolean') {
            newLine.editComponent = (Table) => (
                <Interrupteur
                    value={Table.rowData[Table.columnDef.field]}
                    name={Table.columnDef.field} libelle={""}
                    onChange={e => {
                        var data = { ...Table.rowData }
                        data[Table.columnDef.field] = e.target.checked
                        Table.onRowDataChange(data)
                    }}
                    disabled={estDesactive}
                />
            )

        }
        return newLine

    })
    const columnsSmall = columnsLarge.filter(line => line.small && line.small === true)

    const optionsData = {
        rowStyle: rowData => ({
            textDecoration: (rowData.tableData && rowData.tableData.delete && rowData.tableData.delete === true) ? 'line-through' : 'none'
        }),
        ...props.options
    }

    const materialTableRef = createRef()

    const traductionTableau = {
        body: {
            editRow: {
                deleteText: t('Voulez vous vraiment supprimer cette ligne ?'),
            },
            emptyDataSourceMessage: t('Aucune donnée à afficher')
        },
    }
    const updateData = (event, index) => {
        const dataUpdate = [...props.data];
        if (index >= 0) {
            if (event.target.checked) {
                dataUpdate[index][event.target.name] = event.target.checked
            } else {
                dataUpdate[index][event.target.name] = event.target.value
            }
            dataUpdate[index]['tableData']['modif'] = true
        }
        props.setData([...dataUpdate]);
    }

    function getForm(rowData) {
        if (props.form) {
            return props.form
        }
        const handleChange = (event) => {
            const index = props.data.findIndex(line => line === rowData);
            updateData(event, index)
        }
        return columnsLarge.map(colonne => {
            switch (colonne.type) {
                case 'numeric':
                    return <TextField type="number" value={rowData[colonne.field]} name={colonne.field} libelle={colonne.title} onChange={handleChange} disabled={estDesactive} />

                case 'boolean':
                    return <Interrupteur value={rowData[colonne.field]} name={colonne.field} libelle={colonne.title} onChange={handleChange} disabled={estDesactive} />

                default:
                    if (colonne.lookup) {
                        const mesChoix = []
                        for (const id in colonne.lookup) {
                            mesChoix.push(
                                {
                                    value: id,
                                    label: colonne.lookup[id],
                                    code: colonne.lookup[id],
                                    key: id,
                                }
                            )
                        }

                        return <SelectMono value={rowData[colonne.field]} name={colonne.field} libelle={colonne.title} onChange={handleChange} disabled={estDesactive} mesChoix={mesChoix} />
                    } else {
                        return <TextField value={rowData[colonne.field]} name={colonne.field} libelle={colonne.title} onChange={handleChange} disabled={estDesactive} />
                    }
            }
        })

    }


    function Action(screenSize) {
        let editable = {}
        if (screenSize === 'large') {
            if (props.addAuth) {
                editable.onRowAdd = newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {

                            if (newData.tableData === undefined) { newData.tableData = {} }
                            newData.tableData.modif = true
                            props.setData([...props.data, newData]);

                            resolve();
                        }, 1000)
                    })
            }
            if (props.updateAuth) {
                editable.onRowUpdate = (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...props.data];
                            const index = oldData.tableData.id;
                            if (newData.tableData === undefined) { newData.tableData = {} }

                            newData.tableData.modif = true
                            dataUpdate[index] = newData;
                            props.setData([...dataUpdate]);

                            resolve();
                        }, 1000)
                    })
            }
        }

        if (props.deleteAuth) {
            editable.onRowDelete = oldData =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        const dataUpdate = [...props.data];
                        const index = oldData.tableData.id;
                        if (oldData.tableData === undefined) { oldData.tableData = {} }
                        oldData.tableData.delete = true
                        dataUpdate[index] = oldData;

                        props.setData([...dataUpdate], oldData);
                        resolve()
                    }, 1000)
                })
        }


        if (props.addAuth === false || props.disabled) { editable.onRowAdd = undefined }
        if (props.updateAuth === false || props.disabled) { editable.isEditable = (rowData) => false }
        if (props.deleteAuth === false || props.disabled) { editable.isDeletable = (rowData) => false }


        return editable

    }

    const addPerso = (materialTable) => {
        return new Promise((resolve, reject) => {
            const newLigne = {}
            newLigne.tableData = { 'modif': true }
            props.setData([...props.data, newLigne]);
            materialTable.setState({
                ...materialTable.dataManager.getRenderState(),
            });
            resolve(materialTable)
        })
    }

    return (
        <>
            <Hidden smDown>
                <MaterialTable
                    icons={tableIcons}
                    columns={columnsLarge}
                    data={props.data}
                    editable={Action('large')}
                    options={optionsData}
                    localization={traductionTableau}
                    actions={[...props.actions]}
                />
            </Hidden>
            <Hidden mdUp>
                <MaterialTable
                    icons={tableIcons}
                    columns={columnsSmall}
                    tableRef={materialTableRef}
                    data={props.data}
                    editable={Action('small')}
                    detailPanel={props.expandable ? rowData => {
                        return (
                            <>
                                <Hidden mdUp>
                                    {getForm(rowData)}
                                </Hidden>
                            </>
                        )
                    } : undefined}
                    onRowClick={props.expandable ? (event, rowData, togglePanel) => togglePanel() : undefined}
                    options={optionsData}
                    localization={traductionTableau}
                    actions={

                        [
                            ...props.actions,
                            props.addAuth === false ? undefined :
                                {
                                    icon: AddBox,
                                    tooltip: 'Add ',
                                    isFreeAction: true,
                                    disabled: !props.addAuth,
                                    onClick: (event) => {
                                        addPerso(materialTableRef.current)
                                            .then((materialTable) => {
                                                materialTable.onToggleDetailPanel(
                                                    [props.data.length],
                                                    materialTable.props.detailPanel)
                                            })
                                            .catch(console.error)
                                    }
                                }
                        ]}
                />
            </Hidden>
        </>
    )

}
