import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/SyncDisabled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Online, Offline } from 'react-detect-offline'
import SaveIcon from '@material-ui/icons/Save'
import { SaveAllSites } from '../../services/site'
import * as constants from '../../constants'
import TitreBox from '../outils/utilitaires/titreBox'
import { ControleUpdate } from '../../classes/controleUpdate';
import { Audit } from '../../classes/audit'


const useStyles = makeStyles((theme) => ({
    card: {
        ...theme.card,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
const ColorCircularProgress = withStyles((theme) => ({
    root: {
        color: theme.primary,
        marginLeft: theme.spacing(1)
    },
}))(CircularProgress);


/**
 * Composant CardSaveEnAttente
 * Affiche et lance les sauvegardes en attente concernant les sites et les audits 
 */
export default function CardSaveEnAttente() {
    const [t] = useTranslation(['accueil', 'menu'])
    const classes = useStyles();
    const [reload, setReaload] = useState(false)
    const [auditToSave, setAuditToSave] = useState(Audit.getAuditToSave())

    const [sitesToSave, setSiteToSave] = useState(JSON.parse(window.localStorage.getItem(constants.LocalSiteToUPD)) || [])

    /**
     * timer pour sauvegarder les audits et les sites
     */
    useEffect(() => {
        function lanceSave() {
            if (auditToSave.length > 0) {
                auditToSave.forEach((idEntete) => {
                    const unAudit = Audit.byLocalStorage(idEntete)
                    unAudit.save()
                        .then(() => {
                            setReaload(true)
                        })
                        .catch(console.error)
                })
            }
            if (sitesToSave.length > 0) {
                SaveAllSites(() => {
                    setReaload(true)
                })
            }
        }
        ControleUpdate.NettoieLocalStorage()
        lanceSave()
        const idInterval = setInterval(() => {
            lanceSave()
        }, process.env.REACT_APP_TIME_SAVE_AUDIT)
        return () => {
            clearInterval(idInterval)
            setReaload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (reload) {
            setReaload(false)
            setAuditToSave(Audit.getAuditToSave())
            setSiteToSave(JSON.parse(window.localStorage.getItem(constants.LocalSiteToUPD)) || [])
        }
        return () => setReaload(false)
    }, [reload])



    /**
     * Render les information de l'audit à sauvegarder
     * @param {Audit} unAudit - audit à savegarder
     */
    function renderAudit(idEntete) {
        const localStorageAudit = Audit.byLocalStorage(idEntete)
        if (localStorageAudit !== null) {
            return (
                <Typography component='div' key={'audit_a_save_' + localStorageAudit.ID_ENTETE} color="textSecondary">
                    {localStorageAudit.SITE_NOM} - {localStorageAudit.TYPE_LIB}

                    <Online polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}> <ColorCircularProgress size={16} thickness={5} />  </Online>
                    <Offline polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}><ErrorIcon color="secondary" /></Offline>

                </Typography >
            )
        } else {

            return (<></>)
        }
    }

    /**
    * Render les informations dus site à sauvegarder
    * @param {Integer} idSite - id du site à sauvegarder
    */
    function renderSite(idSite) {
        const localStorageSite = JSON.parse(window.localStorage.getItem(constants.localSiteListe))
        if (localStorageSite !== null) {
            const monSite = localStorageSite.find(unSite => unSite.ID_SITE === idSite)
            if (monSite) {
                return (
                    <Typography component='div' key={'site_a_save_' + monSite.ID_SITE} color="textSecondary">
                        {monSite.SITE_ENTITE} - {monSite.SITE_NOM}

                        <Online polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}> <ColorCircularProgress size={16} thickness={5} />  </Online>
                        <Offline polling={{ 'url': `${process.env.REACT_APP_HOST}/api/version` }}><ErrorIcon color="secondary" /></Offline>

                    </Typography >
                )
            }
        }
        return (<></>)
    }

    return (
        <Card className={classes.card} key="save_card">
            <TitreBox titre={t('accueil:Sauvegarde en attente')} icone={SaveIcon} />
            <CardContent>
                {
                    (auditToSave.length > 0) &&
                    auditToSave.map((idEntete) => (renderAudit(idEntete)))
                }
                {
                    (sitesToSave && sitesToSave.length > 0) &&
                    sitesToSave.map((unSite) => (renderSite(unSite.idSite)))
                }
                {
                    ((!sitesToSave || sitesToSave.length <= 0) && (auditToSave.length <= 0)) &&
                    <Typography color="textSecondary">{t('accueil:Pas de sauvegarde en attente')}  </Typography>
                }

            </CardContent>
        </Card>
    )
}

