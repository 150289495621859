import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { ListeProvider as ListeProviderAudit } from '../../context/ListeContextAudit'
import { ListeProvider as ListeProviderSite } from '../../context/ListeContextSite'
import { AuditProvider } from '../../context/AuditContext'

import { useTranslation } from 'react-i18next';


import Accueil from '../accueil/Accueil'
import AuditListe from '../audit/auditListe'
import AuditNew from '../audit/auditNouveau'
import AuditForm from '../audit/auditForm'
import SiteListe from '../site/siteListe'
import SiteForm from '../site/siteForm'
import Deconnexion from '../connexion/deconnexion';
import LocalDataManager from '../developpeurMode/LocalDataManager';

// import { ListeProvider as ListeProviderReprise } from '../../context/ListeContextReprise'
// import RepriseForm from '../reprise/repriseForm'
// import RepriseListe from '../reprise/repriseListe'


/**
 * Composant Router
 * Ce composant est le routeur de l'application
 */
export default function Router() {
    const [t] = useTranslation(['commun'])
    const UserCtx = useContext(UserContext)


    /**
     * Fonction de controle des droits de visu d'un module
     * @param {Object} unDroit - Object contenant les droits de l'utilisateur
     */
    function controleDroitVisu(unDroit) {
        return (UserCtx.droits[unDroit] && UserCtx.droits[unDroit].visu === true)
    }

    return (
        < Switch >
            <Route exact path="/">
                <Accueil />
            </Route>
            < Route exact path='/accueil' >
                <Accueil />
            </Route >
            <Route exact path='/audit'>
                <ListeProviderAudit>
                    {controleDroitVisu('DroitModuleAudit') ? <AuditListe /> : <>{t('Accès interdit')}</>}
                </ListeProviderAudit>
            </Route>
            <Route exact path='/audit/new'>
                {controleDroitVisu('DroitModuleAudit') ? <AuditNew /> : <>{t('Accès interdit')}</>}
            </Route>

            <Route exact path='/audit/:IdEntete'>
                <AuditProvider>
                    {controleDroitVisu('DroitModuleAudit') ? <AuditForm /> : <>{t('Accès interdit')}</>}
                </AuditProvider>
            </Route>

            {/* <Route exact path='/reprise'>
            <ListeProviderReprise>
                {controleDroitVisu('DroitModuleReprise') ? <RepriseListe /> : <>{t('Accès interdit')}</>}
                </ListeProviderReprise>
            </Route>

            <Route exact path='/reprise/new'>
                {controleDroitVisu('DroitModuleReprise') ? <RepriseForm /> : <>{t('Accès interdit')}</>}
            </Route>

            <Route exact path='/reprise/:IDSiteCandidat'>
                {controleDroitVisu('DroitModuleReprise') ? <RepriseForm /> : <>{t('Accès interdit')}</>}
            </Route> */}

            <Route exact path='/site/:idSite'>
                {controleDroitVisu('DroitModuleSite') ? <SiteForm /> : <>{t('Accès interdit')}</>}
            </Route>

            <Route exact path='/site'>
                <ListeProviderSite>
                    {controleDroitVisu('DroitModuleSite') ? <SiteListe /> : <>{t('Accès interdit')}</>}
                </ListeProviderSite>
            </Route>

            <Route exact path='/localdata'>
                <LocalDataManager />
            </Route>

            <Route exact path='/deconnexion'>
                <Deconnexion />
            </Route>

            <Route path="*">
                <Redirect to='/accueil' />
            </Route>
        </Switch >
    )
}