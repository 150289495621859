import * as constants from '../constants'
import { construitErreur } from './erreur'
import qs from 'querystring'


export function AjouteDerniereModif(typeElement, element, dateModif = new Date()) {
    const myStorage = window.localStorage
    let lastModif = JSON.parse(myStorage.getItem(constants.LocalLastMaj)) || {}
    let LocalElement = lastModif[typeElement] || {}

    const dateModifLocal = (LocalElement.date) ? new Date(LocalElement.date) : new Date(1950, 1, 1)
    if (dateModifLocal < dateModif) {
        const elementFormat = genereElement(typeElement, element)
        if (elementFormat !== undefined) {
            LocalElement.date = dateModif
            LocalElement.element = elementFormat
        }
    }
    lastModif[typeElement] = {}
    lastModif[typeElement] = LocalElement

    myStorage.setItem(constants.LocalLastMaj, JSON.stringify(lastModif))

}

export function MajDerniereModif(typeElement, param, ac) {
    return new Promise((resolve, reject) => {
        fetch(`/api/${typeElement}?` + qs.stringify(param), ac)
            .then(construitErreur)
            .then((res) => {
                if (res.data) {
                    const element = res.data[0]
                    const dateLastModif = (element.DAT_MAJ !== null) ? new Date(element.DAT_MAJ) : new Date(element.DAT_CRE)
                    AjouteDerniereModif(typeElement, element, dateLastModif)
                    resolve()
                }
                reject()
            })
            .catch((erreur) => {
                reject(erreur)
            })
    })
}

function genereElement(typeElement, element) {
    let newElement = {}

    switch (typeElement) {
        case 'audit':
            newElement.id = element.ID_ENTETE
            newElement.titre = element.SITE_NOM
            newElement.sousTitre = element.TYPE_LIB
            break;
        case 'site':
            newElement.id = element.ID_SITE
            newElement.titre = element.SITE_NOM
            newElement.sousTitre = element.LIBELLE_PAYS
            break;
        default:
            break;
    }
    if (newElement.id) {
        return newElement
    } else {
        return undefined
    }

}