import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    helper: {
        fontStyle: 'italic',
        width: "100%",
    },
    gridButton: {
        width: '100%',
        minWidth: '220px',
        // 1212 : taille à partir de laquelle les 4 boutons  + marges Horizontales ne peuvent pas tenir sur 1 ligne
        [theme.breakpoints.up('lg')]: {
            width: 'calc(100% / max(1, 4))',
        },
    },

    // sytle des boutons
    buttonDefault: {
        textTransform: 'capitalize',
        borderWidth: '1px',
        borderColor: 'primary',
        width: '80%',
        minWidth: '180px',
        height: '60px',
        margin: '15px 20px 15px 20px',  // top right bottom left
        color: 'primary',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'primary',
        }
    },

    // bouton success
    buttonSuccess: {
        borderColor: theme.palette.auditEtat.acceptable,
        color: theme.palette.auditEtat.acceptable,
        backgroundColor: 'white',
        '&:hover': {
            color: theme.palette.auditEtat.acceptable + 'CC',
            backgroundColor: 'white',
        }
    },
    buttonSuccessSelected: {
        borderColor: theme.palette.auditEtat.acceptable,
        color: 'white',
        backgroundColor: theme.palette.auditEtat.acceptable,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.auditEtat.acceptable + 'CC',
        }
    },

    // bouton warning
    buttonWarning: {
        borderColor: theme.palette.auditEtat.moyen,
        color: theme.palette.auditEtat.moyen,
        backgroundColor: 'white',
        '&:hover': {
            color: theme.palette.auditEtat.moyen + 'CC',
            backgroundColor: 'white',
        }
    },
    buttonWarningSelected: {
        borderColor: theme.palette.auditEtat.moyen,
        color: 'white',
        backgroundColor: theme.palette.auditEtat.moyen,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.auditEtat.moyen + 'CC',
        }
    },

    // bouton danger
    buttonDanger: {
        borderColor: theme.palette.auditEtat.inacceptable,
        color: theme.palette.auditEtat.inacceptable,
        backgroundColor: 'white',
        '&:hover': {
            color: theme.palette.auditEtat.inacceptable + 'CC',
            backgroundColor: 'white',
        }
    },
    buttonDangerSelected: {
        borderColor: theme.palette.auditEtat.inacceptable,
        color: 'white',
        backgroundColor: theme.palette.auditEtat.inacceptable,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.auditEtat.inacceptable + 'CC',
        }
    },

    // bouton non applicable
    buttonNonApplicable: {
        borderColor: theme.palette.auditEtat.inapplicable,
        color: theme.palette.auditEtat.inapplicable,
        backgroundColor: 'white',
        '&:hover': {
            color: theme.palette.auditEtat.inapplicable + 'CC',
            backgroundColor: 'white',

        }
    },
    buttonNonApplicableSelected: {
        borderColor: theme.palette.auditEtat.inapplicable,
        color: 'white',
        backgroundColor: theme.palette.auditEtat.inapplicable,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.auditEtat.inapplicable + 'CC',
        }
    },
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
RadioButton.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),

    choix: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            libelle: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            style: PropTypes.string.isRequired,
        }),
    ),
}

RadioButton.defaultProps = {
    // value: '',
    disabled: false,
    helperText: '',
    errorText: '',
    required: false,
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function RadioButton({ value, name, disabled, onChange, helperText, errorText, required, choix }) {
    const classes = useStyles()
    const [erreur, setErreur] = useState(errorText)
    const [boutonSelect, setBoutonSelect] = useState((value && value > 0) ? value : 0)
    const [t] = useTranslation(['commun'])



    function handleChange(e) {
        // let valeurSaisie = event
        let uneErreur = ''

        if (required && e.target.value === 0) {
            uneErreur = t('commun:helper')
        }

        // exécute le controle demandé par la props
        if (onChange) {
            uneErreur = onChange(e, uneErreur)
        }

        if (erreur !== uneErreur) {
            // initialise l'erreur selon le retour de la props
            setErreur(uneErreur)
        }
        setBoutonSelect(e.target.value)
    }

    // retourne vrai si une erreur existe sinon faux
    function estEnErreur() {
        return (erreur && erreur !== '') ? true : false
    }

    // donne le style du bouton à appliquer
    function donneStyleButton(style, selected) {
        let styleApplique = classes.buttonDefault

        switch (style) {
            case 'success':
                if (selected === true) {
                    styleApplique = [classes.buttonDefault, classes.buttonSuccessSelected].join(" ")
                } else {
                    styleApplique = [classes.buttonDefault, classes.buttonSuccess].join(" ")
                }
                break;

            case 'warning':
                if (selected === true) {
                    styleApplique = [classes.buttonDefault, classes.buttonWarningSelected].join(" ")
                } else {
                    styleApplique = [classes.buttonDefault, classes.buttonWarning].join(" ")
                }
                break;

            case 'danger':
                if (selected === true) {
                    styleApplique = [classes.buttonDefault, classes.buttonDangerSelected].join(" ")
                } else {
                    styleApplique = [classes.buttonDefault, classes.buttonDanger].join(" ")
                }
                break;

            case 'secondary':
                if (selected === true) {
                    styleApplique = [classes.buttonDefault, classes.buttonNonApplicableSelected].join(" ")
                } else {
                    styleApplique = [classes.buttonDefault, classes.buttonNonApplicable].join(" ")
                }
                break;

            default:
                break;
        }

        return styleApplique
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    // render des boutons
    function renderButton(mesChoix) {
        return mesChoix.map(unChoix => {
            return (
                <Grid
                    component="span"
                    container
                    className={classes.gridButton}
                    justify="center"
                    key={unChoix.key}
                >
                    <Button
                        variant='outlined'
                        className={donneStyleButton(unChoix.style, (boutonSelect === unChoix.value || mesChoix.length === 1))}
                        onClick={() => handleChange({ target: { name: unChoix.name, value: unChoix.value } })}
                        disabled={disabled}
                    >
                        {unChoix.libelle}
                    </Button >
                </Grid >
            )
        })
    }

    return (
        < FormGroup key={'ctrl_id_' + name}>
            < FormControl error={estEnErreur()} disabled={disabled} >
                <Grid
                    component="span"
                    container spacing={0}
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid
                        component="span"
                        item
                        xs={12} sm={12} md={12} lg={12}
                    >
                        <Grid
                            component="span"
                            container
                            justify="center"
                            spacing={0}
                        >
                            {renderButton(choix)}
                        </Grid>
                    </Grid>

                    <Grid
                        component="span"
                        item
                        xs={12} sm={12} md={12} lg={12}
                    >
                        {
                            (estEnErreur() || helperText !== '') &&
                            < FormHelperText
                                id={'input_name_' + name + '_helper'}
                                className={classes.helper}
                            >

                                {
                                    estEnErreur() ?
                                        erreur
                                        :
                                        helperText
                                }

                            </FormHelperText>
                        }
                    </Grid>

                </Grid>
            </FormControl>
        </FormGroup >
    )
}