import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import TextFied from "../outils/generique/TextFied";
import { AuditContext } from "../../context/AuditContext";
import Box from "../outils/utilitaires/box";
import { Audit } from "../../classes/audit";

/**
 * Composant FormBudget
 * Ce composant affiche un bloc budget
 * @param {{
 *          reponseControle : Reponse,
 *          setControle : function
 *        }} props
 */
export default function FormBudget({ idControle, idSecteur, idFamille, refreshPastille }) {
    const { onChangeControle, readOnly, monAudit } = useContext(AuditContext);
    const [t] = useTranslation(["audit"]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDERS
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * Render des champs budgets
     */
    function renderBudget() {
        const maReponseControle = Audit.byLocalStorage(monAudit.ID_ENTETE).getReponseByControle(idControle);
        return maReponseControle.BUDGETS.map((budget) => {
            return (
                <Grid component="span" item xs={12} sm={12} md={2} lg={2} key={"ctrl_" + idControle + "_BUD_" + budget.ID_LIGNE_BUDGET}>
                    <TextFied
                        type={"number"}
                        name={idFamille + "_" + idSecteur + "_" + idControle + "_" + budget.ID_LIGNE_BUDGET}
                        key={"key_BUD_" + budget.ID_LIGNE_BUDGET}
                        libelle={budget.BUD_LIG_ANNEE.toString()}
                        value={budget.BUD_LIG_MONTANT === null ? 0 : budget.BUD_LIG_MONTANT}
                        onChange={(e) => {
                            onChangeControle("budget", e);
                            refreshPastille();
                        }}
                        disabled={readOnly}
                    />
                </Grid>
            );
        });
    }

    return (
        <React.Fragment>
            {Audit.byLocalStorage(monAudit.ID_ENTETE)?.getReponseByControle(idControle)?.BUDGETS?.length !== 0 ? (
                <Box titre={t("audit:budgetLib")} icone={EuroSymbolIcon} containerMode={true}>
                    {renderBudget()}
                </Box>
            ) : (
                <></>
            )}
        </React.Fragment>
    );
}
