import React, { useCallback } from 'react'
import * as constants from '../constants'
import { Trans } from 'react-i18next'
import { useSnackbar } from 'notistack'

export function construitErreur(res) {
    if (!res.ok) {
        console.log(res)
        if (res.status === 401) {
            window.localStorage.setItem(constants.infUser, null)
            setTimeout(() => { window.location.pathname = '/login' }, 5000)
            throw new Error(res.status)
        } else if (res.status === 403) {
            throw new Error(res.status)
        } else if (res.status === 404) {
            const data = {}
            data.data = {}
            return data
        } else if (res.status === 413) {
            throw new Error(res.status)
        }
    }
    return res.json()
}

export const useAfficheErreur = () => {
    const { enqueueSnackbar } = useSnackbar();

    const afficheErreur = useCallback((error) => {
        if (error.message === '403') {
            return enqueueSnackbar(<Trans i18nKey="commun:accesRefusee" defaults="Accès non autorisé." />, { variant: "error" })
        } else if (error.message === '401') {
            return enqueueSnackbar(<Trans i18nKey="commun:sessionExpiree" defaults="Session expirée. Vous allez devoir vous réauthentifier." />, { variant: "error" })
        } else if (isNoInternetError(error)) {
            //mode offline, la requete n'est même pas executé et une erreur est renvoyée. Pas d'affichage pour cette erreur
            if (process.env.NODE_ENV === 'development') {
                console.log('Offline : la requete n\'a pas été executée par le navigateur.')
            }
        } else {
            enqueueSnackbar(<Trans i18nKey="commun:systemErreur" defaults="Erreur système : {{error}}" values={{ error: error.toString() }} />, { variant: "error" })

        }
    }, [enqueueSnackbar])
    return [afficheErreur]
}

export const isNoInternetError = (error) => {

    return navigator.onLine === false || (error.name === 'TypeError' && error.message === 'Failed to fetch')
}