import React, { useEffect, useState } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionPanel from '@material-ui/core/Accordion';
// import useTraceUpdate from './useTraceUpdate';

// const debug = false



///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Accordion(props) {
    const [panel, setPanel] = useState(props.panel.toString())

    // useTraceUpdate({ ...props }, Accordion.name + '_' + props.libelle.toString(), debug)


    useEffect(() => {
        if (props.panel.toString() !== panel) {
            setPanel(props.panel.toString())
        }
    }, [panel, props.panel])



    const handleChange = (valeur, expanded) => {
        setPanel(valeur.toString())
        props.onChange(valeur.toString(), expanded)
    }






    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <AccordionPanel
            square
            expanded={panel.toString() === props.name.toString()}
            onChange={(e, expanded) => handleChange(props.name.toString(), expanded)}
            TransitionProps={{ mountOnEnter: true, timeout: 300 }} //permet le chargement du contenu de l'accordeon à l'ouverture seulement
        >

            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={"id-" + props.name}
                className={
                    ((props.AccordeonClassName && props.AccordeonClassName.summary) ? props.AccordeonClassName.summary : '')
                    + ' ' + ((panel.toString() === props.name.toString() && props.AccordeonClassName && props.AccordeonClassName.expanded) ? props.AccordeonClassName.expanded : '')
                }
            >
                {props.libelle}
            </AccordionSummary>

            <AccordionDetails className={(props.AccordeonClassName && props.AccordeonClassName.details) ? props.AccordeonClassName.details : ''}>
                <Typography component={'div'} className={(props.AccordeonClassName && props.AccordeonClassName.typography) ? props.AccordeonClassName.typography : ''}>
                    {props.children}
                </Typography>
            </AccordionDetails>

        </AccordionPanel >
    );
}

