import React, { useState, createContext } from "react";


const TitreContext = createContext();

const TitreProvider = (props) => {
    const [titre, setTitre] = useState({ 'code': '', 'complement': '' })
    // modification du titre principal
    function changeTitre(principal, complement = '') {
        if (principal !== titre['principal'] || complement !== titre['complement'])
            setTitre({ ...titre, 'principal': principal, 'complement': complement })
    }

    return (
        <TitreContext.Provider value={[titre, changeTitre]}>
            {props.children}
        </TitreContext.Provider>
    );
};

export { TitreContext, TitreProvider };