import { construitErreur } from './erreur'
import * as constants from '../constants'
import { Site } from '../classes/site'
import { SiteUpdate } from '../classes/siteUpdate'


export function SaveAllSites(afterUpdate = undefined, onError = undefined) {
    const SitesToSave = new SiteUpdate()
    SitesToSave.content.forEach(unSite => {
        const monSite = JSON.parse(window.localStorage.getItem(constants.localSite + unSite.idSite))
        if (monSite !== null) {
            SaveUnSite(monSite)
                .then(afterUpdate)
                .catch(onError)
        }
    })

}


export function SaveUnSite(site) {
    return new Promise((resolve, reject) => {
        let url = ''
        let method = ''
        let idSite = site.ID_SITE

        if (site.ID_SITE && site.ID_SITE > 0) {
            // modification d'un site
            url = `/api/site/${site.ID_SITE}`
            method = 'PUT'
        } else {
            // ajout d'un nouveau site
            url = `/api/site/`
            method = 'POST'
        }
        fetch(url, {
            method: method,
            body: JSON.stringify(site),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(construitErreur)
            .then((res) => {
                if (res.data) {
                    // dans le cas d'une création, on met à jour idSite avec le nouvel identifiant
                    if (idSite === 0) {
                        if (res.data.ID_SITE) {
                            idSite = res.data.ID_SITE
                        }
                    }
                    const newSite = new Site(res.data)

                    // on met à jour le local storage
                    newSite.updateLocalStorage()

                    //on met à jour les batiments
                    MAJBatiment(idSite, res.data.BATIMENTS)
                        .then(dataBatiment => {
                            newSite.BATIMENTS = dataBatiment || []
                            // on met à jour le local storage
                            newSite.updateLocalStorage()
                        })

                    const ListeSiteToUpd = new SiteUpdate()
                    ListeSiteToUpd.del(newSite.ID_SITE)

                    resolve(res)
                }
            })
            .catch(reject)
    })
}

export function prepare_MAJActivite(newSiteActivite, activiteDuSite) {

    if (!activiteDuSite) { activiteDuSite = [] }

    // on indique qu'il faut supprimer activités
    if (activiteDuSite.length > 0) {
        activiteDuSite.forEach(uneActivite => {

            uneActivite.SUPPR = true
            uneActivite.MODIF = false
        })
    }

    if (newSiteActivite && newSiteActivite.length > 0) {
        newSiteActivite.forEach(unTypeActivite => {

            let index = activiteDuSite.findIndex(uneActivite => uneActivite.IDCHAN_TYPE === parseInt(unTypeActivite.value))
            if (index >= 0) {
                // activité trouvé -> pas de mise à jour
                activiteDuSite[index].SUPPR = false
                activiteDuSite[index].MODIF = false
            } else {
                // activité non trouvée, on doit la créer
                let newActivite = {}
                newActivite.ID_SITE_ACTIVITE = 0
                newActivite.IDCHAN_TYPE = unTypeActivite.value
                newActivite.CODE = unTypeActivite.code
                newActivite.LIBELLE = unTypeActivite.label

                newActivite.SUPPR = false
                newActivite.MODIF = true

                activiteDuSite.push(newActivite)
            }
        })
    }

    return activiteDuSite
}

export function MAJBatiment(IDSiteBat, dataBatiment) {

    return new Promise((resolve, reject) => {
        let url = ''
        let method = ''

        if (dataBatiment) {
            let ListeRequeteBat = []
            dataBatiment.forEach(unBatiment => {
                if (unBatiment.tableData && unBatiment.tableData.delete === true && unBatiment.ID_BATIMENT && unBatiment.ID_BATIMENT > 0) {
                    url = `/api/batiment/${unBatiment.ID_BATIMENT}`
                    method = 'DELETE'
                } else if (unBatiment.tableData && unBatiment.tableData.modif === true) {
                    if (unBatiment.ID_BATIMENT && unBatiment.ID_BATIMENT > 0) {
                        // modification d'un site
                        url = `/api/batiment/${unBatiment.ID_BATIMENT}`
                        method = 'PUT'
                    } else {
                        // ajout d'un nouveau site
                        url = `/api/batiment/`
                        method = 'POST'
                        unBatiment.ID_SITE = IDSiteBat
                    }
                } else {
                    return
                }

                ListeRequeteBat.push(new Promise((ok, pasOk) => {
                    fetch(url, {
                        method: method,
                        body: (method !== 'DELETE') ? JSON.stringify(unBatiment) : '',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(construitErreur)
                        .then((res) => {
                            if (res.data) {
                                if (method === 'DELETE') {
                                    ok({ 'delete': unBatiment })
                                } else if (method === 'POST') {
                                    // dans le cas d'une création, on met à jour ID_BATIMENT avec le nouvel identifiant
                                    if (unBatiment.ID_BATIMENT === 0 || unBatiment.ID_BATIMENT === undefined) {
                                        if (res.data.ID_BATIMENT) {
                                            unBatiment.ID_BATIMENT = res.data.ID_BATIMENT
                                            unBatiment.tableData.modif = false

                                            ok({ 'add': unBatiment })
                                        }
                                    }
                                } else if (method === 'PUT') {
                                    // dans le cas d'une création, on met à jour ID_BATIMENT avec le nouvel identifiant
                                    if (unBatiment.ID_BATIMENT === 0) {
                                        if (res.data.ID_BATIMENT) {
                                            unBatiment.tableData.modif = false

                                            ok({ 'modif': unBatiment })
                                        }
                                    }
                                }

                            }
                        })
                        .catch(pasOk)
                }))
            });

            Promise.all(ListeRequeteBat)
                .then((tabResult) => {
                    tabResult.forEach(unResultat => {
                        if (unResultat.delete) {
                            const dataDelete = [...dataBatiment];
                            const index = dataDelete.findIndex(search => search.tableData.id === unResultat.delete.tableData.id)
                            if (index > -1) {
                                dataDelete.splice(index, 1);
                            }
                            dataBatiment = dataDelete;

                        } else if (unResultat.add) {
                            const dataInsert = [...dataBatiment];
                            const index = dataInsert.findIndex(search => search.tableData.id === unResultat.add.tableData.id)
                            if (index > -1) {
                                dataInsert[index] = unResultat.add;
                            }
                            dataBatiment = dataInsert

                        } else if (unResultat.modif) {
                            const dataUpdate = [...dataBatiment];
                            const index = dataUpdate.findIndex(search => search.tableData.id === unResultat.modif.tableData.id)
                            if (index > -1) {
                                dataUpdate[index] = unResultat.modif;
                            }
                            dataBatiment = dataUpdate
                        }
                    })
                    resolve(dataBatiment)
                })
                .catch(reject)

        }
    })

}
