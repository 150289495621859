import React from 'react';
import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Reponse } from './reponse'
import { Translation } from 'react-i18next'

/**
 * Sert à la gestion des audits dans l'application
 * @class
 * @extends Globale
 */
export class Audit extends Globale {

    constructor(unAudit) {
        super()
        if (unAudit) {
            this.DAT_CRE = unAudit.DAT_CRE || null
            this.DAT_DERN_MODIF = unAudit.DAT_DERN_MODIF
            this.DAT_MAJ = unAudit.DAT_MAJ
            this.EST_DERNIER_ADT = unAudit.EST_DERNIER_ADT
            this.ID_ENTETE = unAudit.ID_ENTETE
            this.ID_SITE = unAudit.ID_SITE
            this.ID_STATUT = unAudit.ID_STATUT
            this.ID_TYPE = unAudit.ID_TYPE
            this.PAYS_LIB = unAudit.PAYS_LIB
            this.SITE_ENTITE = unAudit.SITE_ENTITE
            this.SITE_NOM = unAudit.SITE_NOM
            this.SITE_PAYS = unAudit.SITE_PAYS
            this.SITE_VILLE = unAudit.SITE_VILLE
            this.STAT_CODE = unAudit.STAT_CODE
            this.STAT_LIB = unAudit.STAT_LIB
            this.TYPE_LIB = unAudit.TYPE_LIB

            this.REPONSES = []
            if (unAudit.REPONSES) {
                unAudit.REPONSES.forEach(uneReponse => {
                    let rep = new Reponse(uneReponse)
                    this.REPONSES.push(rep)
                });
            }
        }
    }

    /**
     * Retourne un objet Audit ou false si inexistant dans le local storage
     * @param {int} idEntete - ID de l'audit à chercher
     * @returns {Audit}
     * @static
     */
    static byLocalStorage(idEntete) {
        const unAudit = JSON.parse(window.localStorage.getItem(constants.localAudit + idEntete))
        if (unAudit) {
            return new Audit(unAudit)
        } else {
            return null
        }
    }

    /**
     * stock l'audit courant dans le local storage
     */
    toLocalStorage() {
        window.localStorage.setItem(constants.localAudit + this.ID_ENTETE, JSON.stringify(this))
    }

    /**
     * Récupère un audit depuis le WS
     * @returns {Promise} - promesse 
     */
    get() {
        const IDEntete = this.ID_ENTETE
        return new Promise((resolve, reject) => {
            const url = `/api/audit/${IDEntete}`
            if (navigator.onLine) {
                Audit.getFetch(url, '')
                    .then((res) => {
                        if (res && res.data && Object.keys(res.data).length > 0) {
                            let ADT = new Audit(res.data)
                            resolve(ADT)
                        } else {
                            const err = new Error()
                            err.message = <Translation ns='audit'>{(t) => t('Impossible de charger l\'audit')}</Translation>
                            throw err
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(Audit.byLocalStorage(this.ID_ENTETE))
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                let ADT = Audit.byLocalStorage(IDEntete)
                if (ADT === null) {
                    const err = new Error()
                    err.message = <Translation ns='audit'>{(t) => t('Impossible de charger l\'audit')}</Translation>
                    reject(err)
                } else {
                    resolve(ADT)
                }
            }
        })

    }

    /**
     * retourne un object Reponse correspondant à l'id passé ou undefined
     * @param {int} id - ID_LIGNE à chercher
     * @returns {Reponse}
     */
    getReponse(id) {
        return this.REPONSES.find(search => search.ID_LIGNE === id)
    }

    /**
    * retourne un object Reponse correspondant à l'id passé ou undefined
    * @param {int} id - ID_CONTROLE à chercher
    * @returns {Reponse}
    */
    getReponseByControle(id) {
        const reponse = this.REPONSES.find(search => search.ID_CONTROLE === id)
        if (reponse) {
            const localReponse = reponse.byLocalStorage(this.ID_ENTETE)
            if (localReponse !== null) {
                localReponse.IS_LOCAL_DATA = true
                return localReponse
            } else {
                return reponse
            }
        }
        return
    }


    /**
     * met à jour une réponse dans l'audit
     * @param {Reponse} uneReponse - Objet de type réponse à mettre à jour
     */
    setReponse(uneReponse) {
        const indexControle = this.REPONSES.findIndex(search => search.ID_CONTROLE === uneReponse.ID_CONTROLE)
        if (indexControle >= 0) {
            this.REPONSES[indexControle] = uneReponse
        }
    }

    /**
     * met à jour une réponse dans l'audit
     * @param {Reponse} uneReponse - Objet de type réponse à mettre à jour
     */
    setLocalReponse(uneReponse) {
        uneReponse.toLocalStorage(this.ID_ENTETE)
    }

    isModified() {
        let saveEnCours = JSON.parse(window.localStorage.getItem(constants.saveEnAttenteADT + this.ID_ENTETE))
        if (saveEnCours === undefined || saveEnCours === null || saveEnCours.length === 0) {
            return false
        }
        return true
    }

    /**
     * Créer une nouvelle version d'un audit
     * @returns {Promise} - promesse de duplication
     */
    duplique() {
        const url = `/api/audit/dupliquer/${this.ID_ENTETE}`
        return Audit.postFetch(url)
    }

    /**
     * Lance la sauvegarde de l'objet courant
     * @returns {Promise} - promesse de sauvegarde
     */
    save() {
        return this._update()
    }

    async _saveUnDoc(unDoc, localReponse, maReponse) {
        const indexDocument = localReponse.DOCUMENTS.findIndex(monDoc => monDoc.ID_DOCUMENT === unDoc.ID_DOCUMENT)
        return new Promise((resolve, reject) => {
            unDoc.save()
                .then((monDoc) => {
                    if (monDoc) {
                        localReponse.DOCUMENTS[indexDocument] = unDoc
                        maReponse.DOCUMENTS.push(unDoc)
                        resolve()
                    } else {
                        reject(Error('Document non sauvegardé : ' + unDoc.META_LIBELLE + '.' + unDoc.META_EXTENSION))
                    }
                })
                .catch((e) => {
                    reject(e)
                })
        })
    }

    /**
     * genere la promesse d'update de l'audit et de sauvegarde des nouveaux documents
     * @returns {Promise} - promesse de sauvegarde
     * @private
     */
    async _update() {
        const url = `/api/audit/${this.ID_ENTETE}`
        const donneeASauvegarder = JSON.parse(window.localStorage.getItem(constants.saveEnAttenteADT + this.ID_ENTETE))
        const body = Object.values(donneeASauvegarder)

        return new Promise((resolve, reject) => {
            Audit.putFetch(url, body)
                .then((res) => {
                    //STEP 1 : on récupère la liste des nouveaux documents à traiter pour chaque réponse
                    const localData = JSON.parse(window.localStorage.getItem(constants.saveEnAttenteADT + this.ID_ENTETE))
                    if (res.data) {
                        const NextValue = []
                        res.data.forEach((ligneReponse) => {
                            let maReponse = new Reponse(ligneReponse)
                            let localReponse = new Reponse(localData[maReponse.ID_LIGNE])
                            NextValue.push({ 'localReponse': localReponse, 'maReponse': maReponse })
                        })
                        return NextValue
                    }
                })
                .then(async (precedentValues) => {
                    //STEP 2 : sauvegarde les nouveaux documents l'un après l'autre (await) 

                    for (let index = 0; index < precedentValues.length; index++) {
                        const element = precedentValues[index];
                        const listeDoc = element.localReponse.getNewDocument()
                        for (let i = 0; i < listeDoc.length; i++) {
                            await this._saveUnDoc(listeDoc[i], element.localReponse, element.maReponse).catch(
                                (err) => {
                                    reject(err);
                                }
                            )
                        }

                    }
                    return precedentValues
                })
                .then((precedentValues) => {
                    //STEP 3 : On compare le local et la réponse transmise

                    const localStorageAudit = Audit.byLocalStorage(this.ID_ENTETE)
                    for (let index = 0; index < precedentValues.length; index++) {
                        const element = precedentValues[index];
                        let deleteToNextUpdate = true
                        element.maReponse.compareTo(element.localReponse, deleteToNextUpdate)

                        if (deleteToNextUpdate) {
                            localStorageAudit.setReponse(element.maReponse)
                            element.maReponse.delLocalStorage(this.ID_ENTETE)
                        }
                    }

                    //STEP 4 : On sauvegarde l'audit dans le local storage et on prépare le retour
                    localStorageAudit.toLocalStorage()



                    resolve(localStorageAudit)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }



    /**
     * Récupère la liste des audits
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(params) {
        return new Promise((resolve, reject) => {
            const url = `/api/audit?`
            Audit.getFetch(url, params)
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        Audit.listeToSessionStorage(res.data)
                        resolve(res.data)
                    } else {
                        Audit.listeToSessionStorage([])
                        resolve([])
                    }
                })
                .catch(
                    (err) => {
                        if (isNoInternetError(err)) {
                            resolve(Audit.listeBySessionStorage())
                        } else {
                            reject(err)
                        }
                    }
                )

        })
    }

    /**
     * Sauvegarde la liste des audits en local
     * @static
     * @param {Array} listeAudit - Liste des audits
     */
    static listeToSessionStorage(listeAudit) {
        window.sessionStorage.setItem(constants.listeAudits, JSON.stringify(listeAudit))
    }

    /**
     * Récupère la liste des audits depuis la session
     * @static
     * @returns {Array} - Liste des audits
     */
    static listeBySessionStorage() {
        let listeAudit = JSON.parse(window.sessionStorage.getItem(constants.listeAudits))
        if (listeAudit === null) {
            listeAudit = []
        }
        return listeAudit
    }

    /**
     * Créer un ou plusieurs audits en fonction du tableau passé 
     * @param {[Audit]} ListeAuditToCreate 
     */
    static newAudits(ListeAuditToCreate) {
        const url = `/api/audit/`
        return Audit.postFetch(url, ListeAuditToCreate)

    }

    static getAuditToSave() {
        const tabIdAuditToSave = []
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i)
            if (key.startsWith(constants.saveEnAttenteADT)) {
                const idEntete = key.replace(constants.saveEnAttenteADT, '')
                tabIdAuditToSave.push(idEntete)
            }
        }
        return tabIdAuditToSave
    }
}


