import React, { useState, createContext } from "react";


const MenuContext = createContext();

const MenuProvider = (props) => {
    const [sousMenu, setSousMenu] = useState(undefined)
    return (
        <MenuContext.Provider value={[sousMenu, setSousMenu]}>
            {props.children}
        </MenuContext.Provider>
    );
};

export { MenuContext, MenuProvider };