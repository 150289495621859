import React, { useContext } from 'react'
import { AuditContext } from '../../context/AuditContext'
import TextFied from '../outils/generique/TextFied'
import SelectMono from '../outils/generique/SelectMono'
import Interrupteur from '../outils/generique/Interrupteur'
import RadioButton from '../outils/generique/RadioButton'
import { Attribut } from '../../classes/attribut';
import { Audit } from '../../classes/audit'


/**
 * Composant FormAttribut
 * Ce composant affiche les différents types d'attributs
 * @param {{
 *          idControle : Integer, 
 *          reponse : Reponse, 
 *          attribut : RepAttribut
 *          setControle : function
 *        }} props
 */
export default function FormAttribut({ idControle, idSecteur, idFamille, attribut, refreshPastille }) {

    // valeur pour la gestion des interrupteurs
    const valeur = { 'valeurNon': {}, 'valeurOui': {} }

    const { onChangeControle, readOnly, monAudit } = useContext(AuditContext)
    const reponseAttribut = Audit.byLocalStorage(monAudit.ID_ENTETE).getReponseByControle(idControle).getAttributByIDAttribut(attribut.ID_ATTRIBUT)

    /**
     * Controle la saisie d'une valeur dans un attribut
     * @param {*} valeurSaisie - Valeur saisie par l'utilisateur 
     */
    function controleSaisie(valeurSaisie) {
        let erreurCtrl = ''

        return erreurCtrl
    }

    function onChangeValue(event, uneErreur) {
        let controleTxt = ''

        // controle de la saisie
        controleTxt = controleSaisie(event.target.value)

        // si controle OK, on met à jour
        if (controleTxt === '') {
            onChangeControle('attribut', event)
            refreshPastille()
        }
        return controleTxt
    }

    /**
     * Renvoi le render associé à l'attribut
     */
    function render() {
        if (reponseAttribut) {

            if (attribut.ATT_CODE.startsWith('ETAT')) {
                return (
                    <RadioButton
                        key={'key_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        name={idFamille + '_' + idSecteur + '_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        disabled={readOnly}
                        value={reponseAttribut.ID_VALEUR_ATT}
                        onChange={onChangeValue}
                        choix={attribut.VALEURS ?
                            attribut.VALEURS.map(valeur => (
                                {
                                    key: idControle + '_' + attribut.ID_ATTRIBUT + '_' + valeur.ID_VALEUR_ATT,
                                    libelle: valeur.VAL_ATT_LIB,
                                    value: (valeur.ID_VALEUR_ATT === null) ? undefined : valeur.ID_VALEUR_ATT,
                                    style: Attribut.getStyleEtat(valeur.VAL_ATT_CODE),
                                    name: idFamille + '_' + idSecteur + '_' + idControle + '_' + attribut.ID_ATTRIBUT + '_' + valeur.ID_VALEUR_ATT
                                }

                            ))
                            :
                            []
                        }

                    />
                )
            } else if (attribut.ATT_CODE.startsWith('NOMBRE') || attribut.ATT_CODE.startsWith('DATE')) {
                let minVal
                let maxVal
                let valeurAfficher

                if (attribut.VALEURS) {
                    // parcours des valeurs pour récupérer le min - max - valeur à afficher
                    attribut.VALEURS.forEach(valeur => {
                        let intVal
                        if (valeur.VAL_ATT_LIB === '-') {
                            intVal = 0
                        } else if (isFinite(valeur.VAL_ATT_LIB)) {
                            intVal = parseInt(valeur.VAL_ATT_LIB)
                        }
                        if (intVal) {
                            if (!minVal || minVal > intVal) {
                                minVal = intVal
                            }
                            if (!maxVal || maxVal < intVal) {
                                maxVal = intVal
                            }
                        }
                        if (valeur.ID_VALEUR_ATT === reponseAttribut.ID_VALEUR_ATT) {
                            valeurAfficher = valeur.VAL_ATT_LIB
                        }
                    })

                    return (
                        <TextFied
                            type={'number'}
                            key={'key_' + idControle + '_' + attribut.ID_ATTRIBUT}
                            name={idFamille + '_' + idSecteur + '_' + idControle + '_' + attribut.ID_ATTRIBUT}
                            libelle={attribut.ATT_LIB}
                            disabled={readOnly}
                            onChange={onChangeValue}
                            value={valeurAfficher}
                            min={minVal}
                            max={maxVal}
                        />
                    )
                }

            } else if (attribut.ATT_CODE.startsWith('TYPE')) {
                return (
                    < SelectMono
                        key={'key_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        name={idFamille + '_' + idSecteur + '_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        libelle={attribut.ATT_LIB}
                        onChange={onChangeValue}
                        disabled={readOnly}
                        value={reponseAttribut.ID_VALEUR_ATT}
                        mesChoix={
                            attribut.VALEURS ?
                                attribut.VALEURS.map(valeur => ({
                                    value: (valeur.ID_VALEUR_ATT === null) ? undefined : valeur.ID_VALEUR_ATT,
                                    label: valeur.VAL_ATT_LIB,
                                    code: idControle + '_' + attribut.ID_ATTRIBUT + '_' + valeur.ID_VALEUR_ATT,
                                    key: idControle + '_' + attribut.ID_ATTRIBUT + '_' + valeur.ID_VALEUR_ATT,
                                }))
                                :
                                []
                        }
                    />
                )
            } else if (attribut.ATT_CODE.startsWith('OUINON')) {
                let valeurOui = attribut.VALEURS.find(search => search.VAL_ATT_CODE === 'OUI')
                let valeurNon = attribut.VALEURS.find(search => search.VAL_ATT_CODE === 'NON')

                valeur['valeurOui'] = valeurOui
                valeur['valeurNon'] = valeurNon

                return (
                    < Interrupteur
                        key={'key_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        name={idFamille + '_' + idSecteur + '_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        disabled={readOnly}
                        libelle={attribut.ATT_LIB}
                        onChange={onChangeValue}
                        libelleGauche={valeurNon.VAL_ATT_LIB}
                        libelleDroite={valeurOui.VAL_ATT_LIB}
                        value={valeurOui.ID_VALEUR_ATT === reponseAttribut.ID_VALEUR_ATT ? true : false}
                    />
                )

            } else {
                return (
                    < TextFied
                        key={'key_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        disabled={readOnly}
                        value={reponseAttribut.LIG_VALEUR}
                        libelle={attribut.ATT_LIB}
                        name={idFamille + '_' + idSecteur + '_' + idControle + '_' + attribut.ID_ATTRIBUT}
                        onChange={onChangeValue}
                    />
                )
            }
        }
        return <></>
    }

    return (<>
        {render()}
    </>)
}

