import React, { useState, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanelActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import FilterIcon from '../../images/icons/filter'
import FilterRazIcon from '../../images/icons/filterRaz'
import ModSite from '../outils/modele/Site';
import TextFieldMultiTag from '../outils/generique/TextFielMultiTag';
import ModPays from '../outils/modele/Pays';
import ModTypeAudit from '../outils/modele/TypeAudit';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    details: {
        alignItems: 'center',
    },
    actions: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    columnAction: {
        flexBasis: '100%',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    button: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    content: {
        width: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
}));

/**
 * Composant Filtre
 * Ce composant affiche le filtre des audits
 * @param {{ setOptions: function, options:Object, razOptions:function }} props 
 */
export default function Filtre({ setOptions, options, razOptions }) {
    const classes = useStyles();
    const [t] = useTranslation(['audit', 'commun'])
    const UserCtx = useContext(UserContext)

    const [filtreOptions, setFiltre] = useState(options)

    /**
    * Ajoute un pays au filtre
    * @param {Event} event - Event de selection d'un pays
    */
    function handleChange(event, item, value = undefined) {
        let filtre = []
        if (value) {
            filtre = Array.from(value, (valeur, index) => ({ 'value': valeur, 'label': valeur, 'code': '', 'key': index }))
        } else if (event) {
            event.forEach(option => {
                filtre.push(option)
            })
        }
        setFiltre({ ...filtreOptions, [item]: filtre })
    }

    /**
     * validation du filtre
     */
    const valideFiltre = () => {
        setOptions(filtreOptions)
    }

    /**
     * réinitialisation du filtre
     */
    const AnnuleFiltre = () => {
        setFiltre(razOptions())
    }

    /**
     * Vérifie s'il existe ou non un filtre
     */
    const existeFiltre = () => {
        // si au moins 1 option de saisie
        const retour = (filtreOptions && Object.keys(filtreOptions).length > 0) ? true : false
        return retour
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // RENDERS
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className={classes.content} >
            <ExpansionPanel defaultExpanded={existeFiltre()}>
                {/* partie ouverture / fermeture  */}
                <ExpansionPanelSummary
                    className={classes.noPaddingLeft}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"                >


                    <Button aria-label={t('audit:filtre')} className={classes.button} variant="outlined" color="primary" startIcon={<FilterIcon />}>
                        {t('audit:filtre')}
                    </Button>
                    <Button
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        disabled={!UserCtx.droits['DroitModuleAudit'].crea}
                        aria-label={t('commun:ajouter')}
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<Add />}
                        component={Link}
                        to="/audit/new">
                        {t('commun:ajouter')}
                    </Button>


                </ExpansionPanelSummary>

                <Divider />

                {/* partie filtre */}
                <ExpansionPanelDetails className={classes.details}>
                    <div className={classes.columnAction}>

                        <ModSite multi={true} onChange={(e) => handleChange(e, 'idSite')} value={filtreOptions['idSite']} />
                        <TextFieldMultiTag value={Array.from(filtreOptions['ville'] || [], element => element.value)} disabled={false} name={'SEL_ville'} libelle={t('site:researchCity')} type="text" onChange={(e, value) => handleChange(e, 'ville', value)} helperText={t('site:filtreVille')} />
                        <ModPays multi={true} onChange={(e) => handleChange(e, 'idPays')} value={filtreOptions['idPays']} />
                        <ModTypeAudit multi={true} onChange={(e) => handleChange(e, 'idType')} value={filtreOptions['idType']} />
                    </div>
                </ExpansionPanelDetails>

                <Divider />

                {/* partie action */}
                <ExpansionPanelActions className={classes.actions}>
                    <div className={classes.columnAction} >
                        <Button aria-label={t('commun:search')} className={classes.button} variant="outlined" color="primary" startIcon={<FilterIcon />}
                            onClick={valideFiltre}>{t('commun:search')}
                        </Button>
                        <Button aria-label={t('commun:cancel')} variant="outlined" className={classes.button} color="primary" startIcon={<FilterRazIcon />}
                            onClick={AnnuleFiltre}>
                            {t('commun:cancel')}
                        </Button>
                    </div>
                </ExpansionPanelActions>
            </ExpansionPanel >
        </ div >
    );
}
