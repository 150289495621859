
import React from 'react'
import TitreBox from './titreBox'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    paperBox: {
        paddingBottom: theme.spacing(1),
    },
    girdBox: {
        marginBottom: theme.spacing(1),
    },
}));


Box.defaultProps = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    containerMode: false
};
///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Box(props) {
    const classes = useStyles();

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} className={classes.girdBox}>
            <Paper className={classes.paperBox}>
                <TitreBox titre={props.titre} icone={props.icone} />
                <Grid
                    container={props.containerChlid}
                    justify={props.containerChlid ? "space-between" : undefined}
                    spacing={props.containerChlid ? 1 : undefined}
                >
                    <>
                        {props.children}
                    </>
                </Grid>
            </Paper>
        </Grid >
    )
}
