import React, { useState } from 'react';
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { useTranslation } from 'react-i18next';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
    },
    formControle: {
        display: "block",
        margin: "5px",
    },
    input: {
        width: "100%",
    },
    helper: {
        fontStyle: 'italic',
        width: "100%",
    }
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      PROTOTYPE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
TextField.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    libelle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    min: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    max: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.any,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
}

TextField.defaultProps = {
    // value: '',
    disabled: false,
    type: 'text',
    min: '',
    max: '',
    helperText: '',
    errorText: '',
    maxLength: -1,
    required: false,
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function TextField({ value, name, libelle, type, disabled, min, max, onChange, helperText, errorText, maxLength, required }) {

    const classes = useStyles()
    const [erreur, setErreur] = useState(errorText)
    const [t] = useTranslation(['commun'])
    const [localValue, setLocalValue] = useState(value)

    // fonction appelée à chaque saisie
    const handleChange = (event) => {
        let valeurSaisie = event.target.value
        let uneErreur = ''
        if (required && valeurSaisie === '') {
            uneErreur = t('commun:helper')
        } else if (type === 'number') {
            switch (true) {
                case (min !== '' && max !== ''):
                    if (valeurSaisie !== '' && (valeurSaisie < min || valeurSaisie > max)) {
                        uneErreur = t('commun:helperBetween', { min: min, max: max })
                    }
                    break;
                case (min !== ''):
                    if (valeurSaisie !== '' && valeurSaisie < min) {
                        uneErreur = t('commun:helperSup', { val: min })
                    }
                    break;
                case (max !== ''):
                    if (valeurSaisie !== '' && valeurSaisie > max) {
                        uneErreur = t('commun:helperInf', { val: max })
                    }
                    break;
                default:
                    break;
            }
        }


        setLocalValue(valeurSaisie)

        // exécute le controle demandé par la props
        if (onChange) {
            uneErreur = onChange(event, uneErreur)
        }

        if (erreur !== uneErreur) {
            // initialise l'erreur selon le retour de la props
            setErreur(uneErreur)
        }
    };

    // retourne vrai si une erreur existe sinon faux
    function estEnErreur() {
        return (erreur && erreur !== '') ? true : false
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <FormGroup key={'ctrl_id_' + name} className={classes.root} >
            <FormControl error={estEnErreur()} disabled={disabled} required={required} className={classes.formControle}>
                <InputLabel htmlFor={'input_name_' + name + '_label'}>
                    {libelle}
                </InputLabel>

                <Input
                    className={classes.input}
                    type={type}
                    name={name}
                    value={localValue}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    id={'input_name_' + name + '_label'}
                    aria-describedby={'input_name_' + name + '_helper'}
                    inputProps={{
                        maxLength: maxLength
                    }}
                />
                {
                    (estEnErreur() || helperText !== '') &&
                    < FormHelperText
                        id={'input_name_' + name + '_helper'}
                        className={classes.helper}
                    >

                        {
                            estEnErreur() ?
                                erreur
                                :
                                helperText
                        }

                    </FormHelperText>
                }
            </FormControl >
        </FormGroup >
    );
}
