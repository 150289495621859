import React, { useState, useEffect, useContext } from 'react';
import { Redirect, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { estVisible } from '../../services/stickyTableHeader'

////////////////////////////////////////////////////////////////////////
//                      import REALESTATE
import { UserContext } from '../../context/UserContext'
import { ListeContext } from '../../context/ListeContextSite'
import { TitreContext } from '../../context/TitreContext'
import { useAfficheErreur } from '../../services/erreur'

import Filtre from './siteListeFiltre'
import Chargement from '../outils/utilitaires/chargement'
import NoData from '../outils/utilitaires/noData'
import { Site } from '../../classes/site';


////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      STYLE
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
    ...theme.table,
    bouton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    hover: {},
    selected: {}
}));

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//      EXPORT
///////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SiteListe() {
    let activeEventScroll = true

    const UserCtx = useContext(UserContext)
    const [, setTitre] = useContext(TitreContext)

    const [filtre, setFiltre] = useContext(ListeContext)
    const [t] = useTranslation(['site', 'commun'])

    const [site, setSite] = useState()

    const [redirect, setRedirect] = useState(null)
    const [loading, setLoading] = useState(false)

    const [renderFloatHeader, setRenderFloatHeader] = useState(false)
    const [leftScroll, setLeftScroll] = useState(0)
    const [orderBy, setOrderBy] = useState('SITE_ENTITE')
    const [direction, setDirection] = useState('asc')

    const classes = useStyles();

    const [afficheErreur] = useAfficheErreur()

    useEffect(() => {
        setTitre(t('menu:site'))
    })

    // chargement de la liste des sites
    useEffect(() => {

        // préparation du filtre avant appel API : on transforme les listes sous forme de chaine
        function prepareFiltre() {
            let rechString = {}
            for (const [key, value] of Object.entries(filtre)) {
                rechString[key] = value.map(unElement => unElement.value).join(',')
            }
            return (rechString)
        }

        const getSite = () => {
            setLoading(true)
            let param = prepareFiltre() || {}
            param.lang = UserCtx.langue
            Site.getListe(param)
                .then((listeSite) => {
                    setSite(listeSite)
                })
                .catch(afficheErreur)
                .finally(() => {
                    setLoading(false)
                })
        }
        getSite()

    }, [UserCtx.User, UserCtx.langue, afficheErreur, filtre])


    // fonction de Raz des options du filtre
    function razOptions() {
        setFiltre({})

        return {}
    }

    // fonction qui mémorise le filtre site
    function memoriseOptions(newOptions) {
        setFiltre(newOptions)
    }



    // function appelée à chaque modification du scroll de la table
    function toggleScroll() {
        if (estVisible('positionColumn1')) {
            setRenderFloatHeader(false)
        } else {
            setRenderFloatHeader(true)
        }
    }
    // Change la position du header floatant
    function setPositionLeft() {
        let floatHeader = document.getElementById('floatHeader')
        let tableContent = document.getElementById('tableContent')
        let scrollLeft = tableContent.scrollLeft
        let left = 0 - scrollLeft
        setLeftScroll(left)
        if (floatHeader) {
            floatHeader.style.left = left + 'px'
        }

    }

    // activation de l'évènement d'écoute du scrolling
    if (activeEventScroll === true) {
        document.addEventListener("scroll", function (e) { toggleScroll() })
        activeEventScroll = false
    }

    /**
     * Fonction de tri du tableau
     * @param {Object} element1 - Premier élément à comparer
     * @param {Object} element2 - Deuxième élément à comparer
     */
    function sortElement(element1, element2) {

        const el1 = getElementValue(orderBy, element1)
        const el2 = getElementValue(orderBy, element2)
        if (el1 !== undefined && el2 !== undefined) {
            if (direction === 'asc') {
                return el1.localeCompare(el2, undefined, { 'numeric': true })
            } else {
                return el2.localeCompare(el1, undefined, { 'numeric': true })
            }
        }
        return 0
    }

    /**
     * Fonction permettant de recupérer la valeur de l'element sur lequel porte le filtre
     * @param {string} orderBy - Correspond au nom de l'élément sur lequel on filtre
     * @param {Object} parent - Correspond à l'objet sur lequel appliquer le filtre
     */
    function getElementValue(orderBy, parent) {

        if (Array.isArray(parent[orderBy])) {
            if (typeof parent[orderBy][0] === 'object') {
                let liste = parent[orderBy]
                    .sort((a, b) => {
                        if (a.ORDRE === undefined) { a.ORDRE = 0 }
                        if (b.ORDRE === undefined) { b.ORDRE = 0 }
                        return parseInt(a.ORDRE) - parseInt(b.ORDRE)
                    })
                    .map(x => x.LIBELLE)
                    .join(', ')
                return liste + ''
            } else if (typeof parent[orderBy][0] === 'undefined') {
                return ''
            } else {
                let liste = parent[orderBy]
                    .join(', ')
                return liste + ''
            }


        }
        const index = orderBy.indexOf('.')
        if (index >= 0) {
            return getElementValue(orderBy.slice(index + 1), parent[orderBy.slice(0, index)])
        }
        return parent[orderBy] + ''
    }

    function defineSort(type) {
        if (type === orderBy) {
            setDirection(direction === 'asc' ? 'desc' : 'asc')
        } else {
            setOrderBy(type)
            setDirection('asc')
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // RENDER
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // render redirection vers le détail du site
    function renderRedirect() {
        if (redirect && redirect.ID_SITE && redirect.ID_SITE > 0) {
            return <Redirect to={"/site/" + redirect.ID_SITE} push />
        }
    }


    // render entêtes du tableau
    function renderTableHead() {
        return (
            <TableHead>
                <TableRow id="headerTable" >
                    <TableCell id="positionColumn1" data-type="SITE_ENTITE" className={classes.cellTh} sortDirection={orderBy === 'SITE_ENTITE' ? direction : 'asc'}>
                        <TableSortLabel
                            active={orderBy === 'SITE_ENTITE'}
                            direction={orderBy === 'SITE_ENTITE' ? direction : 'asc'}
                            onClick={() => { defineSort('SITE_ENTITE') }}
                        >
                            {t('site:siteEntite')}
                        </TableSortLabel>

                    </TableCell>
                    <TableCell data-type="SITE_NOM" className={classes.cellTh} sortDirection={orderBy === 'SITE_NOM' ? direction : 'asc'}>
                        <TableSortLabel
                            active={orderBy === 'SITE_NOM'}
                            direction={orderBy === 'SITE_NOM' ? direction : 'asc'}
                            onClick={() => { defineSort('SITE_NOM') }}
                        >
                            {t('site:siteName')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell data-type="SITE_VILLE" className={classes.cellTh} sortDirection={orderBy === 'SITE_VILLE' ? direction : 'asc'}>
                        <TableSortLabel
                            active={orderBy === 'SITE_VILLE'}
                            direction={orderBy === 'SITE_VILLE' ? direction : 'asc'}
                            onClick={() => { defineSort('SITE_VILLE') }}
                        >
                            {t('site:siteVille')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell data-type="PAYS.LIBELLE_PAYS" className={classes.cellTh} sortDirection={orderBy === 'LIBELLE_PAYS' ? direction : 'asc'}>
                        <TableSortLabel
                            active={orderBy === 'PAYS'}
                            direction={orderBy === 'PAYS' ? direction : 'asc'}
                            onClick={() => { defineSort('PAYS.LIBELLE_PAYS') }}
                        >
                            {t('site:sitePays')}
                        </TableSortLabel>
                    </TableCell>

                    <TableCell data-type="ACTIVITES" className={classes.cellTh} sortDirection={orderBy === 'ACTIVITES' ? direction : 'asc'}>
                        <TableSortLabel
                            active={orderBy === 'ACTIVITES'}
                            direction={orderBy === 'ACTIVITES' ? direction : 'asc'}
                            onClick={() => { defineSort('ACTIVITES') }}
                        >
                            {t('site:siteActivite')}
                        </TableSortLabel>
                    </TableCell>

                </TableRow>
            </TableHead>
        )
    }

    function renderTableFloatHead() {
        let headerBase = document.getElementById('headerTable')
        let subHeader = []
        headerBase.childNodes.forEach(unTH => {
            subHeader.push(
                <span className={unTH.className} style={{ 'width': unTH.offsetWidth }} key={'key_' + unTH.textContent}>
                    {unTH.dataset.type ?
                        <TableSortLabel
                            active={orderBy === unTH.dataset.type}
                            direction={orderBy === unTH.dataset.type ? direction : 'asc'}
                            onClick={() => { defineSort(unTH.dataset.type) }}
                        >
                            {unTH.textContent}
                        </TableSortLabel>
                        :
                        unTH.textContent
                    }
                </span >
            )
        })
        return (
            <div className={classes.HeaderFloat} id='floatHeader' style={{ 'left': leftScroll === 0 ? 'auto' : leftScroll + 'px' }}>
                {subHeader}
            </div>
        )

    }
    // render données
    function renderTableRow() {
        return (
            site
                .sort(sortElement)
                .map((unSite, index) => (
                    <TableRow
                        key={unSite.ID_SITE}
                        hover
                        onClick={() => setRedirect(unSite)}
                        classes={{ hover: classes.hover, selected: classes.selected }}
                        className={(index % 2 === 0) ? classes.rowPaire : classes.rowImpaire}
                    >
                        <TableCell className={classes.cell}>{unSite.SITE_ENTITE}</TableCell>
                        <TableCell className={classes.cell}>{unSite.SITE_NOM}</TableCell>
                        <TableCell className={classes.cell}>{unSite.SITE_VILLE}</TableCell>
                        <TableCell className={classes.cell}>{unSite.PAYS.LIBELLE_PAYS}</TableCell>
                        <TableCell className={classes.cell}>{Site.getListeActivite(unSite.ACTIVITES)}</TableCell>
                    </TableRow>
                ))
        )
    }

    // render structure du tableau
    function renderTable() {
        if (site) {
            if (site.length > 0) {
                return (
                    <TableContainer className={classes.content} id="tableContent" onScroll={setPositionLeft}>
                        {renderFloatHeader && renderTableFloatHead()}
                        <Table>
                            {renderTableHead()}
                            <TableBody>
                                {renderTableRow()}
                            </ TableBody >
                        </Table >
                    </TableContainer >
                )

            } else {
                return (<NoData />)
            }
        }
        if (loading === true) {
            return (<Chargement active={true} />)
        }
    }

    return (
        <React.Fragment>
            <Filtre setOptions={memoriseOptions} razOptions={razOptions} options={filtre} />
            {/* Masquer le bouton pour l'instant mantis : 1315 */}
            {false &&
                <Button disabled={!UserCtx.droits['DroitModuleSite'].crea} aria-label={t('commun:ajouter')} className={classes.bouton} variant="outlined" color="primary" component={NavLink} to={"/site/0"} startIcon={<AddIcon />}>{t('commun:ajouter')}</Button>
            }

            {renderRedirect()}
            {renderTable()}
        </React.Fragment>
    )
}
