import * as constants from '../constants'
import { Globale } from './globale'


/**
 * Sert à la gestion du localStorage pilotant la sauvegarde des audits dans l'application
 * @class
 * @extends Globale
 */
export class ControleUpdate extends Globale {
    constructor() {
        super()
        this.content = JSON.parse(window.localStorage.getItem(constants.LocalAdtToUPD))
        if (this.content === null) {
            this.content = []
        }
    }

    /**
     * Retourne la liste des controles à sauvegarder pour un audit
     * @param {int} idAudit - idEntete de l'audit cherché 
     * @returns {Array} - tableau de controle
     */
    getControleIDByAudit(idAudit) {
        let unAudit = this.content.find(unAuditModif => parseInt(unAuditModif.ID_ENTETE) === parseInt(idAudit))
        if (unAudit && unAudit.CONTROLES_ID && unAudit.CONTROLES_ID.length > 0) {
            return unAudit.CONTROLES_ID
        }
        return []
    }

    /**
     * Supprime un controle de la liste des controles à sauvegardé pour un audit
     * @param {int} idAudit - idEntete de l'audit cherché 
     * @param {int} idControle - idControle du controle cherché
     */
    dropControleID(idAudit, idControle) {
        const k = this.content.findIndex(search => parseInt(search.ID_ENTETE) === parseInt(idAudit))
        if (k >= 0) {
            const l = this.content[k].CONTROLES_ID.indexOf(idControle)
            if (l >= 0) {
                this.content[k].CONTROLES_ID.splice(l, 1)
            }
            if (this.content[k].CONTROLES_ID.length <= 0) {
                this.content.splice(k, 1)
            }
        }

    }

    /**
     * Ajout un controle à la liste des controles à sauvegardé pour un audit
     * @param {int} idAudit - idEntete de l'audit concerné 
     * @param {int} idControle - idControle du controle à sauvegarder
     */
    addControleID(idAudit, idControle) {
        const k = this.content.findIndex(search => parseInt(search.ID_ENTETE) === parseInt(idAudit))
        if (k >= 0) {
            if (this.content[k].CONTROLES_ID.indexOf(idControle) === -1) {
                this.content[k].CONTROLES_ID.push(idControle)
            }
        } else {
            this.content.push({ 'ID_ENTETE': idAudit, 'CONTROLES_ID': [idControle] })
        }
    }

    /**
     * Sauvegarde la liste des élèments à sauvegardé dans le localStorage
     */
    toLocalStorage() {
        if (this.content.length > 0) {
            window.localStorage.setItem(constants.LocalAdtToUPD, JSON.stringify(this.content))
        } else {
            window.localStorage.removeItem(constants.LocalAdtToUPD)
        }
    }

    static NettoieLocalStorage() {
        const LocalData = new ControleUpdate()
        let indexToRemove = []
        LocalData.content.forEach((uneData, index) => {
            if (uneData.CONTROLES_ID && uneData.CONTROLES_ID.length <= 0) {
                indexToRemove.push(index)
                return;
            }
            const localAdt = JSON.parse(window.localStorage.getItem(constants.localAudit + uneData.ID_ENTETE))
            if (localAdt && localAdt === null) {
                indexToRemove.push(index)
                return;
            }
        })
        for (let i = indexToRemove.length; i > 0; i--) {
            LocalData.content.splice(indexToRemove[i], 1)
        }
        LocalData.toLocalStorage()
    }
}