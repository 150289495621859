import * as constants from '../constants'
import { isNoInternetError } from '../services/erreur'
import { Globale } from './globale'
import { Referentiel } from './referentiel'

/**
 * Sert à la gestion des Sites Candidats dans l'application
 * @class
 * @extends Globale
 */
export class Pays extends Globale {

    constructor(unPays = {}) {
        super()
        this.ID_PAYS = unPays.ID_PAYS
        this.LIBELLE_PAYS = unPays.LIBELLE_PAYS
        this.CODE_PAYS = unPays.CODE_PAYS
    }

    static getRefName() {
        return 'Pays'
    }

    /**
     * Retourne un objet SiteCandidat ou false si inexistant dans le local storage
     * @returns {[Pays]} - Tableau de Pays
     * @static
     */
    static byLocalStorage() {
        let ListePays = JSON.parse(window.localStorage.getItem(constants.localPaysListe))
        if (ListePays === null) { ListePays = [] }
        return ListePays
    }

    /**
     * stock le site candidat courant dans le local storage
     */
    static toLocalStorage(ListePays) {
        if (ListePays) {
            window.localStorage.setItem(constants.localPaysListe, JSON.stringify(ListePays))
        }
    }

    /**
     * Récupère un statut depuis le WS
     * @returns {Promise} - promesse 
     */
    static getByID(id) {
        const unPays = this.byLocalStorage().find(search => search.ID_PAYS === id)

        if (unPays) {
            return new Pays(unPays)
        } else {
            return new Pays()
        }
    }


    /**
     * Récupère la liste des sites candidats
     * @static
     * @param {Object} params - Filtre de la requete 
     * @returns {Promise} - promesse de réupération de la liste
     */
    static getListe(param = {}, force = false) {
        return new Promise((resolve, reject) => {
            const UserInfo = JSON.parse(window.localStorage.getItem(constants.infUser))
            let params = {}
            params = param
            params.lang = UserInfo.lang || process.env.REACT_APP_DEFAULT_LANG

            const url = `/api/referentiel/pays`
            if (force || this.byLocalStorage().length === 0) {
                this.getFetch(url, params)
                    .then((res) => {
                        if (res && res.data) {

                            this.toLocalStorage(res.data)

                            const ref = new Referentiel()
                            ref.maj(Pays.getRefName())

                            resolve(res.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(
                        (err) => {
                            if (isNoInternetError(err)) {
                                resolve(this.byLocalStorage())
                            } else {
                                reject(err)
                            }
                        }
                    )
            } else {
                resolve(this.byLocalStorage())
            }
        })
    }

}


