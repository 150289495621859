import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next'
import LogoOrpea from '../../images/logo_orpea.png'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden'
import PC_tuto_1 from './images/PC_tuto_1.png'
import PC_tuto_2 from './images/PC_tuto_2.png'
import PC_tuto_3 from './images/PC_tuto_3.png'
import Phone_tuto_1 from './images/phone_tuto_1.jpg'
import Phone_tuto_2 from './images/phone_tuto_2.jpg'
import Phone_tuto_3 from './images/phone_tuto_3.jpg'
import Phone_tuto_4 from './images/phone_tuto_4.jpg'
import Phone_tuto_5 from './images/phone_tuto_5.jpg'
import Phone_tuto_6 from './images/phone_tuto_6.jpg'


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.secondary,
        minHeight: '100vh',
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    entete: {
        background: theme.palette.primary.main,
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(6),
        textAlign: 'center',
        height: 'max-content',
        width: "100%",
    },
    contenu: {
        marginBottom: theme.spacing(2),
    },
    logo: {
        width: "75%",
        maxWidth: "400px",
    },
}));

/**
 * Composant NoCookies
 * Affiche le mode d'emploi pour activer les cookies
 */
export default function NoCookies() {
    const classes = useStyles();
    const [t] = useTranslation(['noCookies'])

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    //      RENDER
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (

        <Grid container justify="flex-start" alignItems="stretch" direction="column" className={classes.root}>
            <Grid item className={classes.entete}>
                <img alt="Logo" src={LogoOrpea} className={classes.logo} />
            </Grid>
            <Grid container justify="center" alignItems="flex-start">
                <Grid item xs={11} sm={9} md={6} lg={3} className={classes.contenu}  >
                    <Paper className={classes.paper} align="center" >
                        <Typography variant="h4" component="h4" >{t('noCookies:Cookies')}</Typography>
                        <Typography variant="body1" gutterBottom>
                            {t('noCookies:Realestate Wep App a besoin des cookies pour fonctionner.')}
                            {t('noCookies:Pour les activer suivez le petit tuto ci dessous.')}
                        </Typography>
                        <Hidden smDown>
                            <Typography variant="h5" component="h5" >{t('noCookies:PC')}</Typography>
                            <img src={PC_tuto_1} alt="Step 1" width="100%" /> <br /><br />
                            <img src={PC_tuto_2} alt="Step 2" width="100%" /> <br /><br />
                            <img src={PC_tuto_3} alt="Step 3" width="100%" /> <br /><br />
                        </Hidden>
                        <Hidden mdUp>
                            <Typography variant="h5" component="h5" >{t('noCookies:Smartphone et tablette')}</Typography>
                            <img src={Phone_tuto_1} alt="Step 1" width="100%" /> <br /><br />
                            <img src={Phone_tuto_2} alt="Step 2" width="100%" /> <br /><br />
                            <img src={Phone_tuto_3} alt="Step 3" width="100%" /> <br /><br />
                            <img src={Phone_tuto_4} alt="Step 4" width="100%" /> <br /><br />
                            <img src={Phone_tuto_5} alt="Step 5" width="100%" /> <br /><br />
                            <img src={Phone_tuto_6} alt="Step 6" width="100%" /> <br /><br />
                        </Hidden>
                    </Paper >
                </Grid>
            </Grid>
        </Grid >

    )

}
