import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as constants from '../../constants'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Description from '@material-ui/icons/Description';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/core/FormLabel'
import { AuditContext } from '../../context/AuditContext'
import { UserContext } from '../../context/UserContext'
import { DocumentADT } from '../../classes/documentADT'
import db from '../../services/db'
import FormDocument from './auditFormDocument'
import Box from '../outils/utilitaires/box'
// import useTraceUpdate from '../outils/utilitaires/useTraceUpdate';
import { Audit } from '../../classes/audit';




const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    backdropImg: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
    }
}));

/**
 * Composant FormDocumentListe
 * Ce composant affiche un bloc contenant la liste des documents d'un controle
 * @param {{reponseControle : Reponse }}
 */
export default function FormDocumentListe({ idFamille, idSecteur, idControle, refreshPastille }) {
    const classes = useStyles();
    const { monAudit, onChangeControle, refreshDoc } = useContext(AuditContext)
    const UserCtx = useContext(UserContext)
    const maReponseControle = Audit.byLocalStorage(monAudit.ID_ENTETE).getReponseByControle(idControle)
    const [documents, setDocuments] = useState(maReponseControle.DOCUMENTS || [])
    const [newID, setNew] = useState(-1)

    const { enqueueSnackbar } = useSnackbar();
    const [t] = useTranslation(['audit', 'commun', 'document'])

    const mimeTypeRef = require('./mime-type.json')
    const Droit = UserCtx.droits['DroitModuleGED']
    let mimeTypeListe = ""

    // useTraceUpdate({ refreshDoc }, FormDocumentListe.name)

    useEffect(() => {
        setDocuments(Audit.byLocalStorage(monAudit.ID_ENTETE).getReponseByControle(idControle).DOCUMENTS || [])
        refreshPastille()
    }, [idControle, monAudit.ID_ENTETE, refreshDoc, refreshPastille])

    /**
     * Chargement des documents locaux (pas encore sauvegardé en bdd)
     */
    useEffect(() => {

        db.table('documents')
            .where('referenceDocument')
            .startsWith(constants.localDoc + monAudit.ID_ENTETE + '_' + maReponseControle.ID_LIGNE)
            .toArray((tabDoc => {
                let minIDDocument = 0
                tabDoc.forEach((uneLigne) => {
                    const unDoc = new DocumentADT(uneLigne)
                    if (-1 === maReponseControle.DOCUMENTS.findIndex(unElement => unElement.ID_DOCUMENT === unDoc.ID_DOCUMENT)) {
                        setLocal(unDoc)
                        unDoc.blob = uneLigne.blob
                        updateDocument(unDoc)
                    }
                    if (minIDDocument > parseInt(uneLigne.ID_DOCUMENT)) {
                        minIDDocument = parseInt(uneLigne.ID_DOCUMENT)
                    }
                })

                minIDDocument--
                setNew(minIDDocument)
            }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monAudit.ID_ENTETE])



    /**
     * Supprime un document via son ID
     * @param {Integer} idDoc - Id du document à supprimer
     */
    function deleteDocument(idDoc) {
        const documentUpdate = [...documents]
        const index = documentUpdate.findIndex(unDocument => unDocument.ID_DOCUMENT === idDoc)
        if (index >= 0) {
            documentUpdate[index].SUPPR = true
            if (documentUpdate[index].ID_DOCUMENT < 0) {
                const unDocument = new DocumentADT(documentUpdate[index])
                unDocument.toIndexedDB()
                documentUpdate.splice(index, 1)
            }
            setLocal(documentUpdate[index])
            setDocuments([...documentUpdate])
        }

    }

    /**
     * Modifie un document
     * @param {DocumentADT} newData - Object document à modifier
     */
    function updateDocument(newData) {
        const documentUpdate = [...documents]
        const index = documentUpdate.findIndex(unDocument => unDocument.ID_DOCUMENT === newData.ID_DOCUMENT)
        newData.getContenuByIndexedDB()
            .then((monRetour) => {
                if (monRetour && monRetour.blob) {
                    newData.blob = monRetour.blob
                }
            })
            .finally(() => {
                documentUpdate[index] = newData
                documentUpdate[index].toIndexedDB()
                setLocal(documentUpdate[index])
                setDocuments([...documentUpdate])
            })
    }

    /**
     * Ajout d'un document
     * @param {Event} event - Evenement d'ajout d'un nouveau document
     */
    function addDocument(event) {
        let newIdLocal = newID
        const tabDoc = [...documents]
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i]
            const name = file.name.split('.')[0]
            const ext = file.name.split('.')[1]
            const size = file.size
            const newData = new DocumentADT(undefined)
            if (file && file.name) {


                newData.ID_CONTROLE = idControle
                newData.ID_LIGNE = maReponseControle.ID_LIGNE
                newData.ID_SITE = monAudit.ID_SITE
                newData.ID_TYPE = monAudit.ID_TYPE
                newData.ID_ENTETE = monAudit.ID_ENTETE
                newData.ID_DOCUMENT = newIdLocal
                newData.blob = file
                newData.META_LIBELLE = name
                newData.META_NOM = name
                newData.META_COMMENTAIRE = ''
                newData.META_EXTENSION = ext.toLowerCase()
                newData.META_POIDS = size
                newData.META_NBPAGE = 0
                newData.MODIF = false
                if (mimeTypeRef[newData.META_EXTENSION] === undefined) {
                    enqueueSnackbar(t('document:Extension du fichier invalide'), { variant: 'error' })
                } else if (newData.META_POIDS >= process.env.REACT_APP_MAX_SIZE_FILES) {
                    enqueueSnackbar(t('document:Le fichier est trop lourd, maximum autorisé : 10Mo.'), { variant: 'error' })
                } else {
                    newIdLocal--

                    tabDoc.push(newData)
                    setDocuments([...tabDoc])
                    newData.toIndexedDB()
                    setLocal(newData)
                }


            }
        }
        setNew(newIdLocal)

    }

    /**
     * Controle la saisie d'un document
     * @param {DocumentADT} unDocument - Objet document à controler
     */
    function controleSaisie(unDocument) {
        let erreurCtrl = ''

        if (unDocument.META_LIBELLE === undefined || unDocument.META_LIBELLE === '') {
            erreurCtrl += t('document:Le libelle est obligatoire pour les documents.')
        }

        return erreurCtrl
    }

    /**
     * Fonction de sauvegarde en local d'un doucment
     * @param {DocumentADT} unDocument - Document à sauvegarder
     */
    function setLocal(unDocument) {
        let controleTxt = ''
        if (!unDocument) { return controleTxt }
        // controle de la saisie
        controleTxt = controleSaisie(unDocument)
        if (controleTxt === '') {
            // si pas d'erreur, on met à jour la réponse

            const reponseDocumentIndex = maReponseControle.DOCUMENTS.findIndex(search => search.ID_DOCUMENT === unDocument.ID_DOCUMENT)
            const CopieDoc = unDocument
            if (reponseDocumentIndex >= 0) {
                maReponseControle.DOCUMENTS[reponseDocumentIndex] = CopieDoc
            } else {
                maReponseControle.DOCUMENTS.push(CopieDoc)
            }
            onChangeControle('document', { target: { name: idFamille + '_' + idSecteur + '_' + idControle, value: maReponseControle.DOCUMENTS } })
            refreshPastille()
        } else {
            enqueueSnackbar(controleTxt, { variant: 'error' })
        }

        return controleTxt
    }

    /**
     * Fonction permettant de récuperer la lsite des mimeType
     */
    function getMimeTypeListe() {
        if (mimeTypeListe === "") {
            for (const key in mimeTypeRef) {
                if (mimeTypeRef.hasOwnProperty(key)) {
                    if (mimeTypeListe !== "") {
                        mimeTypeListe += ","
                    }
                    mimeTypeListe += mimeTypeRef[key]
                }
            }
        }
        return mimeTypeListe
    }



    return (
        <Box titre={t('document:Documents')} icone={Description}  >

            <input
                accept={getMimeTypeListe()}
                type="file"
                id={"add_document_idctrl_" + idControle}
                style={{ display: "none" }}
                onChange={addDocument}
                multiple
            />
            <Label htmlFor={"add_document_idctrl_" + idControle}>
                <Button size="small" color="primary" variant="outlined" endIcon={<NoteAdd />} component="span" className={classes.button} disabled={!Droit.crea}>
                    {t('document:Ajouter un document')}
                </Button>
            </Label>


            <Grid container
                direction="row"
                justify="center"
                alignItems="flex-start"
            >
                {
                    documents.map(unDocument => {
                        return <FormDocument unDocument={unDocument} key={'doc_' + unDocument.ID_DOCUMENT} deleteDocument={deleteDocument} modif={updateDocument} setLocal={setLocal} Droit={Droit} refreshDoc={refreshDoc} refreshPastille={refreshPastille} />
                    })
                }
            </Grid>
        </Box>
    )
}

